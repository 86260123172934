import React from 'react';

import { DATA_TEST_IDS_INSTANCE_NEW_PAGE, GLOSSARY } from 'global-shared/model';
import { TypographyFeature } from 'global-shared/features';

import classes from './index.module.scss';

import { NewInstanceFormContext } from '../../shared/model';

/**
 * @since 1.3.0
 * @description Component that allows you to select the type of instance in the form for creating an instance
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=7418-3721&node-type=frame&t=ZlSEZIpAHZTxDuJe-0}
 * @example <InstanceTypeFeature />
 * @returns React Fragment
*/
function InstanceType() {
  //variables
  const { useValue, useDispatch, setInstanceForm } = NewInstanceFormContext;
  const dispatch = useDispatch();
  const { form: { type } } = useValue();

  //func
  const handleChange = (type: 'vm' | 'bm') => {
    dispatch(setInstanceForm(type, 'type'));
  };

  return (
    <div className={classes.instanceTypeMoleculeClass}>
      <label className={classes.instanceTypeAtomClass}>
        <input
          type='radio'
          checked={type === 'vm' ? true : false}
          onChange={() => handleChange('vm')}
          test-dataid={DATA_TEST_IDS_INSTANCE_NEW_PAGE.VM_INSTANCE_TYPE}
        />
        {/* // */}
        <div>
          <TypographyFeature
            text={GLOSSARY.virtual_machine}
            fontSize='s'
            color='customColorDarkBlue80'
            fontWeight='bold'
          />
        </div>
      </label>
      <label className={classes.instanceTypeAtomClass}>
        <input
          type='radio'
          checked={type === 'bm' ? true : false}
          onChange={() => handleChange('bm')}
          test-dataid={DATA_TEST_IDS_INSTANCE_NEW_PAGE.BM_INSTANCE_TYPE}
        />
        {/* // */}
        <div>
          <TypographyFeature
            text={GLOSSARY.bare_metal}
            fontSize='s'
            color='customColorDarkBlue80'
            fontWeight='bold'
          />
        </div>
      </label>
    </div>
  );
};

export { InstanceType as InstanceTypeFeature };
