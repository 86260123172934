import { useMemo } from 'react';

import { ALL_INSTANCES_STATUSES } from 'global-shared/model';
import { InterfaceInstance, InterfaceStatus } from 'global-shared/model/dataTypes';

import { InterfaceChartPieListItem } from '../shared/dataTypes';

/**
 * @since 1.2.1
 * @description The hook that filters instances by status and
 * returns a new array of objects with the total number by the corresponding status
 * @param theInstances
 * @version 0.0.1
 * @see {@link https://northerndata.atlassian.net/wiki/spaces/IAAS/pages/2506784857/Instance+Status}
 * @package pages/overview/hooks
 * @example useFiltersInstancesByStatusHook();
 * @returns array of objects
 */
function useFiltersInstancesByStatus(
  theInstances: Readonly<InterfaceInstance>[],
): InterfaceChartPieListItem[] {
  // func
  const theStatuses = useMemo(
    () => ALL_INSTANCES_STATUSES.map(
      (status: InterfaceStatus ) => {
        const numberOfItems = theInstances.filter(
          (product: InterfaceInstance) => status.api === product.status,
        );

        return {
          color: status.color,
          name: status.ui,
          value: numberOfItems.length,
        };
      },
    ),
    [theInstances],
  );

  return theStatuses;
};

export { useFiltersInstancesByStatus as useFiltersInstancesByStatusHook };
