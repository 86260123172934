import { INewIPv4 } from 'global-shared/model';
import {
  SET_NETWORKS,
  SET_SSH_KEYS,
  SET_IPS,
  SET_ERROR_HANDLING,
  SET_NEW_NETWORK,
  SET_MAINTENANCE,
  SET_CURRENT_API_VERSION,
  SET_PRESELECTED_REMOVING_ITEMS,
  SET_NOTIFICATIONS,
  SET_IS_API_MAINTENANCE,
  SET_NEW_IPV4,
  SET_NEW_SSH_KEY,
  SET_SELECTED_INSTANCE_TYPE,
  MAKE_API_REQUEST,
  SET_METRICS,
  SET_METRICS_INSTANCES,
} from 'reducers/dashboard';

import {
  TErrorNotifications,
  TFetchFuncPage,
  TFetchFuncRequestMethod,
  TFetchFuncStatus,
  TIsApiMaintenance,
  TStatusMaintenance,
  TSuccessNotifications,
  IIpv4,
  INetwork,
  INewNetwork,
  ISshKey,
  TNewSshKey,
  TTabInstanceType,
  IMetrics,
  IMetricsInstances,
} from 'types';


export function setNetworks(networks: Array<INetwork>) {
  return {
    type: SET_NETWORKS,
    networks,
  };
};

export function setNewNetwork(newNetwork: INewNetwork) {
  return {
    type: SET_NEW_NETWORK,
    newNetwork,
  };
};

export function setSshKeys(sshKeys: Array<ISshKey>) {
  return {
    type: SET_SSH_KEYS,
    sshKeys,
  };
};

export function setNewSshKey(newSshKey: TNewSshKey) {
  return {
    type: SET_NEW_SSH_KEY,
    newSshKey,
  };
};

export function setIPs(ips: Array<IIpv4>) {
  return {
    type: SET_IPS,
    ips,
  };
};

export function setNewIpv4(newIpv4: INewIPv4) {
  return {
    type: SET_NEW_IPV4,
    newIpv4,
  };
};


export function setMaintenance(maintenance: TStatusMaintenance) {
  return {
    type: SET_MAINTENANCE,
    maintenance,
  };
};

export function setIsApiMaintenance(isApiMaintenance: TIsApiMaintenance) {
  return {
    type: SET_IS_API_MAINTENANCE,
    isApiMaintenance,
  };
};

export function setApiVersion(version: string) {
  return {
    type: SET_CURRENT_API_VERSION,
    version,
  };
};

export function setFetchFunc(
  fetchFunc: number | boolean | string | [] | unknown ,
  page: TFetchFuncPage,
  fetchMethod: TFetchFuncRequestMethod,
  status: TFetchFuncStatus,
) {
  return {
    type: SET_ERROR_HANDLING,
    fetchFunc,
    page,
    fetchMethod,
    status,
  };
};

export function setPreselectedRemovingItems(
  preselectedRemovingItemsPage: TFetchFuncPage,
  preselectedRemovingItemValue: 'uuid' | 'name',
  preselectedRemovingItems: string[],
) {

  return {
    type: SET_PRESELECTED_REMOVING_ITEMS,
    preselectedRemovingItemsPage,
    preselectedRemovingItemValue,
    preselectedRemovingItems,
  };
};

export function setNotifi(notifiKey: 'success' | 'errors', notifi: TSuccessNotifications[] | TErrorNotifications[]) {
  return {
    type: SET_NOTIFICATIONS,
    notifiKey,
    notifi,
  };
};

export function makeApiRequest(
  request: number | boolean | string | [] | unknown ,
  // method: 'get' | 'post' | 'delete' | 'patch',
  state: 'pending' | 'error' | 'message' | 'data',
) {
  return {
    type: MAKE_API_REQUEST,
    request,
    // method,
    state,
  };
};

export function setSelectedInstanceType(selectedInstanceType: TTabInstanceType) {
  return {
    type: SET_SELECTED_INSTANCE_TYPE,
    selectedInstanceType,
  };
};

export function setMetrics(metrics: IMetrics) {
  return {
    type: SET_METRICS,
    metrics,
  };
};

export function setMetricsInstances(metricsInstances: IMetricsInstances) {
  return {
    type: SET_METRICS_INSTANCES,
    metricsInstances,
  };
};
