import React, { useEffect } from 'react';

import SSHKeysList from 'feature/ssh-keys/SSHKeysList';
import { useDashboard } from 'context/dashboard';
import PageLayout from 'layouts/pages';
import { useCleanState } from 'hooks/useCleanState';
import BulkOperationsSection from 'feature/bulk-operations/delete/BulkOperationsSection';
import useSelectedOrDeselectedAllRemovingItems from 'hooks/useSelectedOrDeselectedAllRemovingItems';
import PortalNotification from 'portals/Notification';
import { useGetSshKeys } from 'hooks/useGetSshKeys';
import { PageHeaderFeature, PaginationFeature } from 'global-shared/features';
import { GLOSSARY } from 'global-shared/model';
import { useGoToModalFormPageHook } from 'global-shared/hooks';
import { setNewSshKey } from 'actions/dashboard';

/**
 * @since 0.0.1
 * @description A component that instantly receives data about all ssh keys and
 * shows a whole page with a header, bulk operations
 * @version 0.0.4
 * @example <SSHKeys />
 * @returns React Fragment
*/
export function SSHKeys() {
  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc } = state;
  const cleanState = useCleanState();
  const page = 'sshKeys';
  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingItems('sshKeys', false);
  const { handleOpenCreateForm } = useGoToModalFormPageHook();

  // hooks
  useGetSshKeys();

  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | ${GLOSSARY.ssh_keys_plural}`;
  }, []);

  useEffect(() => {
    return () => {
      cleanState('sshKey', 'post');
    };
  }, [cleanState]);

  // func
  const handleCloseModalForm = () => {
    handleOpenCreateForm('sshKeys');
    cleanState('sshKey', 'post');
    dispatch(
      setNewSshKey({
        tenant_uuid: '',
        name: '',
        key: '',
      }),
    );
  };

  return (
    <>
      <BulkOperationsSection
        page={page}
        handleDeselectedAllItems={handleDeselectedAllItems}
        itemsName='SSH Key'
      />
      <PageLayout
        page={page}
        emptyTextPlaceholder={GLOSSARY.ssh_keys_plural}
        renderSuccess={
          <>
            <PageHeaderFeature
              headerTitle={GLOSSARY.ssh_keys_plural}
              btnTitle={`${GLOSSARY.actions.create} ${GLOSSARY.ssh_key}`}
              onClick={handleCloseModalForm}
            />
            <SSHKeysList />
          </>
        }
      />
      <PaginationFeature page={page} />
      {fetchFunc.sshKey.post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};
