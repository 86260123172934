import { useCallback, useEffect } from 'react';

import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import { useTenants } from 'global-shared/features';
import { API_URLS, INetworkApi } from 'global-shared/model';

/**
 * @description useMakeGetRequestNetworksByClick - a hook that returns a function
 * that makes an API /GET request to get all networks
 * @returns handleGetNetworks
 */
function useMakeGetRequestNetworksByClick() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const url = `${API_URLS.networks}/${selectedTenantId}?brief=true`;
  const handleFetchRequest = useHandleFetchRequest();
  const page = 'networks';
  const fetchMethod = 'get';

  // func
  const handleGetNetworks = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      const getNetworks = api.useFetchData<[], INetworkApi[]>(url, fetchMethod, signal);
      handleFetchRequest(page, fetchMethod, getNetworks);
    }
    return () => {
      controller.abort();
    };
  }, [handleFetchRequest, selectedTenantId, url]);

  return { handleGetNetworks };
};

/**
 * @description useMakeGetRequestNetworksImmediately - a hook that makes an API /GET request
 * to immediately get all networks using useEffect
 * @returns null
 */
function useMakeGetRequestNetworksImmediately() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { handleGetNetworks } = useMakeGetRequestNetworksByClick();

  // hooks
  useEffect(() => {
    if (selectedTenantId) {
      handleGetNetworks();
    }
    return () => {};
  }, [selectedTenantId, handleGetNetworks]);
};

export {
  useMakeGetRequestNetworksByClick as useMakeGetRequestNetworksByClickHook,
  useMakeGetRequestNetworksImmediately as useMakeGetRequestNetworksImmediatelyHook,
};
