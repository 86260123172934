import { UUID } from 'crypto';

import { Link } from 'react-router-dom';

import { CopyBtnFeature, InstanceStatusFeature, TypographyFeature } from 'global-shared/features';
import { useGetPageRoutesHook } from 'global-shared/hooks';

/**
 * @description a component that returns a specific element type for pdp
 * @example var { showRightTypeOfElement } = useShowElementForPDPHook();
 * @returns func
 */
var useShowElementForPDP = () => {
  //hooks
  var { getDetailsPageRoute } = useGetPageRoutesHook();

  //func
  var showRightTypeOfElement = (element: string, value: string | UUID) => {
    switch (element) {
    case 'Network UUID':
      return (
        <div className='row justify-center items-center gap-1'>
          <Link to={() => getDetailsPageRoute('networks', value as UUID)}>
            <TypographyFeature
              text={value}
              fontWeight='thin'
              color='customColorDarkBlue80'
              fontSize='s'
              customStyle='cursor-pointer hover:text-primaryColorEasternBlueBtnHover'
            />
          </Link>
          <CopyBtnFeature
            value={value}
          />
        </div>
      );

    case 'UUID':
      return (
        <div className='row justify-center items-center gap-1'>
          <TypographyFeature
            text={value}
            fontWeight='thin'
            color='customColorDarkBlue80'
            fontSize='s'
          />
          <CopyBtnFeature
            value={value}
          />
        </div>
      );

    case 'Status':
      return(
        <InstanceStatusFeature
          status={value}
          fontSize='s'
          fontWeight='medium'
        />
      );

    default:
      return (
        <TypographyFeature
          text={value}
          fontWeight='thin'
          color='customColorDarkBlue80'
          fontSize='s'
        />
      );
    }
  };

  return { showRightTypeOfElement };
};

export { useShowElementForPDP as useShowElementForPDPHook };
