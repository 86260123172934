import { useDashboard } from 'context/dashboard';
import { TypographyFeature } from 'global-shared/features';
import { GLOSSARY } from 'global-shared/model';

import myClasses from './index.module.scss';

import {
  InstancesChartPieContentFeature,
  InstancesTabsEmptyStateFeature,
} from '..';
import sharedClasses from '../../shared/index.module.scss';

/**
 * @since 1.2.1
 * @description A section that shows a chart
 *  of all instance statuses in numbers and percentages.
 * @version 0.0.2
 * @package pages/overview/features
 * @example <InstancesSection />
 * @returns React Element | div
 */
function Instances(){
  // style
  const {
    grayWrapperSectionClass,
    grayWrapperSectionContentClass,
  } = sharedClasses;

  const {
    overviewGridRowSpan2Class,
  } = myClasses;

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const {
    fetchFunc: { instances: { get: { data }}},
  } = stateDashboard;
  const totalCount = data?.length;

  return (
    <div className={`${overviewGridRowSpan2Class} ${grayWrapperSectionClass}`}>
      <TypographyFeature
        text={GLOSSARY.instances_plural}
        fontSize='sm'
        customStyle='m-6'
      />
      <hr />
      <div className={`col ${grayWrapperSectionContentClass}`}>
        {totalCount === 0 ?
          <InstancesTabsEmptyStateFeature
            tabsType='all'//{selectedInstanceType}
          />
          :
          <InstancesChartPieContentFeature />
        }
      </div>
    </div>
  );
};

export { Instances as InstancesFeature };
