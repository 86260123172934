import React, { useRef }  from 'react';

import { dataTestIdsNewVm } from 'data-test-ids';
import InstanceName from 'feature/new-instance/Name';
import Authentication from 'feature/new-instance/keys/Authentication';
import Ips from 'feature/new-instance/ips/Ips';

import classes from './index.module.scss';

import { InstanceTypeFeature } from '../type';
import { FlavorsFeature, ImagesFeature, LocationsFeature, NetworkFeature } from '..';

/**
 * @since 1.0.0
 * @description A component that returns form fields to create a new instance,
 * and makes the necessary api requests to get additional values for the form
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=5036-1182&node-type=section&t=mBGYBVjux5veRJX9-0}
 * @version 0.0.3
 * @package pages/instances/new
 * @example <InstanceFormFieldsFeature />
 * @returns React Fragment
*/
function InstanceFormFields() {
  // hooks
  const nameRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className={classes.formFieldsClass}>
      <InstanceTypeFeature />
      <InstanceName ref={nameRef}/>
      <LocationsFeature />
      <FlavorsFeature />
      <ImagesFeature />
      <NetworkFeature dataTestIds={dataTestIdsNewVm.newVm.main.network} />
      <Authentication dataTestIds={dataTestIdsNewVm.newVm.main.authentication} />
      <Ips dataTestIds={dataTestIdsNewVm.newVm.main.ips} />
    </div>
  );
};

export { InstanceFormFields as InstanceFormFieldsFeature };
