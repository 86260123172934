import React, { useReducer, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import ReactGA from 'react-ga4';

import 'styles/index.scss';

import { initialStateDashboard, reducerDashboard } from 'reducers/dashboard';
import { DashboardContext } from 'context/dashboard';
import Routes from 'Routes';
import {
  initialStateTenants,
  reducerTenants,
  SpinnerFeature,
  TenantsContext,
} from 'global-shared/features';

const oktaAuthData = new OktaAuth({
  issuer: process.env.REACT_APP_API_OKTA_ISSUER as string,
  clientId: process.env.REACT_APP_API_OKTA_CLIENT_ID,
  scopes: ['openid', 'profile', 'groups', 'email'],
  redirectUri: `${window.location.origin}/login/callback`,
});

export default function App() {
  // state
  const [loading, setLoading] = useState<boolean>(false);

  // reducers
  const [stateTenants, dispatchTenants] = useReducer(reducerTenants, initialStateTenants);
  const [stateDashboard, dispatchDashboard] = useReducer(reducerDashboard, initialStateDashboard);

  // variables
  const history = useHistory();

  // func
  const customAuthHandler = () => {
    history.push('/login');
  };

  const restoreOriginalUri = async (_oktaAuthData: {}, originalUri: string) => {
    setLoading(true);
    history.replace(toRelativeUrl(originalUri || '/tenants', window.location.origin));
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);

  if(loading && oktaAuthData) {
    return <SpinnerFeature />;
  };

  return (
    <div className='bg-white'>
      <Security
        oktaAuth={oktaAuthData}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <TenantsContext.Provider value={[stateTenants, dispatchTenants]}>
          <DashboardContext.Provider value={[stateDashboard, dispatchDashboard]}>
            <Routes />
          </DashboardContext.Provider>
        </TenantsContext.Provider>
      </Security>
    </div>
  );
};
