import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import { InterfaceInstance } from 'global-shared/model/dataTypes';
import { API_URLS } from 'global-shared/model';
import { useTenants } from 'global-shared/features';

/**
 * @description a component makes a request to the API
 * @example useMakeGetRequestInstanceHook()
 * @returns null
*/
var useMakeGetRequestInstance = () => {
  // variables
  const { instanceId } = useParams<{instanceId: string}>();
  const url = `${API_URLS.instance}/${instanceId}`;
  const handleFetchRequest = useHandleFetchRequest();
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId && instanceId) {
      const getInstance = api.useFetchData<[], InterfaceInstance>(url, 'get', signal);
      handleFetchRequest('instance', 'get', getInstance);
    }
    return () => {
      controller.abort();
    };
  }, [url, instanceId, handleFetchRequest, selectedTenantId]);
};

export { useMakeGetRequestInstance as useMakeGetRequestInstanceHook };
