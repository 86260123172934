import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { ButtonFeature, TypographyFeature } from 'global-shared/features';
import { DATA_TEST_ID_PROFILE_PAGE, GLOSSARY } from 'global-shared/model';
import { useGoToModalFormPageHook, useLogoutHook } from 'global-shared/hooks';

/**
 * @description user section
*/
function UserSection() {
  // variables
  const { authState } = useOktaAuth();
  const name = authState?.idToken?.claims.name || 'user';
  const email = authState?.idToken?.claims.email || 'user@email.com';
  const { handleLogout } = useLogoutHook();
  const { handleOpenCreateForm } = useGoToModalFormPageHook();

  return (
    <div className='col'>
      <div className='row justify-between'>
        <TypographyFeature
          text='User details'
          customStyle='capitalize self-end pb-1'
          fontSize='sm'
        />
        <ButtonFeature
          uiStyle='primary'
          title={GLOSSARY.log_out}
          customStyle='mb-4 min-w-[12.75rem]'
          onClick={handleLogout}
          dataTestId={DATA_TEST_ID_PROFILE_PAGE.LOG_OUT_BTN}
        />
      </div>
      <hr />
      <div className='row pt-8 pb-14'>
        <div className='flex-1 ml-2'>
          <TypographyFeature
            text='Name'
            fontSize='s'
            fontWeight='medium'
          />
          <TypographyFeature
            text={name}
            fontSize='s'
            fontWeight='normal'
          />
        </div>
        <div className='flex-1'>
          <TypographyFeature
            text='Email'
            fontSize='s'
            fontWeight='medium'
          />
          <TypographyFeature
            text={email}
            fontSize='s'
            fontWeight='normal'
          />
        </div>
        <ButtonFeature
          uiStyle='secondary'
          customStyle='min-w-[12.75rem]'
          onClick={() => handleOpenCreateForm('demo')}
        >
          {GLOSSARY.manage_account_via_okta}
        </ButtonFeature>
      </div>
    </div>
  );
};

export { UserSection as User };
