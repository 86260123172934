import React from 'react';
import { Link } from 'react-router-dom';

import { TEST_ID_SIDEBAR } from 'data-test-ids/data-testid-variables';
import { TypographyFeature } from 'global-shared/features';
import { APP_ROUTES_PAGES_PATHS } from 'global-shared/model';
import { IconBigFullLogo } from 'global-shared/assets';

import classes from './index.module.scss';
import RoutesComponent from './navbar/RoutesComponent';
import LinksComponent from './navbar/LinksComponent';
import { IconSmallLogo } from './assets';

/**
 * @description
 * @see {@link: https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=7373-2980&t=X23LNYRltnLT4QZm-4}
 */
export default function Sidebar() {
  // variables
  const appVersion = `v${process.env.REACT_APP_VERSION}` || '';

  return (
    <div
      className={classes.sidebarClass}
      data-testid={TEST_ID_SIDEBAR}
    >
      <div className='items-center'>
        <div className={classes.logoClass}>
          <Link to={APP_ROUTES_PAGES_PATHS.home}>
            <div className='2xl:block hidden'>
              <IconBigFullLogo color='white' />
            </div>
            <div className='max-2xl:block hidden'>
              <IconSmallLogo />
            </div>
          </Link>
        </div>
        <div className={classes.navbarClass}>
          <div className='col'>
            <RoutesComponent />
            <LinksComponent />
          </div>
        </div>
      </div>
      <div>
        {process.env.REACT_APP_VERSION ?
          <TypographyFeature
            text={appVersion}
            fontSize='xxxs'
            color='white'
            customStyle='row justify-center mt-2'
          />
          : null
        }
      </div>
    </div>
  );
};
