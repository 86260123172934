import React, { useEffect } from 'react';

import NetworksList from 'feature/networks/Networks';
import { useDashboard } from 'context/dashboard';
import PortalNotification from 'portals/Notification';
import PageLayout from 'layouts/pages';
import BulkOperationsSection from 'feature/bulk-operations/delete/BulkOperationsSection';
import useSelectedOrDeselectedAllRemovingItems from 'hooks/useSelectedOrDeselectedAllRemovingItems';
import { useMakeGetRequestNetworksImmediatelyHook, useGoToModalFormPageHook } from 'global-shared/hooks';
import { PageHeaderFeature, PaginationFeature } from 'global-shared/features';
import { GLOSSARY } from 'global-shared/model';
import { setNewNetwork } from 'actions/dashboard';
import { useCleanState } from 'hooks/useCleanState';

/**
 * @since 0.0.1
 * @description A component that instantly receives data about all networks and
 * shows a whole page with a header, bulk operations
 * @version 0.0.4
 * @example <Networks />
 * @returns React Fragment
*/
export function Networks() {
  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatchDashboard = context[1];
  const { fetchFunc } = state;
  const page = 'networks';
  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingItems(page, false);
  const { handleOpenCreateForm } = useGoToModalFormPageHook();
  const cleanState = useCleanState();

  // hooks
  useMakeGetRequestNetworksImmediatelyHook();

  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | ${GLOSSARY.networks_plural}`;
  }, []);

  useEffect(() => {
    return () => {
      cleanState('network', 'post');
    };
  }, [cleanState]);

  // func
  const handleOpenModalForm = () => {
    handleOpenCreateForm('networks');
    cleanState('network', 'post');
    dispatchDashboard(
      setNewNetwork({
        tenant_uuid: '',
        cluster_uuid: '',
        network_name: '',
      }),
    );
  };

  return (
    <>
      <BulkOperationsSection
        page={page}
        handleDeselectedAllItems={handleDeselectedAllItems}
        itemsName='Network'
      />
      <PageLayout
        page={page}
        emptyTextPlaceholder={GLOSSARY.networks_plural}
        renderSuccess={
          <>
            <PageHeaderFeature
              headerTitle={GLOSSARY.networks_plural}
              btnTitle={`${GLOSSARY.actions.create} ${GLOSSARY.network}`}
              onClick={handleOpenModalForm}
            />
            <NetworksList />
          </>
        }
      />
      <PaginationFeature page={page} />
      {fetchFunc.network.post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};
