import React, { useEffect, useState } from 'react';

import { useDashboard } from 'context/dashboard';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import { TDataTestIdsNewVmMainIps } from 'data-test-ids/types/new-vm';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';
import { IIpv4Api, GLOSSARY } from 'global-shared/model';
import { ButtonFeature, useTenants } from 'global-shared/features';

import Ipv6Section from './Ipv6Section';
import AddIP4Modal from './AddIP4';
import SelectedIps from './SelectedIps';

import TitleSection from '../TitleSection';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 * @param props dataTestIds
 */
export default function Ips({dataTestIds}: {dataTestIds: TDataTestIdsNewVmMainIps}) {
  // state
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [warning, setWarning] = useState<string>('');

  // variables
  const { useValue, useDispatch, setInstance, setInstanceForm } = NewInstanceFormContext;
  const dispatchInstance = useDispatch();
  const {
    form: {
      preselectedIpv4s,
      selectedIpv4,
    },
    instance: {
      ips: ipAddresses,
    },
  } = useValue();

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { data } = fetchFunc.ips.get;
  const testIds = dataTestIds.wrapper;
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;

  useEffect(() => {
    if (selectedIpv4) {
      setWarning('');
    }
  }, [selectedIpv4]);

  useEffect(() => {
    if (selectedTenantId) {
      dispatchInstance(setInstance([], 'ips'));
    }
  }, [selectedTenantId, dispatchInstance, setInstance]);

  const handleAddIpv4 = () => {
    if (!selectedIpv4) {
      return setWarning(GLOSSARY.notifications.errors.please_select_ipv4);
    };
    const address = data.filter((address: IIpv4Api) => address.address4 === selectedIpv4).flat();
    const currentAddress = ipAddresses.filter((address: IIpv4Api)  => address.address4 === selectedIpv4);
    const ipv4s = [...ipAddresses, address].flat();

    if (currentAddress.length === 0) {
      dispatchInstance(setInstance(ipv4s, 'ips'));
    };
    dispatchInstance(setInstanceForm([...preselectedIpv4s, selectedIpv4], 'preselectedIpv4s'));

    setOpenModal(false);
    setWarning('');
    dispatchInstance(setInstanceForm('', 'selectedIpv4'));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setWarning('');
  };

  return (
    <div
      className='col-span-6 mt-5'
      data-testid={testIds.name}
    >
      <TitleSection
        title={GLOSSARY.ip_addresses_plural}
        dataTestId={testIds.title}
      />
      <Ipv6Section
        dataTestId={testIds.ipv6}
      />
      <SelectedIps dataTestIds={testIds.selectedIps} />
      <div
        className='flex justify-end items-center relative'
        data-testid={testIds.addIpv4Wrapper.name}
      >
        <ButtonFeature
          type='button'
          onClick={() => setOpenModal(true)}
          uiStyle='secondary'
          title={`${GLOSSARY.actions.add} ${GLOSSARY.ipv4}`}
          dataTestId={testIds.addIpv4Wrapper.button}
          customStyle='absolute right-0 top-[-10px]'
          size='small'
        />
        {openModal &&
          <PortalModal
            open={openModal}
            onCancel={handleCloseModal}
            dataTestId={testIds.addIpv4Wrapper.modalForm.portal.portalModal.name}
          >
            <FormModalTemplate
              handleSubmitButtonName={GLOSSARY.actions.add}
              onCancel={handleCloseModal}
              handleSubmit={handleAddIpv4}
              header={`${GLOSSARY.actions.add} ${GLOSSARY.ipv4}`}
              dataTestIds={testIds.addIpv4Wrapper.modalForm.portal.portalModal.form}
            >
              <div
                data-testid={testIds.addIpv4Wrapper.modalForm.warningWrapper.name}
                className='pt-6 px-8 pb-[61px]'
              >
                <AddIP4Modal
                  isEmptySelectIp={warning}
                  dataTestIds={testIds.addIpv4Wrapper.modalForm.warningWrapper.addIpv4ModalForm}
                />
              </div>
            </FormModalTemplate>
          </PortalModal>
        }
      </div>
    </div>
  );
};
