import { UUID } from 'crypto';

import React from 'react';

import { useDashboard } from 'context/dashboard';
import { TDataTestIdsNewVmMainIpsAddIpv4ModalForm } from 'data-test-ids/types/new-vm';
import { useFilterItemsByClusterHook } from 'global-shared/hooks';
import {
  AlertValidationFeature,
  MessageFeature,
  SelectFeature,
  SpinnerFeature,
} from 'global-shared/features';
import { GLOSSARY, IIpv4Api, IOptionSelect, TypeIpv4Select } from 'global-shared/model';
// eslint-disable-next-line no-restricted-imports
import { useMakeGetRequestIpv4sImmediatelyHook } from 'global-shared/hooks/useMakeGetRequestIpv4s';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';

import NewIp from './NewIp';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 * @description AddIP4
 * @param props
 * @returns React Element
 */
export default function AddIP4(props: IProps) {
  //props
  const {
    isEmptySelectIp='',
    dataTestIds,
  } = props;

  //hooks
  useMakeGetRequestIpv4sImmediatelyHook();

  //variables
  const { useValue, useDispatch, setInstanceForm } = NewInstanceFormContext;
  const dispatchInstance = useDispatch();
  const {
    form: {
      preselectedIpv4s,
      selectedIpv4,
    },
    instance: {
      cluster: {
        uuid: selectedClusterUuid,
      },
    },
  } = useValue();
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { error, loading, data } = fetchFunc.ips.get;
  const ipv4sDependsOnCluster = useFilterItemsByClusterHook(data, selectedClusterUuid as UUID) as IIpv4Api[];
  const ipv4sWithoutInstance = ipv4sDependsOnCluster.filter((item: IIpv4Api) => item.vm_uuid === null);
  const ipv4sWithoutAddedIps = ipv4sWithoutInstance.filter((el) => !preselectedIpv4s.includes(el.address4));
  const ipv4s: TypeIpv4Select[] = ipv4sWithoutAddedIps.map((ip) => ({...ip, type: 'ipv4s'})) ;

  //func
  const showCorrectValue = () => {
    if(ipv4sWithoutAddedIps?.length === 0){
      return GLOSSARY.empty_state.you_dont_have_ipv4;
    }

    if(selectedIpv4) {
      return selectedIpv4;
    }

    return `Select ${GLOSSARY.ipv4}` ;
  };

  const handleSelect = (option: IOptionSelect) => {
    dispatchInstance(setInstanceForm(option.name, 'selectedIpv4'));
  };

  return (
    <div className={dataTestIds.wrapper.name}>
      {loading ?
        <SpinnerFeature /> :
        <>
          <SelectFeature
            name={GLOSSARY.ipv4}
            value={showCorrectValue()}
            options={ipv4s}
            dataTestId={dataTestIds.wrapper.select}
            isDisabled={ipv4sWithoutAddedIps.length === 0 ? true : false}
            isError={isEmptySelectIp ? true : false}
            onSelect={handleSelect}
          />
          {isEmptySelectIp &&
            <AlertValidationFeature
              text={isEmptySelectIp}
            />
          }
          <NewIp dataTestIds={dataTestIds.wrapper.newIp.name} />
        </>
      }
      {error &&
        <MessageFeature
          status='error'
          msg={error}
          dataTestId={dataTestIds.wrapper.error}
        />
      }
    </div>
  );
};

interface IProps {
  isEmptySelectIp: string,
  dataTestIds: TDataTestIdsNewVmMainIpsAddIpv4ModalForm,
};
