import { ReactElement } from 'react';
import {
  NotificationFeature,
  SpinnerFeature,
  TypographyFeature,
} from 'global-shared/features';

import { ListWithChartPieFeature } from './list-with-chart-pie';
import { InterfaceChartPieListItem } from './dataTypes';
import classes from './index.module.scss';

/**
  * @since 1.2.1
  * @description The component returns the contents of the networks section
  * or the ipv4s section of the overview page
  * @param props
  * @version 0.0.1
  * @package pages/overview/features
  * @example
  *   <SectionsContentFeature
        heading='Ipv4s'
        emptyBtnTitle='Create ipv4'
        emptyBtnRedirectTo='newIpv4'
        error={error}
        loading={loading}
        status={status}
        customData={[{}]}
        totalCount={0}
      />
  * @returns React Element | Custom Button
 */
function SectionsContent(props: InterfaceProps){
  // props
  const {
    heading,
    error,
    status,
    loading,
    totalCount,
    customData,
    children,
    dataTestId,
  } = props;

  // style
  const {
    grayWrapperSectionClass,
    grayWrapperSectionContentClass,
  } = classes;

  return (
    <div className={`${grayWrapperSectionClass}`}>
      <TypographyFeature
        text={heading}
        fontSize='sm'
        customStyle='m-6'
      />
      <hr />
      {(error && status !== 404) ?
        <NotificationFeature status='error' msg={error} /> :
        null
      }
      {loading ?
        <SpinnerFeature />
        : null
      }
      <div className={`${grayWrapperSectionContentClass}`}>
        {(!loading && !error) ?
          <>
            {totalCount === 0 ?
              <div className='row items-center justify-between'>
                <div className='flex col basis-full self-stretch'>
                  <TypographyFeature
                    text='Field empty'
                    customStyle='self-start'
                    fontSize='xs'
                  />
                  {children}
                </div>
              </div>
              :
              <ListWithChartPieFeature
                data={customData}
                totalCount={totalCount}
                dataTestId={dataTestId}
              />
            }
          </>
          :
          null
        }
      </div>
    </div>
  );
};

export { SectionsContent as SectionsContentFeature };

interface InterfaceProps {
  heading: string,
  error: string,
  status: unknown,
  loading: boolean,
  totalCount: number,
  customData: InterfaceChartPieListItem[],
  children: ReactElement,
  dataTestId: string,
};
