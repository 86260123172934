//pages
export const PROFILE_PAGE = {
  LOG_OUT_BTN: 'test_id_profile_page_log_out_btn',
  DOWNLOAD_BTN: 'test_id_profile_page_download_btn',
  MANAGE_VIA_OKTA_BTN: 'test_id_profile_page_manage_via_okta_btn',
  UUIDS_DROP_DOWN: 'test_id_profile_page_uuids_drop_down',
};

export const INSTANCES_PAGE = {
  VM_TAB: 'test_id_instances_page_vm_tab',
  BM_TAB: 'test_id_instances_page_bm_tab',
};

export const INSTANCE_NEW_PAGE = {
  VM_INSTANCE_TYPE: 'test_id_instances_new_page_vm_type',
  BM_INSTANCE_TYPE: 'test_id_instances_new_page_bm_type',
};

export const LOGIN_PAGE = {
  LOGIN_BTN: 'test_id_login_page_login_btn',
};

export const NETWORKS_PAGE = {
  CREATE_NEW_NETWORK_BTN: 'test_id_networks_page_create_new_network_btn',
};

export const OVERVIEW_PAGE = {
  TOTAL_INSTANCES_NUMBER: 'test_id_overview_page_total_instances_number',
  TOTAL_NETWORKS_NUMBER: 'test_id_overview_page_total_networks_number',
  TOTAL_IPV4S_NUMBER: 'test_id_overview_page_total_ipv4s_number',
  TOTAL_SSH_KEYS_NUMBER: 'test_id_overview_page_total_ssh_keys_number',
  CREATE_NEW_IPV4_BTN: 'test_id_overview_page_create_new_ipv4_btn',
  CREATE_NEW_NETWORK_BTN: 'test_id_overview_page_create_new_network_btn',
  CREATE_NEW_SSH_KEY_BTN: 'test_id_overview_page_new_ssh_key_btn',
  CREATE_NEW_INSTANCE_BTN: 'test_id_overview_page_new_instance_btn',
  HERE_BTN: 'test_id_overview_page_here_btn',
};

//ui
export const DROP_DOWN_MENU = {
  LIST_DROP_DOWN: 'test_id_list_drop_down',
  LIST_ITEM_DROP_DOWN: 'test_id_list_item_drop_down',
};

export const ACTION_MENU = {
  ACTION_MENU: 'test_id_action_menu',
  ACTION_ITEM_MENU: 'test_id_action_item_menu',
};

export const TOAST = 'test_id_toast';

export const ALERT = {
  warning: 'test_id_warning_alert',
  validation: 'test_id_validation_alert',
};

export const PAGINATION = {
  TEXT: 'test_id_pagination_text',
};

export const BULK_OPERATIONS = {
  //for vms only
  POWER_OFF_BTN: 'test_id_bulk_operations_power_off_btn',
  POWER_ON_BTN: 'test_id_bulk_operations_power_on_btn',
  REBOOT_BTN: 'test_id_bulk_operations_reboot_btn',
  RESET_BTN: 'test_id_bulk_operations_reset_btn',
  //for all pages
  DELETE_BTN: 'test_id_bulk_operations_delete_btn',
  CLOSE_BTN: 'test_id_bulk_operations_close_btn',
  SELECTED_ITEMS_TEXT: 'test_id_bulk_operations_selected_items_text',
};

export const TABS = {
  AVAILABLE_CLUSTERS_TAB: 'test_id_tabs_available_clusters_tab',
  USE_UUID_TAB: 'test_id_tabs_use_uuid_tab',
};

//forms
export const NEW_NETWORK_FORM = {
  DROP_DOWN: 'test_id_new_network_form_drop_down',
};

export const RENAME_NETWORK_FORM = {
  NAME_INPUT: 'test_id_rename_network_form_input',
};

//modals
export const MODALS = {
  PORTAL_MODAL: 'test_id_portal_modal',
  CANCEL_BTN: 'test_id_modal_cancel_btn',
  SUBMIT_BTN: 'test_id_modal_submit_btn',
  modalBm: {
    TENANT_UUID: 'test_id_modal_bm_tenant_uuid',
    NAME_VALUE: 'test_id_modal_bm_number_value',
    LOCATION_NAME: 'test_id_modal_bm_location_name',
    FLAVOR_NAME: 'test_id_modal_bm_flavor_name',
    IMAGE_NAME: 'test_id_modal_bm_image_name',
    NETWORK_NAME: 'test_id_modal_bm_network_name',
    FIRST_SSH_KEY_NAME: 'test_id_modal_bm_first_ssh_key_name',
    FIRST_IPV4_VALUE: 'test_id_modal_bm_first_ipv4_value',
    ADD_BTN: 'test_id_modal_bm_add_btn',
    SUBTRACT_BTN: 'test_id_modal_bm_subtract_btn',
    COPY_BTN: 'test_id_modal_bm_copy_btn',
    HELP_DESK: 'test_id_modal_bm_help_desk_btn',
  },
};
