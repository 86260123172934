import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';

import 'styles/index.scss';

import { APP_ROUTES_PAGES_PATHS } from 'global-shared/model';
import {
  OverviewPage,
  InstancesPages,
  Ipv4sPages,
  NetworksPage,
  SshKeyPage,
  ModalFormPage,
  Template,
  Profile,
  Tenants,
  MaintenanceApi,
  SignIn,
  NotFound,
} from 'pages';

/**
 * @since 1.0.0
 * @description A сomponent that returns all project routes
 * @version 0.0.3
 * @example <Routes />
 * @returns React Element
 */
export default function Routes() {
  const location = useLocation();
  //@ts-ignore
  const pdpInstancePage = location.state && location.state.pdpInstancePage;
  //@ts-ignore
  const modalFormPage = location.state && location.state.modalFormPage;

  return (
    <>
      <Switch location={ modalFormPage || pdpInstancePage || location}>
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.newInstance}
          children={
            <Template>
              <InstancesPages.NewInstancePage />
            </Template>
          }
        />
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.instance}
          children={
            <Template>
              <InstancesPages.InstanceDetailPage />
            </Template>
          }
        />
        <SecureRoute
          exact
          path={APP_ROUTES_PAGES_PATHS.instances}
          children={
            <Template>
              <InstancesPages.ListInstancesPage />
            </Template>
          }
        />
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.network}
          children={
            <Template>
              <NetworksPage.NetworkDetailPage />
            </Template>
          }
        />
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.networks}
          children={
            <Template>
              <NetworksPage.Networks />
            </Template>
          }
        />
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.sshKeys}
          children={
            <Template>
              <SshKeyPage.SSHKeys />
            </Template>
          }
        />
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.ipv4}
          children={
            <Template>
              <Ipv4sPages.Ipv4DetailPage />
            </Template>
          }
        />
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.ipv4s}
          children={
            <Template>
              <Ipv4sPages.IPv4s />
            </Template>
          }
        />
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.profile}
          children={
            <Template>
              <Profile />
            </Template>
          }
        />
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.tenants}
          children={<Tenants />}
        />
        <SecureRoute
          exact
          path={APP_ROUTES_PAGES_PATHS.home}
          children={
            <Template>
              <OverviewPage />
            </Template>
          }
        />

        <Route exact path={APP_ROUTES_PAGES_PATHS.login} component={SignIn} />
        <Route path={APP_ROUTES_PAGES_PATHS.loginCallback} component={LoginCallback} />
        <Route path={APP_ROUTES_PAGES_PATHS.maintenance} component={MaintenanceApi} />
        <Route path={APP_ROUTES_PAGES_PATHS.notFound} component={NotFound} />
      </Switch>

      {/* Show the modal when a modalFormPage page is set */}
      {modalFormPage &&
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.modalForm}
          children={<ModalFormPage />}
        />
      }
      {pdpInstancePage &&
        <SecureRoute
          path={APP_ROUTES_PAGES_PATHS.pdpInstance}
          children={<InstancesPages.ModalIDPInfoPage />}
        />
      }
    </>
  );
};
