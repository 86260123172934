import { UUID } from 'crypto';

import { useTenants } from 'global-shared/features';
import {
  TWebNewModalFormPage,
  TypeModalFormActionPage,
  TypeRoutePath,
  TypeRoutePathDetailsPage,
  TypeRoutePathModalFormPage,
  TypeRoutePathNewPage,
  TypeRoutePathProductDetailPage,
  TypeWebNewPage,
  TypeWebPage,
} from 'global-shared/model/dataTypes';

/**
 * @since 1.2.1
 * @description The hook that returns the project route string
 * @version 0.0.1
 * @package shared/hooks
 * @example
 *   const { getDetailsPageRoute, getNewPageRoute, getPageRoute } = useGetPageRoutesHook();
 * @returns object of functions
 */
function useGetPageRoutes() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const selectedTenantUUID: UUID = selectedTenantId as UUID;

  /**
   * @param page
   * @param uuid instanceId | networkId | ipv4Id | pdpUUID => to get details page for one element and info about an instance
   * @returns string | `/${UUID}/pdp/${pdpUUID}/${UUID}`
   */
  const getProductDetailPageRoute: TypeGetProductDetailPageRoute = (pdpUUID, uuid) => {
    return `/${selectedTenantUUID}/pdp/${pdpUUID}/${uuid}`;
  };

  /**
   * @param page
   * @param uuid instanceId | networkId | ipv4Id => to get details page for one element
   * @returns string | `/${UUID}/${TypePage}/${UUID}`
   */
  const getDetailsPageRoute: TypeGetDetailsPageRoute = (page, uuid) => {
    return `/${selectedTenantUUID}/${page}/${uuid}`;
  };

  /**
   * @param page
   * @returns string | `/${UUID}/${TypePage}/new`
   */
  const getNewPageRoute: TypeGetNewPageRoute = (page) => {
    return `/${selectedTenantUUID}/${page}/new`;
  };

  /**
   * @param page
   * @returns string | `/${UUID}/${TypePage}`
   */
  const getPageRoute: TypeGetPageRoute = (page) => {
    return `/${selectedTenantUUID}/${page}`;
  };

  /**
   * @param page
   * @param action new => to get details page for one element and info about an instance
   * @returns string | `/${UUID}/modalForm/${TypePage}/new`
   */
  const getModalFormPageRoute: TypeGetModalFormPageRoute = (page, action) => {
    return `/${selectedTenantUUID}/modalForm/${page}/${action}`;
  };

  return { getProductDetailPageRoute, getDetailsPageRoute, getNewPageRoute, getPageRoute, getModalFormPageRoute };
};

export { useGetPageRoutes as useGetPageRoutesHook };

type TypeGetProductDetailPageRoute = (pdpUUID: UUID, uuid: UUID) => TypeRoutePathProductDetailPage;
type TypeGetDetailsPageRoute = (page: TypeWebPage, uuid: UUID) => TypeRoutePathDetailsPage;
type TypeGetNewPageRoute = (page: TypeWebNewPage) => TypeRoutePathNewPage;
type TypeGetPageRoute = (page: TypeWebPage) => TypeRoutePath;
type TypeGetModalFormPageRoute = (page: TWebNewModalFormPage, action: TypeModalFormActionPage) => TypeRoutePathModalFormPage;
