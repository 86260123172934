import React, { ReactNode, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import useOnClickOutside from 'hooks/useClickOutside';
import {
  DATA_TEST_ID_DROP_DOWN_MENU,
  IOptionSelect,
  TypeClusterSelect,
  TypeIpv4Select,
  TypeNetworkSelect,
  TypeTenantSelect,
  TypeUuidSelect,
} from 'global-shared/model';

import classes from './index.module.scss';
import { IconCheckGreen } from './assets/IconCheckGreen';

import { TypographyFeature } from '../typography';

/**
 * @description
 * @param props
 * @example
  * <SelectFeature
      name='Network'
      value={showCorrectValue()}
      options={[]}
      dataTestId=''
      isDisabled={theNetworks?.length === 0 ? true : false}
      isError={validation.isNetwork}
    />
 */
const Select = (props: InterfaceProps) => {
  // state
  const [openList, setOpenList] = useState<boolean>(false);

  // refs
  const refSelect = useRef(null);

  // props
  const {
    name,
    value,
    options,
    dataTestId={DATA_TEST_ID_DROP_DOWN_MENU},
    isError=false,
    isDisabled = false,
    onSelect,
  } = props;

  //variables
  const iconStyle = 'h-5 w-5 stroke-[1.5px]';

  // func
  const statusView = () => {
    if (isError && !isDisabled) return `${classes.validationClass}`;
    if (isDisabled) return `${classes.disabledClass}`;
    return `${classes.selectClass}`;
  };

  const handleClickOutside = () => {
    const el = document.getElementById(`${name}`) as HTMLDetailsElement;
    el.open = false;
    setOpenList(false);
  };

  useOnClickOutside(refSelect, handleClickOutside);

  const onToggle = (event: React.MouseEvent<HTMLDetailsElement>) => {
    event.preventDefault();
    if (!isDisabled) {
      setOpenList(!openList);
    }
  };

  const handleSelect = (obj: IOptionSelect) => {
    onSelect(obj);
    handleClickOutside();
  };

  const showValue = (option: TypeOption) => {
    switch (option.type) {
    case 'clusters':
      return option.name;
    case 'tenants':
      return option.customer_name;
    case 'ipv4s':
      return option.address4;
    case 'networks':
      return option.network_name;
    case 'uuids':
      return option.title;
    default:
      return option;
    }
  };

  return (
    <details
      ref={refSelect}
      id={name}
      open={isDisabled ? false : openList}
      data-testid={dataTestId}
      aria-label={name}
      className='relative'
      onClick={onToggle}
    >
      <summary className={statusView()}>
        {<>
          <TypographyFeature
            text={value}
            fontSize='sm'
            fontWeight='light'
          />
          {openList ?
            <ChevronUpIcon
              className={`${iconStyle} fill-customColorDarkBlue`}
              aria-hidden='true'
            />
            :
            <ChevronDownIcon
              className={`${iconStyle} ${isDisabled ? 'fill-customGray3' : 'fill-customColorDarkBlue'}`}
              aria-hidden='true'
            />
          }
        </>}
      </summary>
      <div className={classes.selectListClass}>
        {options?.map((option) => (
          <div
            key={option.uuid}
            className='selectListItemClass'
            onClick={() => handleSelect({ name: showValue(option), uuid: option.uuid })}
          >
            <TypographyFeature
              text={showValue(option)}
              fontSize='s'
              fontWeight='medium'
            />
            {showValue(option) === value ?
              <IconCheckGreen />  :
              ''
            }
          </div>
        ))}
      </div>
    </details>
  );
};

export { Select as SelectFeature };

type TypeOption = TypeClusterSelect | TypeTenantSelect | TypeIpv4Select | TypeNetworkSelect | TypeUuidSelect;
type TypeOptions = TypeClusterSelect[] | TypeTenantSelect[] | TypeIpv4Select[] | TypeNetworkSelect[] | TypeUuidSelect[];

interface InterfaceProps {
  name: string,
  value: string,
  options: TypeOptions,
  dataTestId?: string,
  isError?: boolean,
  isDisabled?: boolean,
  onSelect: (option: IOptionSelect) => void,
  children?: ReactNode,
};
