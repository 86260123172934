import React, { ReactElement } from 'react';

import Aside from 'components/aside';
import { TDataTestIdsOverviewPageContentMainInfoAside } from 'data-test-ids/types/overview-page';

import { NoInstances } from './NoInstances';

/**
  * @description a component that shows a link with the name or uuid of an instance or
  * that there are no instances
  * @param props
  * @example
  *   <PDPAttachedSectionFeature
        isAttached={true}
        dataTestIds={{}}
      >
  * @returns React Element
*/
var PDPAttachedSection = (props: InterfaceProps) => {
  // props
  const { dataTestIds, children, isAttached } = props;

  return (
    <Aside
      title='Attached to Instances'
      dataTestIds={dataTestIds}
    >
      {isAttached ?
        children
        :
        <NoInstances />
      }
    </Aside>
  );
};

export { PDPAttachedSection as PDPAttachedSectionFeature };

interface InterfaceProps {
  dataTestIds: TDataTestIdsOverviewPageContentMainInfoAside,
  children: ReactElement,
  isAttached: boolean,
};
