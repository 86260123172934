import { ButtonFeature } from 'global-shared/features';
import { useNavigationPathHook } from 'global-shared/hooks';
import { DATA_TEST_IDS_OVERVIEW_PAGE, GLOSSARY } from 'global-shared/model';

/**
  * @since 1.2.1
  * @description Button that redirects to the vm creation form
  * @version 0.0.1
  * @package overview/feature
  * @example <NavigateToCreateVmPage />
  * @returns React Element | Custom Button
 */
function NavigateToCreateVmPage(){
  // func
  const { handleNavigationNewPage } = useNavigationPathHook();

  return (
    <ButtonFeature
      title={`${GLOSSARY.actions.create} ${GLOSSARY.instance}`}
      uiStyle='primary'
      customStyle='min-w-[155px]'
      size='small'
      onClick={() => handleNavigationNewPage('instances')}
      dataTestId={DATA_TEST_IDS_OVERVIEW_PAGE.CREATE_NEW_INSTANCE_BTN}
    />
  );
};

export { NavigateToCreateVmPage as NavigateToCreateVmPageFeature };
