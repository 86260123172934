import React from 'react';

import { GLOSSARY } from 'global-shared/model';

import { TypographyFeature } from '../typography';

/**
 * @description a component that displays text for PDPs that have no instances
 * @example <NoInstances />
 * @returns React Element
*/
var NoInstances = () => {
  return (
    <div
      data-testid='test_id_no_vms_wrapper'
      className='pl-6 py-4'
    >
      <TypographyFeature
        text={GLOSSARY.empty_state.no_instances}
        fontWeight='medium'
        fontSize='s'
        dataTestId='test_id_no_instances'
      />
    </div>
  );
};

export { NoInstances };
