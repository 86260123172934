import { createContext, useContext } from 'react';

import { InterfaceIpv4, InterfaceNetwork, InterfaceSshKey } from 'global-shared/model/dataTypes';
import { defaultNetwork } from 'global-shared/model/consts';

import { InterfaceFlavor, InterfaceImage } from './data-types';

//action types
export const SET_INSTANCE_FORM = 'NEW_INSTANCE.SET_INSTANCE_FORM';
export const SET_INSTANCE = 'NEW_INSTANCE.SET_INSTANCE';
export const SET_INSTANCE_VALIDATION = 'NEW_INSTANCE.SET_INSTANCE_VALIDATION';

//actions
export function setInstanceForm(form: {}, key: TInstanceFormKey) {
  return {
    type: SET_INSTANCE_FORM,
    form,
    key,
  };
};

export function setInstance(instance: {}, key: TInstanceKey) {
  return {
    type: SET_INSTANCE,
    instance,
    key,
  };
};

export function setInstanceValidation(validation: undefined | boolean, key: TypeInstanceValidationKey) {
  return {
    type: SET_INSTANCE_VALIDATION,
    validation,
    key,
  };
};


const defaultCluster = {
  name: '',
  uuid: '',
};

const defaultFlavor = {
  uuid: '',
  name: '',
  slug: '',
  selected: false,
  cluster: defaultCluster,
  cpu_type: '',
  core_count: 0,
  ram: 0,
  storage_size: 0,
  gpu_type: '',
  gpu_count: 0,
  available: false,
  nvswitch_count: 0,
  nvswitch_type: '',
};

const defaultImage = {
  uuid: '',
  name: '',
  size: 0,
  image_group_id: 0,
  image: '',
  selected: false,
};

//initial state
export const initialState: InterfaceInstanceInitialStateReducer = {
  form: {
    type: 'vm',
    error: undefined,
    location: '',
    selectedIpv4: undefined,
    preselectedIpv4s: [],
  },
  instance: {
    name: '',
    network: defaultNetwork,
    flavor: defaultFlavor,
    image: defaultImage,
    cluster: defaultCluster,
    sshKeys: [],
    ips: [],
  },
  validation: {
    isName: undefined,
    isSshKey: undefined,
    isNetwork: undefined,
    isImage: undefined,
    isFlavor: undefined,
    isCluster: undefined,
  },
};

//reducer
export function reducer(
  state: InterfaceInstanceInitialStateReducer,
  action: IAction,
): InterfaceInstanceInitialStateReducer {
  switch (action.type) {
  case SET_INSTANCE_VALIDATION:
    return {
      ...state,
      validation: {
        ...state.validation,
        [action.key]: action.validation,
      },
    };
  case SET_INSTANCE:
    return {
      ...state,
      instance: {
        ...state.instance,
        [action.key]: action.instance,
      },
    };
  case SET_INSTANCE_FORM:
    return {
      ...state,
      form: {
        ...state.form,
        [action.key]: action.form,
      },
    };

  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  }
};

//context
export const ValueContext = createContext<InterfaceInstanceInitialStateReducer>(initialState);
export const DispatchContext = createContext<(object: any) => void>(() => {});
export const useValue = () => useContext(ValueContext);
export const useDispatch = () => useContext(DispatchContext);

//types, interfaces
interface InterfaceInstanceInitialStateReducer {
  form: InterfaceInstanceFormReducer,
  instance: InterfaceInstanceReducer
  validation: InterfaceValidationReducer,
};

interface InterfaceInstanceFormReducer {
  type: 'vm' | 'bm',
  location: string,
  error: string | undefined,
  preselectedIpv4s: string[],
  selectedIpv4: string | undefined,
};

interface InterfaceInstanceReducer {
  name: string,
  flavor: InterfaceFlavor,
  network: InterfaceNetwork,
  image: InterfaceImage,
  cluster: InterfaceCluster,
  sshKeys: InterfaceSshKey[],
  ips: InterfaceIpv4[],
};

interface InterfaceValidationReducer {
  isName: undefined | boolean,
  isSshKey: undefined | boolean,
  isNetwork: undefined | boolean,
  isImage: undefined | boolean,
  isFlavor: undefined | boolean,
  isCluster: undefined | boolean,
};

interface IAction {
  type: string,
  key: string,
  instance: {},
  validation: {},
  form: {},
};

interface InterfaceCluster {
  name: string,
  uuid: string,
};

type TInstanceKey =
  | 'name'
  | 'sshKeys'
  | 'network'
  | 'image'
  | 'ips'
  | 'flavor'
  | 'cluster'
;

type TInstanceFormKey =
  | 'form'
  | 'type'
  | 'location'
  | 'ips'
  | 'selectedIpv4'
  | 'preselectedIpv4s'
  | 'error'
;

type TypeInstanceValidationKey =
  | 'isName'
  | 'isSshKey'
  | 'isNetwork'
  | 'isImage'
  | 'isFlavor'
  | 'isCluster'
;
