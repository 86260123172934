import React, { useState } from 'react';

import { useDashboard } from 'context/dashboard';
import { ButtonFeature } from 'global-shared/features';
import { useCleanState } from 'hooks/useCleanState';
import { setNewNetwork } from 'actions/dashboard';
import ModalWrapper from 'components/forms/create-network';
import { TDataTestIdsNewVmMainNetworkNewNetwork } from 'data-test-ids/types/new-vm';
import { GLOSSARY } from 'global-shared/model';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 * @description a component that shows a form to create a new network for the corresponding cluster
 * @param props
 * @example <CreateNewNetworkSection dataTestIds={} />
 */
function CreateNewNetworkSection(props: InterfaceProps){
  //state
  const [openForm, setOpenForm] = useState<boolean>(false);

  //props
  const { dataTestIds, onWarning } = props;

  //variables
  const { useValue } = NewInstanceFormContext;
  const { instance: { flavor} } = useValue();
  const contextDashboard = useDashboard();
  const dispatchDashboard = contextDashboard[1];
  const cleanState = useCleanState();

  //func
  const handleAddNewNetwork = () => {
    cleanState('network', 'post');
    dispatchDashboard(
      setNewNetwork({
        tenant_uuid: '',
        cluster_uuid: '',
        network_name: '',
      }),
    );
    if (flavor.uuid === '') {
      return onWarning(GLOSSARY.notifications.errors.please_choose_flavor);
    }
    return setOpenForm(true);
  };

  return (
    <div className='relative'>
      <ButtonFeature
        type='button'
        onClick={handleAddNewNetwork}
        uiStyle='secondary'
        title={`${GLOSSARY.actions.create} ${GLOSSARY.network}`}
        dataTestId={dataTestIds.button}
        customStyle='absolute right-0 top-[-10px]'
        size='small'
      />
      {openForm &&
        <ModalWrapper
          onCancel={() => setOpenForm(false)}
          open={openForm}
          dataTestIds={dataTestIds.modalForm}
        />
      }
    </div>
  );
};

export default CreateNewNetworkSection;

interface InterfaceProps {
  dataTestIds: TDataTestIdsNewVmMainNetworkNewNetwork,
  onWarning: (value: string) => void,
};
