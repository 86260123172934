const IconSupport = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='13' viewBox='0 0 17 13' fill='none'>
    <path fillRule='evenodd' clipRule='evenodd' d='M5.6758 0C4.01912 0 2.52821 1.16926 2.52821 2.77917C2.52821 4.38908 4.01912 5.55834 5.6758 5.55834C7.33249 5.55834 8.8234 4.38908 8.8234 2.77917C8.8234 1.16926 7.33249 0 5.6758 0ZM3.76701 2.77917C3.76701 1.99067 4.54105 1.21738 5.6758 1.21738C6.81056 1.21738 7.5846 1.99067 7.5846 2.77917C7.5846 3.56768 6.81056 4.34096 5.6758 4.34096C4.54105 4.34096 3.76701 3.56768 3.76701 2.77917Z' fill='white'/>
    <path fillRule='evenodd' clipRule='evenodd' d='M11.8778 2.20924C10.483 2.20924 9.20423 3.19623 9.20423 4.58144C9.20423 5.96666 10.483 6.95365 11.8778 6.95365C13.2726 6.95365 14.5513 5.96666 14.5513 4.58144C14.5513 3.19623 13.2726 2.20924 11.8778 2.20924ZM10.443 4.58144C10.443 4.0175 11.0051 3.42662 11.8778 3.42662C12.7505 3.42662 13.3125 4.0175 13.3125 4.58144C13.3125 5.14539 12.7505 5.73627 11.8778 5.73627C11.0051 5.73627 10.443 5.14539 10.443 4.58144Z' fill='white'/>
    <path fillRule='evenodd' clipRule='evenodd' d='M2.09234 7.00536C0.792963 7.78446 1.42081e-05 9.17122 1.42081e-05 10.6662L6.44725e-06 11.0085C-4.30997e-05 11.1395 -8.53725e-05 11.2513 0.0112497 11.3527C0.107619 12.2149 0.801262 12.8946 1.6783 12.989C1.78148 13.0001 1.89526 13 2.02883 13H9.32274C9.45631 13 9.57012 13.0001 9.67331 12.989C10.5503 12.8946 11.244 12.2149 11.3404 11.3527C11.3517 11.2513 11.3516 11.1395 11.3516 11.0085L11.3516 10.6662C11.3516 9.17122 10.5586 7.78446 9.25927 7.00536C7.05937 5.68632 4.29223 5.68632 2.09234 7.00536ZM1.23881 10.6662C1.23881 9.59624 1.80632 8.60295 2.73757 8.04457C4.54127 6.96309 6.81033 6.96309 8.61403 8.04457C9.54529 8.60295 10.1128 9.59624 10.1128 10.6662V10.9785C10.1128 11.1551 10.1119 11.1939 10.109 11.2198C10.0761 11.5134 9.83955 11.7464 9.53848 11.7788C9.51191 11.7817 9.47221 11.7826 9.2923 11.7826H2.05931C1.8794 11.7826 1.83969 11.7817 1.81313 11.7788C1.51206 11.7464 1.27546 11.5134 1.24264 11.2198C1.23975 11.1939 1.23881 11.1551 1.23881 10.9785V10.6662Z' fill='white'/>
    <path d='M14.5527 8.06619C13.7099 7.58618 12.7584 7.35319 11.8119 7.36452C11.7278 7.36553 11.6438 7.36846 11.5599 7.37332C11.2193 7.39304 10.9577 7.68033 10.978 8.01687C10.9982 8.35332 11.2922 8.60831 11.6327 8.5886C11.6974 8.58485 11.7622 8.58259 11.827 8.58181C12.5579 8.57306 13.2884 8.75317 13.9315 9.11946C14.6122 9.50714 15.0112 10.1857 15.0112 10.9002L15.0112 11.1128C15.0112 11.2029 15.0104 11.2371 15.0077 11.2606C14.9782 11.5112 14.7566 11.7452 14.4284 11.7788C14.3966 11.782 14.3538 11.7826 14.2587 11.7826L12.2531 11.7826C11.9119 11.7826 11.6337 12.0542 11.6337 12.3913C11.6337 12.7284 11.9119 13 12.2531 13L14.2707 13C14.3479 13.0001 14.4535 13.0001 14.5565 12.9896C15.4182 12.9015 16.1371 12.2599 16.2383 11.4005C16.2502 11.2992 16.2501 11.196 16.25 11.1247V11.1237L16.25 11.1121L16.25 10.9002C16.25 9.73057 15.5969 8.6609 14.5527 8.06619Z' fill='white'/>
  </svg>
);

export { IconSupport };
