import { useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import useOnClickOutside from 'hooks/useClickOutside';

import classes from './index.module.scss';
import { InterfaceImageFamily } from './shared/data-types';

import { InterfaceImage } from '../../shared/model/data-types';
import { NewInstanceFormContext } from '../../shared/model';

/**
 * @description component that return an image dropdown menu on the create new instance page
 * @param props
 */
const Dropdown = (props: InterfaceProps) => {
  // state
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [preselectedImageFamilyId, setPreselectedImageFamilyId] = useState<number>(0);

  // props
  const {
    imageFamily,
    selectedImage,
    onSelectImageFamily,
  } = props;

  // variables
  const ref = useRef(null);
  const isSelectedImageBelongsSelectedFamily = selectedImage?.image_group_id === imageFamily?.id;
  const { useDispatch, setInstance, setInstanceValidation } = NewInstanceFormContext;
  const dispatch = useDispatch();

  // func
  const handleClickOutside = () => {
    setOpenDropdown(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const handleOpenDropdown = (id: number) => {
    setPreselectedImageFamilyId(id);
    setOpenDropdown(true);
  };

  const handleChooseVesionOfImageAndImageGroup = (imageFamilyId: number, image: InterfaceImage) => {
    onSelectImageFamily(imageFamilyId, image);
    dispatch(setInstance(image, 'image'));
    dispatch(setInstanceValidation(false, 'isImage'));
    setOpenDropdown(false);
  };

  return (
    <>
      <button
        type='button'
        className={classes.selectItemClass}
        onClick={() => handleOpenDropdown(imageFamily.id)}
      >
        <p
          className={`
            truncate w-44 text-start
            ${!isSelectedImageBelongsSelectedFamily ? classes.dropdownDefaultValueClass : null}
          `}
        >
          {isSelectedImageBelongsSelectedFamily ? selectedImage?.name : 'Version'}
        </p>
        {(openDropdown && preselectedImageFamilyId === imageFamily.id) ?
          <ChevronUpIcon className={`w-5 h-5 ${openDropdown ? classes.openClass : ''}`}/> :
          <ChevronDownIcon className={`w-5 h-5 ${openDropdown ? classes.openClass : ''}`}/>
        }
      </button>
      {(openDropdown && preselectedImageFamilyId === imageFamily.id) &&
        <div
          ref={ref}
          className={`
            ${classes.dropdownBodyClass}
            ${imageFamily?.images?.length > 1 ? classes.dropdownBodyMultyClass : ''}
            ${openDropdown && classes.openClass}
          z-10`}
        >
          {imageFamily?.images?.map((image: InterfaceImage) => (
            <div
              key={image.uuid}
              className={`
                ${image.selected ? classes.selectedVersionClass : ''}
                ${classes.dropdownItemClass}
                ${imageFamily?.images?.length === 1 ? 'rounded-md' : ''}
              `}
              onClick={
                image.selected ?
                  () => setOpenDropdown(false) :
                  () => handleChooseVesionOfImageAndImageGroup(imageFamily.id, image)
              }
              data-testid={image.uuid}
            >
              {image.name}
            </div>
          ))}
        </div>
      }
    </>
  );
};

export default Dropdown;

interface InterfaceProps {
  imageFamily: InterfaceImageFamily,
  selectedImage: InterfaceImage,
  onSelectImageFamily: (imageFamilyId: number, image: InterfaceImage) => void,
};
