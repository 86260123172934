import { InterfaceTypograhy } from 'global-shared/model/dataTypes';

/**
 * @sice 1.0.0
 * @description The Typography component can be used to create text tags like h1...h6 and p,
 * and can also be customized in appearance.
 * Use props {truncate} and {width} to truncate overflowing text with an ellipsis (…) if needed.
 * @param props
 * @version 0.0.3
 * @package global-shared/features
 * @example
 *  <Typography
      text='text'
      customStyle='my-6'
      fontSize='xs'
      fontWeight='normal'
    />
* @returns React Element | h1..h6 | p with placeholder text inside - TagComponent
*/
function Typography(props: InterfaceProps) {
  // props
  const {
    text,
    fontWeight='normal',
    color='customColorDarkBlue',
    dataTestId='test_id_text_element',
    fontSize='base',
    customStyle='',
    truncate='',
    width='',
  } = props;

  // variables
  const styleFont = `
    text-${fontSize}
    font-${fontWeight}
    text-${color}
    ${truncate}
    ${truncate ? `w-[${width}px]` : ''}
  `;

  const styles = `
    ${customStyle}
    ${styleFont}
  `;

  return (
    <p
      data-testid={dataTestId}
      className={styles}
    >
      {text}
    </p>
  );
};

export { Typography as TypographyFeature };

interface InterfaceProps extends InterfaceTypograhy {
  /**
   * @description The text will be shown to the user for example (<p> text </p>)
   * @default 'placeholder text';
  */
  text: string | number,
  /**
   * @default 'dataTestId_text_element';
  */
  dataTestId?: string,
  customStyle?: string,
  truncate?: 'truncate' | '',
  width?: number,
};
