import React, { useEffect, useState } from 'react';

import { useDashboard } from 'context/dashboard';
import { useCleanState } from 'hooks/useCleanState';
import { defaultNetwork } from 'reducers/dashboard';
import { TDataTestIdsNewVmMainNetwork } from 'data-test-ids/types/new-vm';
import { GLOSSARY } from 'global-shared/model';
import { MessageFeature, useTenants } from 'global-shared/features';

import { NewInstanceFormContext } from '../../shared/model';

import CustomNetworkSelect from '../../../../../feature/new-instance/network/CustomNetworkSelect';
import CreateNewNetworkSection from '../../../../../feature/new-instance/network/CreateNewNetworkSection';
import { SectionTitleFeature } from '../../shared/features';

/**
 * @description a component that shows a dropdown-menu with networks for a specific cluster
 * and a form for creating a new network
 * @param props
 * @example <NetworkFeature dataTestIds={} />
 */
function Network(props: {dataTestIds: TDataTestIdsNewVmMainNetwork}){
  //state
  const [warning, setWarning] = useState<string>('');

  //props
  const { dataTestIds } = props;

  //variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = stateDashboard;
  const { error } = fetchFunc.networks.get;
  const cleanState = useCleanState();
  const { useDispatch, setInstance, setInstanceValidation } = NewInstanceFormContext;
  const dispatchInstance = useDispatch();

  //hooks
  useEffect(() => {
    return () => {
      cleanState('network', 'post');
    };
  }, [cleanState]);

  useEffect(() => {
    if (selectedTenantId) {
      dispatchInstance(setInstance(defaultNetwork, 'network'));
      dispatchInstance(setInstanceValidation(undefined, 'isNetwork'));
    }
  }, [selectedTenantId, dispatchInstance, setInstance, setInstanceValidation]);

  return (
    <div>
      <SectionTitleFeature
        dataTestId={dataTestIds.wrapper.title.name}
        title={GLOSSARY.network}
      />
      <CustomNetworkSelect />
      <CreateNewNetworkSection
        onWarning={setWarning}
        dataTestIds={dataTestIds.wrapper.newNetwork}
      />
      {warning && <MessageFeature status='warning' msg={warning} />}
      {error && <MessageFeature status='error' msg={error} />}
    </div>
  );
};

export { Network as NetworkFeature };
