import React, { useState } from 'react';

import { useDashboard } from 'context/dashboard';
import { IModalForm } from 'types';
import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { setFetchFunc, setNewNetwork } from 'actions/dashboard';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import { TDataTestIdsPortalModal } from 'data-test-ids/types/general-pages/portalModal';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';
import { API_URLS, GLOSSARY } from 'global-shared/model';
import { INetworkApi, InterfaceNewNetworkApi } from 'global-shared/model/dataTypes';
import { useCleanState } from 'hooks/useCleanState';
import {
  AlertValidationFeature,
  AlertWarningFeature,
  RequestStateFeature,
  useTenants,
} from 'global-shared/features';
import { TEST_ID_NETWORKS_PAGE_ALERT_SECTION } from 'data-test-ids/data-testid-variables';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 * @param props
 * @description the component is present only in the form "create new instance"
 */
export default function CreateNewNetworkForm({onCancel, open, dataTestIds}: TProps) {
  // state
  const [nameError, setNameError] = useState<string>('');
  const [clusterError, setClusterError] = useState<string>('');

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc, newNetwork } = stateDashboard;
  const { loading, status, error } = fetchFunc.network.post;
  const { data: allNetworks } = fetchFunc.networks.get;
  const fetchMethod = 'post';
  const handleFetchRequest = useHandleFetchRequest();
  const { useValue, useDispatch, setInstance, setInstanceForm, setInstanceValidation } = NewInstanceFormContext;
  const dispatchInstance = useDispatch();
  const { form, instance: { cluster: { uuid: clusterUuid, name } } } = useValue();
  const url = `${API_URLS.networks}/${clusterUuid}/${selectedTenantId}?network_name=${newNetwork.network_name}`;
  const hideBtns = status > 0 || loading ? true : false;
  const cleanState = useCleanState();
  const fullNameClusterAndLocation = `${name} - ${form?.location}`;

  // func
  const handleCloseModalForm = () => {
    cleanState('network', 'post');
    dispatchDashboard(
      setNewNetwork({
        tenant_uuid: '',
        cluster_uuid: '',
        network_name: '',
      }),
    );
    onCancel();
  };

  const handleSubmit = async () => {
    const newNetworkWithDefaultCluster = {
      network_name: newNetwork.network_name,
      cluster_uuid: clusterUuid,
      tenant_uuid: selectedTenantId,
    };

    if (!newNetwork.network_name || /^\s*$/.test(newNetwork.network_name)) {
      return setNameError(GLOSSARY.notifications.errors.please_enter_name);
    };
    if (!clusterUuid) {
      return setClusterError(GLOSSARY.notifications.errors.please_select_cluster);
    };

    const newNetworkParams = newNetworkWithDefaultCluster;

    if (selectedTenantId) {
      const request = api.useFetchData<InterfaceNewNetworkApi, INetworkApi>(url, fetchMethod, null, newNetworkParams);
      handleFetchRequest('network', fetchMethod, request);
      const getNewNetwork: INetworkApi = await request;
      dispatchDashboard(setFetchFunc([...allNetworks, getNewNetwork], 'networks', 'get', 'data'));
      dispatchInstance(setInstance(getNewNetwork, 'network'));
      dispatchInstance(setInstanceValidation(false, 'isNetwork'));
      dispatchInstance(setInstanceForm('', 'error'));
      handleCloseModalForm();
    };
  };

  //func
  const handleChangeName = (value: string) => {
    setNameError('');
    dispatchDashboard(
      setNewNetwork({
        ...newNetwork,
        tenant_uuid: selectedTenantId,
        'network_name': value.trim(),
      }),
    );
  };

  return (
    <PortalModal
      open={open}
      onCancel={handleCloseModalForm}
      dataTestId={dataTestIds.portalModal.name}
    >
      <FormModalTemplate
        handleSubmitButtonName={GLOSSARY.actions.create}
        onCancel={handleCloseModalForm}
        handleSubmit={handleSubmit}
        header={`${GLOSSARY.actions.create} ${GLOSSARY.network}`}
        hideBtns={hideBtns}
        dataTestIds={dataTestIds.portalModal.form}
      >
        <RequestStateFeature
          error={error}
          loading={loading}
        >
          <div
            data-testid={dataTestIds.portalModal.form.content.wrapper}
            className='pt-8 px-6'
          >
            <label htmlFor='name' className='relative mb-8'>
              <p>Name</p>
              <input
                type='text'
                name='network_name'
                id='network_name'
                placeholder='Name'
                onChange={(e) => handleChangeName(e.target.value)}
                value={newNetwork.network_name}
                data-testid={dataTestIds.portalModal.form.content.inputText}
                required={nameError ? true : false}
                autoComplete='off'
                className='mb-6'
              />
              {nameError && <AlertValidationFeature text={nameError} />}
            </label>
            <AlertWarningFeature>
              <div
                data-testid={TEST_ID_NETWORKS_PAGE_ALERT_SECTION}
                className='col'
              >
                <p>Create Network for:</p>
                <p>{fullNameClusterAndLocation}</p>
              </div>
            </AlertWarningFeature>
            {clusterError && <AlertValidationFeature text={clusterError} />}
          </div>
        </RequestStateFeature>
      </FormModalTemplate>
    </PortalModal>
  );
};

type TProps = IModalForm & {
  dataTestIds: TDataTestIdsPortalModal,
};
