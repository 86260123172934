import { InterfaceStatus } from 'global-shared/model/dataTypes';

/**
 * @since 1.2.1
 * @description an array of objects with all the statuses that are present in all instances (vms, bms)
 * @version 0.0.1
 * @see {@link https://northerndata.atlassian.net/wiki/spaces/IAAS/pages/2506784857/Instance+Status}
 * @package global-shared/model
 * @example ALL_INSTANCES_STATUSES
 * @returns array
 */
export const ALL_INSTANCES_STATUSES: TypeAllInstanceStatuses = [
  {
    prop: 'active',
    api: 'VM_ACTIVE',
    ui: 'Active',
    color: '#7EE896',
  },
  {
    prop: 'power_off',
    api: 'POWER_OFF',
    ui: 'Power Off',
    color: '#B49E17',
  },
  {
    prop: 'scheduled',
    api: 'SCHEDULED',
    ui: 'In Scheduling',
    color: '#8E8E8E',
  },
  {
    prop: 'network_ready',
    api: 'NETWORK_READY',
    ui: ' Network Ready',
    color: '#4DAAEC',
  },
  {
    prop: 'requested',
    api: 'REQUESTED',
    ui: 'Requesting',
    color: '#ECD54D',
  },
  {
    prop: 'reset',
    api: 'RESET_REQUESTED',
    ui: 'Resetting',
    color: '#EC704D',
  },
  {
    prop: 'reboot',
    api: 'REBOOT_REQUESTED',
    ui: 'Rebooting',
    color: '#276EF1',
  },
  {
    prop: 'error',
    api: 'ERROR',
    ui: 'Error',
    color: '#FF2222',
  },
  {
    prop: 'creation_error',
    api: 'CREATION_ERROR',
    ui: 'Creation Error',
    color: '#FF2222',
  },
  {
    prop: 'deleted',
    api: 'DELETED',
    ui: 'Deleted',
    color: '#FF2222',
  },
  {
    prop: 'deletion_requested',
    api: 'DELETION_REQUESTED',
    ui: 'In Deletion',
    color: '#FF2222',
  },
];

type TypeAllInstanceStatuses = Readonly<InterfaceStatus>[];
