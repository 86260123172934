import { ALL_INSTANCES_STATUSES } from 'global-shared/model';
import { TypographyFeature } from 'global-shared/features';
import { InterfaceTypograhy } from 'global-shared/model/dataTypes';

/**
 * @since 1.0.0
 * @description The component that returns the UI appearance
 * (color and name) for the instance status
 * @param props
 * @version 0.0.3
 * @example
 *  <InstanceStatusFeature
      status={item.name}
      color='customColorDarkBlue60'
      fontSize='xs'
    />
 * @returns React Element | div
*/
function InstanceStatus(props: InterfaceProps) {
  // props
  const {
    status,
    fontSize='base',
    fontWeight='normal',
    color='customColorDarkBlue',
  } = props;

  // variables
  var findStatus = ALL_INSTANCES_STATUSES.find((theStatus) => ((theStatus.ui === status) || (theStatus.api === status)));
  var getStatus = findStatus ||
    {
      prop: '',
      api: '',
      ui: '',
      color: '',
    };

  return (
    <div className='row items-center'>
      <div
        className={`
          w-[10px]
          h-[10px]
          mb-[0.1rem]
          mr-2
          rounded-full
        `}
        style={{ backgroundColor: getStatus.color }}
      />
      <TypographyFeature
        text={getStatus.ui}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
        truncate='truncate'
        width={100}
      />
    </div>
  );
};

export { InstanceStatus as InstanceStatusFeature };

interface InterfaceProps extends InterfaceTypograhy {
  status: string,
};
