function IconBigFullColorLogo() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='60 40 450 229.2'
      xmlSpace='preserve'
    >
      <g>
        <polygon style={{fill: '#303031'}} points='65.5,105.7 114.2,105.7 114.2,113 114.2,113 114.2,144.5 106.8,138.5 106.8,113 74.6,113 	'/>

        <linearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='163.7059' y1='183.5911' x2='141.7012' y2='82.8586' gradientTransform='matrix(1 0 0 -1 0 232)'>
          <stop  offset='0' style={{stopColor: '#7EE896'}}/>
          <stop  offset='0.1181' style={{stopColor: '#61D3A4'}}/>
          <stop  offset='0.2694' style={{stopColor: '#41BCB3'}}/>
          <stop  offset='0.3974' style={{stopColor: '#2EAEBC'}}/>
          <stop  offset='0.4858' style={{stopColor: '#27A9BF'}}/>
          <stop  offset='0.5869' style={{stopColor: '#2DA3C2'}}/>
          <stop  offset='0.7254' style={{stopColor: '#3F91CB'}}/>
          <stop  offset='0.8849' style={{stopColor: '#5B74D9'}}/>
          <stop  offset='1' style={{stopColor: '#745BE6'}}/>
        </linearGradient>
        <path fill='url(#SVGID_1_)' d='M155.7,54.2c-9.5-0.3-18.3,3.8-24,11c-1.2,1.5-0.8,3.6,0.8,4.6l0.8,0.5c1.3,0.8,3.1,0.5,4.1-0.7
		c4.7-5.9,12.1-9,19.9-8.1c10.1,1.2,18.3,9.4,19.3,19.5c1.4,13.3-9,24.5-22,24.5H123v45.9l7.4,5.9v-33.8v-10.8h24.3
		c16.8,0,30.3-14.1,29.3-31.1C183.1,66.9,170.7,54.8,155.7,54.2z'/>

        <linearGradient id='SVGID_00000098178859791326419380000016928416843040736902_' gradientUnits='userSpaceOnUse' x1='72.0317' y1='138.2472' x2='117.6099' y2='191.9026' gradientTransform='matrix(1 0 0 -1 0 232)'>
          <stop  offset='0' style={{stopColor: '#7EE896'}}/>
          <stop  offset='3.503679e-02' style={{stopColor: '#76E29A'}}/>
          <stop  offset='0.2358' style={{stopColor: '#4BC3AE'}}/>
          <stop  offset='0.3928' style={{stopColor: '#31B0BA'}}/>
          <stop  offset='0.4858' style={{stopColor: '#27A9BF'}}/>
          <stop  offset='0.587' style={{stopColor: '#2DA3C2'}}/>
          <stop  offset='0.7242' style={{stopColor: '#3E92CB'}}/>
          <stop  offset='0.8812' style={{stopColor: '#5A75D9'}}/>
          <stop  offset='1' style={{stopColor: '#745BE6'}}/>
        </linearGradient>
        <path fill='url(#SVGID_00000098178859791326419380000016928416843040736902_)' d='M70.7,99.2c-0.8-3.3-1.3-6.6-1.3-10.1
		c0-22.3,17.9-40.4,39.9-40.4c7.5,0,14.7,2.1,20.9,6c1.1,0.7,2.5,0.7,3.6,0.1l1.8-1c1.9-1.1,2-3.8,0.2-5.1c-7.8-5.3-17-8.1-26.5-8.1
		c-26.4,0-48,21.8-48,48.6c0,3.4,0.4,6.8,1,10.1L70.7,99.2L70.7,99.2z'/>
        <g>
          <polygon style={{fill: '#303031'}} points='230.6,113 241.6,113 241.6,66.8 264.2,66.8 264.2,57 208,57 208,66.8 230.6,66.8 		'/>
          <path style={{fill: '#303031'}} d='M283.2,99.9h29l6,13.1h11.9L304,57h-12.3l-26.2,56h11.7L283.2,99.9z M297.7,68.4l10,21.7h-19.9L297.7,68.4z'
          />
          <rect x='338.9' y='57' style={{fill: '#303031'}} width='11' height='56'/>
          <path style={{fill: '#303031'}} d='M413.2,83.5h-21.4v9.8h18.4v0.6c0,1.5-0.7,3.1-2.1,4.6c-1.4,1.6-3.5,2.8-6.1,3.9c-2.6,1.1-5.9,1.6-9.8,1.6
			h-1.3c-3.9,0-7.4-0.7-10.4-2.2s-5.3-3.6-7-6.5C371.8,92.4,371,89,371,85s0.8-7.5,2.5-10.4c1.7-2.8,4-5,7-6.5s6.5-2.2,10.5-2.2h1.3
			c2.9,0,5.3,0.3,7.3,1c2,0.7,3.6,1.5,4.9,2.5s2.2,2.1,2.8,3.3c0.7,1.2,1,2.4,1.2,3.5h11.6c-0.2-3.8-1.5-7.2-3.7-10.2
			c-2.2-3.1-5.4-5.4-9.5-7.2c-4.2-1.7-9.2-2.6-15.2-2.6c-6.6,0-12.3,1.2-17,3.7c-4.8,2.5-8.4,5.9-11,10.2c-2.5,4.3-3.8,9.3-3.8,14.9
			c0,5.7,1.3,10.7,3.8,15c2.5,4.4,6.2,7.8,10.9,10.2c4.8,2.4,10.4,3.6,17,3.6c3.6,0,6.7-0.4,9.1-1.2c2.5-0.8,4.5-1.7,6.1-2.8
			c1.6-1.1,2.7-2.1,3.4-3v6.2h10.6V91.2c0-2.5-0.7-4.4-2-5.7S415.7,83.5,413.2,83.5z'/>
          <path style={{fill: '#303031'}} d='M442.9,99.9h29l6,13.1h11.9l-26.1-56h-12.3l-26.2,56h11.7L442.9,99.9z M457.4,68.4l10,21.7h-19.9L457.4,68.4z
			'/>
          <path style={{fill: '#303031'}} d='M225.8,152.7c2.1,1.1,4.6,1.6,7.4,1.6c2.3,0,4.3-0.4,6.1-1.2c1.8-0.7,3.2-1.7,4.2-3c1.1-1.3,1.7-2.8,2-4.4
			h-5.1c-0.3,1.4-1.1,2.4-2.4,3.1c-1.2,0.7-2.7,1-4.4,1H233c-1.8,0-3.3-0.3-4.6-1.1c-1.2-0.7-2.2-1.7-2.9-3c-0.7-1.2-1-2.6-1-4.2
			c0-1.5,0.3-2.9,1-4.2c0.7-1.2,1.6-2.2,2.9-3c1.3-0.7,2.8-1.1,4.6-1.1h0.6c1.1,0,2.1,0.2,3.1,0.5s1.7,0.8,2.4,1.4
			c0.7,0.6,1.1,1.4,1.3,2.3h5.1c-0.3-1.5-0.9-2.9-1.9-4.2s-2.4-2.3-4.1-3.1c-1.7-0.8-3.8-1.2-6.2-1.2c-2.8,0-5.2,0.6-7.3,1.7
			c-2.1,1.1-3.7,2.6-4.8,4.5c-1.1,1.9-1.7,4.1-1.7,6.5c0,2.5,0.5,4.7,1.6,6.6C222.2,150.1,223.7,151.6,225.8,152.7z'/>
          <polygon style={{fill: '#303031'}} points='261.9,129.2 257,129.2 257,153.9 278.4,153.9 278.4,149.6 261.9,149.6 		'/>
          <path style={{fill: '#303031'}} d='M301.7,154.3c2.7,0,5.1-0.4,7.2-1.5c2.1-1.1,3.8-2.5,5-4.4c1.2-1.9,1.8-4.2,1.8-6.8c0-2.6-0.6-4.9-1.8-6.8
			c-1.2-1.9-2.9-3.4-5-4.4c-2.1-1-4.5-1.5-7.2-1.5s-5.1,0.5-7.2,1.5c-2.1,1.1-3.8,2.5-5,4.4c-1.2,1.9-1.8,4.2-1.8,6.8
			c0,2.6,0.6,4.9,1.8,6.8c1.2,1.9,2.9,3.4,5,4.4C296.7,153.8,299,154.3,301.7,154.3z M292.7,141.6c0-1.7,0.4-3.3,1.2-4.5
			c0.8-1.3,1.9-2.2,3.2-2.9c1.4-0.7,2.8-1,4.4-1h0.6c1.6,0,3.1,0.3,4.4,1c1.3,0.7,2.4,1.7,3.2,2.9c0.8,1.2,1.2,2.7,1.2,4.5
			c0,1.7-0.4,3.2-1.2,4.5c-0.8,1.3-1.9,2.2-3.2,2.9s-2.8,1-4.4,1h-0.6c-1.6,0-3.1-0.3-4.4-1c-1.3-0.7-2.4-1.6-3.2-2.9
			C293.1,144.9,292.7,143.4,292.7,141.6z'/>
          <path style={{fill: '#303031'}} d='M349.8,129.3h-4.9V144c0,2-0.6,3.4-1.6,4.5c-1,1.1-2.5,1.6-4.5,1.6h-0.6c-2,0-3.4-0.6-4.5-1.6
			c-1.1-1-1.6-2.5-1.6-4.5v-14.7h-4.9v14.9c0,3.1,0.9,5.6,2.8,7.4c1.8,1.8,4.6,2.7,8.4,2.7c3.7,0,6.5-1,8.6-2.7
			c1.9-1.9,2.8-4.3,2.8-7.4V129.3z'/>
          <path style={{fill: '#303031'}} d='M381.4,152.3c2-0.9,3.5-2.4,4.5-4.2c1.1-1.8,1.6-4.1,1.6-6.6s-0.6-4.8-1.6-6.6c-1.1-1.8-2.6-3.2-4.5-4.2
			c-2-1-4.2-1.5-6.9-1.5h-11.8v24.6h11.8C377.2,153.8,379.5,153.3,381.4,152.3z M367.6,149.6v-16h6.6c2.7,0,4.8,0.6,6.2,2
			c1.4,1.3,2.1,3.3,2.1,6c0,2.6-0.7,4.6-2.1,6c-1.4,1.3-3.4,2-6.2,2H367.6z'/>
          <path style={{fill: '#303031'}} d='M231.5,178.9c-0.2-0.3-0.6-0.4-0.9-0.5c0.4-0.1,0.8-0.4,1.1-0.8c0.3-0.4,0.5-0.9,0.5-1.5
			c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.5-0.7-0.8-1.2-1c-0.6-0.2-1.1-0.3-1.8-0.3h-7.5v10.5v0.1h7.7c0.7,0,1.3-0.2,1.8-0.4s0.9-0.6,1.2-1
			c0.3-0.4,0.5-0.9,0.5-1.5c0-0.5-0.1-0.9-0.3-1.2C232,179.4,231.8,179.1,231.5,178.9z M223.2,174.9h5.3c0.5,0,1,0,1.3,0.3
			c0.3,0.3,0.5,0.6,0.5,1c0,0.4-0.2,0.7-0.5,1c-0.3,0.3-0.7,0.4-1.3,0.4h-5.3V174.9z M230.1,181.8c-0.3,0.2-0.8,0.3-1.4,0.3h-5.5
			v-2.8h5.5c0.6,0,1.1,0.2,1.4,0.4c0.3,0.2,0.5,0.5,0.5,1S230.4,181.6,230.1,181.8z'/>
          <polygon style={{fill: '#303031'}} points='239.4,177.6 235.7,173.3 233.2,173.3 238.5,179.3 238.5,183.8 240.4,183.8 240.4,179.3 245.6,173.3
			243.1,173.3 		'/>
          <polygon style={{fill: '#303031'}} points='260,181.7 252.8,173.3 250.3,173.3 250.3,183.8 250.3,183.9 252.2,183.9 252.2,175.5 259.4,183.9
			261.9,183.9 261.9,173.4 260,173.4 		'/>
          <path style={{fill: '#303031'}} d='M276,174.6c-0.6-0.4-1.4-0.8-2.2-1c-0.8-0.3-1.7-0.4-2.7-0.4c-0.9,0-1.9,0.2-2.7,0.4c-0.8,0.2-1.6,0.5-2.2,1
			c-0.6,0.5-1.1,1-1.5,1.7c-0.3,0.7-0.5,1.4-0.5,2.3c0,0.9,0.1,1.6,0.5,2.3c0.4,0.7,0.9,1.2,1.5,1.7c0.6,0.4,1.4,0.8,2.2,1
			c0.8,0.3,1.7,0.4,2.7,0.4s1.9-0.2,2.7-0.4c0.8-0.2,1.6-0.5,2.2-1c0.6-0.5,1.2-1,1.5-1.7s0.5-1.4,0.5-2.3c0-0.9-0.1-1.6-0.5-2.3
			C277.1,175.6,276.6,175.1,276,174.6z M275.8,180.2c-0.3,0.5-0.6,0.9-1.1,1.2s-1,0.5-1.6,0.7c-0.6,0.1-1.2,0.2-1.9,0.2h-0.3
			c-0.6,0-1.3-0.1-1.9-0.2c-0.6-0.1-1.1-0.4-1.6-0.7s-0.8-0.7-1.1-1.2s-0.4-1-0.4-1.6c0-0.6,0.1-1.1,0.4-1.6
			c0.3-0.5,0.6-0.9,1.1-1.2c0.5-0.3,1-0.5,1.6-0.7c0.6-0.1,1.2-0.2,1.9-0.2h0.3c0.7,0,1.3,0,1.9,0.2c0.6,0.1,1.1,0.4,1.6,0.7
			c0.5,0.3,0.8,0.7,1.1,1.2s0.4,1,0.4,1.6C276.2,179.2,276.1,179.7,275.8,180.2z'/>
          <path style={{fill: '#303031'}} d='M289.5,173.7c-0.5-0.3-1.2-0.4-1.9-0.4h-7.3v10.5v0.2h1.8v-4.1h5.8c0.4,0,0.7,0.1,0.9,0.2
			c0.2,0.1,0.3,0.4,0.3,0.8v3h1.9v-3.5c0-0.5-0.1-0.9-0.4-1.2s-0.7-0.4-1.3-0.4c0.4-0.1,0.7-0.3,1-0.5c0.3-0.3,0.5-0.6,0.7-0.9
			c0.2-0.4,0.3-0.8,0.3-1.2c0-0.6-0.2-1.1-0.5-1.5C290.5,174.3,290,174,289.5,173.7z M288.8,177.7c-0.4,0.3-0.9,0.4-1.5,0.4h-5.1v-3
			h5.1c0.6,0,1.1,0.1,1.5,0.4c0.3,0.2,0.5,0.6,0.5,1.1C289.3,177,289.2,177.4,288.8,177.7z'/>
          <polygon style={{fill: '#303031'}} points='292.7,175 297.7,175 297.7,183.8 299.7,183.8 299.7,175 304.7,175 304.7,173.3 292.7,173.3 		'/>
          <polygon style={{fill: '#303031'}} points='316.5,177.7 308.4,177.7 308.4,173.3 306.5,173.3 306.5,183.8 308.4,183.8 308.4,179.3 316.5,179.3
			316.5,183.8 318.4,183.8 318.4,173.3 316.5,173.3 		'/>
          <polygon style={{fill: '#303031'}} points='323.2,179.2 331.2,179.2 331.2,177.5 323.2,177.5 323.2,175 332.1,175 332.1,173.3 321.3,173.3
			321.3,183.8 321.3,183.9 332.3,183.9 332.3,182.2 323.2,182.2 		'/>
          <path style={{fill: '#303031'}} d='M343.7,173.7c-0.5-0.3-1.2-0.4-1.9-0.4h-7.3v10.5v0.2h1.8v-4.1h5.8c0.4,0,0.7,0.1,0.9,0.2
			c0.2,0.1,0.3,0.4,0.3,0.8v3h1.9v-3.5c0-0.5-0.1-0.9-0.4-1.2s-0.7-0.4-1.3-0.4c0.4-0.1,0.7-0.2,1-0.5c0.3-0.3,0.5-0.6,0.7-0.9
			c0.2-0.4,0.3-0.8,0.3-1.2c0-0.6-0.2-1.1-0.5-1.5C344.7,174.3,344.2,174,343.7,173.7z M343,177.7c-0.4,0.3-0.9,0.4-1.5,0.4h-5.1v-3
			h5.1c0.6,0,1.1,0.1,1.5,0.4c0.3,0.2,0.5,0.6,0.5,1.1C343.5,177,343.4,177.4,343,177.7z'/>
          <polygon style={{fill: '#303031'}} points='357.8,181.7 350.6,173.3 348.1,173.3 348.1,183.8 348.1,183.9 350,183.9 350,175.5 357.2,183.9
			359.7,183.9 359.7,173.4 357.8,173.4 		'/>
          <path style={{fill: '#303031'}} d='M376.7,173.9c-0.9-0.4-1.9-0.6-3.1-0.6h-6.5v10.4h6.5c1.2,0,2.2-0.2,3.1-0.6c0.9-0.4,1.5-1.1,2.1-1.8
			c0.6-0.7,0.8-1.7,0.8-2.8c0-1.1-0.3-2-0.8-2.8S377.6,174.3,376.7,173.9z M376.5,181.2c-0.7,0.6-1.8,0.9-3.2,0.9H369v-7.2h4.3
			c1.4,0,2.5,0.3,3.2,0.9c0.7,0.6,1.1,1.5,1.1,2.7C377.6,179.7,377.3,180.6,376.5,181.2z'/>
          <path style={{fill: '#303031'}} d='M385.8,173.3l-5.8,10.5h2.2l1.3-2.5h6.9l1.3,2.5h2.2l-5.8-10.5H385.8z M384.5,179.6l2.5-4.7l2.5,4.7H384.5z'
          />
          <polygon style={{fill: '#303031'}} points='405.8,173.3 393.8,173.3 393.8,175 398.8,175 398.8,183.8 400.8,183.8 400.8,175 405.8,175 		'/>
          <path style={{fill: '#303031'}} d='M411.5,173.3l-5.8,10.5h2.2l1.3-2.5h6.9l1.3,2.5h2.2l-5.8-10.5H411.5z M410.2,179.6l2.5-4.7l2.5,4.7H410.2z'
          />
          <path style={{fill: '#303031'}} d='M436.8,178.3h-5.3v1.7h4.7c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.8,0.6-1.5,0.8s-1.5,0.3-2.5,0.3h-0.3
			c-1,0-1.9-0.1-2.6-0.4c-0.8-0.3-1.3-0.7-1.7-1.3c-0.4-0.5-0.6-1.2-0.6-2c0-0.8,0.2-1.4,0.6-2c0.4-0.6,1-1,1.7-1.3s1.5-0.4,2.5-0.4
			h0.3c0.7,0,1.3,0.1,1.8,0.2c0.5,0.1,0.9,0.3,1.2,0.5c0.3,0.2,0.5,0.5,0.7,0.7c0.2,0.3,0.3,0.5,0.3,0.7h2.1
			c-0.1-0.7-0.4-1.3-0.9-1.9c-0.5-0.5-1.3-1-2.1-1.3c-0.8-0.3-1.9-0.5-3.2-0.5c-1.5,0-2.8,0.2-3.8,0.7c-1,0.4-1.8,1.1-2.4,1.9
			c-0.5,0.8-0.8,1.7-0.8,2.8c0,1.1,0.3,2,0.8,2.8s1.3,1.4,2.3,1.9s2.3,0.7,3.8,0.7c0.9,0,1.7-0.1,2.3-0.2c0.6-0.2,1.1-0.4,1.5-0.6
			c0.4-0.3,0.6-0.5,0.8-0.7v1.4h2.1v-4.2c0-0.4-0.1-0.7-0.3-1S437.3,178.3,436.8,178.3z'/>
          <path style={{fill: '#303031'}} d='M449.5,173.7c-0.5-0.3-1.2-0.4-1.9-0.4h-7.3v10.5v0.2h1.8v-4.1h5.8c0.4,0,0.7,0.1,0.9,0.2
			c0.2,0.1,0.3,0.4,0.3,0.8v3h1.9v-3.5c0-0.5-0.1-0.9-0.4-1.2s-0.7-0.4-1.3-0.4c0.4-0.1,0.7-0.2,1-0.5c0.3-0.3,0.5-0.6,0.7-0.9
			c0.2-0.4,0.3-0.8,0.3-1.2c0-0.6-0.2-1.1-0.5-1.5C450.5,174.3,450,174,449.5,173.7z M448.8,177.7c-0.4,0.3-0.9,0.4-1.5,0.4h-5.1v-3
			h5.1c0.6,0,1.1,0.1,1.5,0.4c0.3,0.2,0.5,0.6,0.5,1.1C449.3,177,449.2,177.4,448.8,177.7z'/>
          <path style={{fill: '#303031'}} d='M465,174.6c-0.6-0.4-1.4-0.8-2.2-1c-0.8-0.3-1.7-0.4-2.7-0.4c-0.9,0-1.9,0.2-2.7,0.4c-0.8,0.2-1.6,0.5-2.2,1
			c-0.6,0.5-1.2,1-1.5,1.7c-0.3,0.7-0.5,1.4-0.5,2.3c0,0.9,0.1,1.6,0.5,2.3c0.4,0.7,0.9,1.2,1.5,1.7c0.6,0.4,1.4,0.8,2.2,1
			c0.8,0.3,1.7,0.4,2.7,0.4s1.9-0.2,2.7-0.4c0.8-0.2,1.6-0.5,2.2-1c0.6-0.5,1.1-1,1.5-1.7c0.3-0.7,0.5-1.4,0.5-2.3
			c0-0.9-0.1-1.6-0.5-2.3C466.1,175.6,465.6,175.1,465,174.6z M464.9,180.2c-0.3,0.5-0.6,0.9-1.1,1.2s-1,0.5-1.6,0.7
			c-0.6,0.1-1.2,0.2-1.9,0.2H460c-0.7,0-1.3-0.1-1.9-0.2c-0.6-0.1-1.1-0.4-1.6-0.7s-0.8-0.7-1.1-1.2s-0.4-1-0.4-1.6
			c0-0.6,0.1-1.1,0.4-1.6s0.6-0.9,1.1-1.2c0.5-0.3,1-0.5,1.6-0.7c0.6-0.1,1.2-0.2,1.9-0.2h0.3c0.7,0,1.3,0,1.9,0.2
			c0.6,0.1,1.1,0.4,1.6,0.7c0.5,0.3,0.8,0.7,1.1,1.2s0.4,1,0.4,1.6C465.3,179.2,465.2,179.7,464.9,180.2z'/>
          <path style={{fill: '#303031'}} d='M478.3,179.1c0,1.1-0.3,2-0.9,2.5c-0.6,0.5-1.5,0.8-2.6,0.8h-0.3c-1.2,0-2-0.2-2.6-0.8
			c-0.6-0.6-0.9-1.4-0.9-2.5v-5.7h-1.9v5.8c0,0.9,0.2,1.8,0.6,2.5c0.4,0.7,1,1.3,1.8,1.7c0.8,0.4,1.9,0.6,3.1,0.6
			c1.2,0,2.2-0.2,3.1-0.6c0.9-0.4,1.5-1,1.9-1.7c0.4-0.7,0.6-1.5,0.6-2.5v-5.8h-1.9V179.1z'/>
          <path style={{fill: '#303031'}} d='M493.4,174.9c-0.4-0.5-0.8-0.9-1.4-1.2c-0.6-0.3-1.3-0.4-2.1-0.4h-7v10.5h1.9v-3.9h5.1c0.8,0,1.5-0.1,2.1-0.4
			c0.6-0.3,1.1-0.7,1.4-1.2s0.5-1.1,0.5-1.7C493.9,176,493.7,175.4,493.4,174.9z M491.3,177.8c-0.4,0.3-0.9,0.4-1.7,0.4h-4.8V175
			h4.8c0.8,0,1.3,0.1,1.7,0.4c0.4,0.3,0.6,0.7,0.6,1.2S491.7,177.5,491.3,177.8z'/>
        </g>
      </g>
    </svg>
  );
};

export { IconBigFullColorLogo };
