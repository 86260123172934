import { UUID } from 'crypto';

import React from 'react';

import { useDashboard } from 'context/dashboard';
import ActionItem from 'feature/actions/ActionItem';
import { TDataTestIdsOverviewPageContentMainHeaderNetworkPage } from 'data-test-ids/types/overview-page';
import { TypographyFeature } from 'global-shared/features';
import { useGetPageRoutesHook } from 'global-shared/hooks';

/**
 * @description The Header component is used on the ip page and
 * contains the name and action delete.
 * @param props
 * @returns React node - LightGrayWrapper component
*/
export default function Header({ dataTestIds }: {dataTestIds: TDataTestIdsOverviewPageContentMainHeaderNetworkPage}) {
  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.ip.get;
  const { getDetailsPageRoute } = useGetPageRoutesHook();

  return (
    <>
      <TypographyFeature
        text={data.address4}
        truncate='truncate'
        width={660}
      />
      <div
        data-testid='test_id_ip_page_header_wrapper'
        className='row'
      >
        <ActionItem
          uuid={data.uuid}
          route={getDetailsPageRoute('ipv4s', data.uuid as UUID)}
          url='network/ipv4'
          page='ip'
          fetchMethod='delete'
          actionName='DELETED'
          itemName={data.address4}
          dataTestIds={dataTestIds.buttonDelete}
          typeAction='button-action'
        />
      </div>
    </>
  );
};
