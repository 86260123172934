
import { InterfaceNetwork } from './dataTypes';

export const defaultNetwork: InterfaceNetwork = {
  tenant_uuid: '',
  cluster_uuid: '',
  network_name: '',
  uuid: '',
  prefix6: '',
  assigned_addresses6: [
    {
      vm_uuid: '',
      address6: '',
    },
  ],
};
