import { ReactNode } from 'react';

import { ButtonFeature } from '../button';
import { TypographyFeature } from '../typography';
import { TEST_IDS } from '../../model';

/**
 * @description A component that returns the main title of
 * a web page with/without a button
 * @param props
 * @example
 *  <PageHeader
      headerTitle={GLOSSARY.descriptions.welcome_to_our_taiga}
      btnTitle=''
      isShowBtn={true}
      onClick={() => {}}
    />
 */
function PageHeader(props: InterfaceProps) {
  // props
  const {
    headerTitle,
    btnTitle='button',
    isShowBtn=true,
    onClick=() => {},
    children=<></>,
  } = props;

  return(
    <div className='flex items-start mb-[21px] mt-12 min-h-[3.5rem]'>
      <div className='sm:flex-auto'>
        <TypographyFeature
          text={headerTitle}
          fontWeight='medium'
        />
        {children}
      </div>
      <hr />
      {isShowBtn &&
        <ButtonFeature
          type='button'
          uiStyle='primary'
          title={btnTitle}
          onClick={onClick}
          dataTestId={TEST_IDS.page_header.btn}
        />
      }
    </div>
  );
};

export { PageHeader as PageHeaderFeature };

interface InterfaceProps {
  headerTitle: string,
  btnTitle?: string,
  isShowBtn?: boolean,
  onClick?: () => void,
  children?: ReactNode,
};
