import React from 'react';

import { useDashboard } from 'context/dashboard';
import { GLOSSARY, IOptionSelect, TypeNetworkSelect } from 'global-shared/model';
import { SelectFeature, SpinnerFeature, TypographyFeature } from 'global-shared/features';
// eslint-disable-next-line no-restricted-imports
import { useFilterNetworksByClusterHook } from 'pages/instances/new/shared/hooks';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';

import classes from '../instance.module.scss';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 * @description a component that returns a menu with networks depending on the cluster
 * @example <CustomNetworkSelect />
 */
function CustomNetworkSelect() {
  //variables
  const { useValue, useDispatch, setInstance, setInstanceForm, setInstanceValidation } = NewInstanceFormContext;
  const dispatchInstance = useDispatch();
  const { form, instance, validation } = useValue();
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { loading } = fetchFunc.networks.get;
  const theNetworks = useFilterNetworksByClusterHook(instance.cluster.uuid);
  const networks: TypeNetworkSelect[] = theNetworks.map(n => ({...n, type: 'networks'}));

  //func
  const showCorrectValue = () => {
    if(theNetworks?.length === 0){
      return GLOSSARY.empty_state.you_dont_have_networks;
    }

    if(instance.network.network_name) {
      return instance.network.network_name;
    }

    return `Select ${GLOSSARY.network}` ;
  };

  const handleSelect = (option: IOptionSelect) => {
    const networkArray = theNetworks?.filter((network) => network?.uuid === option.uuid);
    const networkObject = {};
    for(let i = 0; i < networkArray.length; i++ ) {
      Object.assign(networkObject, networkArray[i]);
    }
    dispatchInstance(setInstance(networkObject, 'network'));
    dispatchInstance(setInstanceValidation(false, 'isNetwork'));
    dispatchInstance(setInstanceForm('', 'error'));
  };

  return (
    <>
      {loading ?
        <div className={`${classes.sectionWrapperClass} justify-center`}>
          <SpinnerFeature />
        </div>
        :
        <SelectFeature
          name='Network'
          value={showCorrectValue()}
          options={networks}
          dataTestId='new_vm_page_network_select_name'
          isDisabled={theNetworks?.length === 0 ? true : false}
          isError={validation.isNetwork}
          onSelect={handleSelect}
        />
      }
      <div className='flex items-start row'>
        <TypographyFeature
          text={'Network option for'}
          fontSize='xxxs'
          fontWeight='normal'
          customStyle='mt-5px ml-4 inline'
        />
        &nbsp;
        <TypographyFeature
          text={`${form?.location}`}
          fontSize='xxxs'
          fontWeight='bold'
          customStyle='mt-5px inline'
        />
      </div>
    </>
  );
};

export default CustomNetworkSelect;
