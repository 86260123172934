import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { TCustomIndexPage } from 'data-test-ids/types/general-pages';
import { ReactNode } from 'react';

import classes from './modal.module.scss';

type TProps = {
  header: ReactNode,
  onCancel: () => void;
  dataTestId?: `${TCustomIndexPage}_new`,
};

/**
 *
 * @deprecated
 */
const Header = ({ header, onCancel, dataTestId }: TProps) => {
  return (
    <Dialog.Title
      as='h3'
      className={`${classes.dialogTitleClass} border-b-[#f0f2f5]`}
      data-testid={dataTestId}
    >
      <div data-testid={`${dataTestId}_title`} className='truncate w-3/4'>{header}</div>
      <XMarkIcon onClick={onCancel} className='w-5 h-5 cursor-pointer' />
    </Dialog.Title>
  );
};

export default Header;
