import { UUID } from 'crypto';

import { useHistory } from 'react-router-dom';

import { useGetPageRoutesHook } from 'global-shared/hooks';
import { TypeWebNewPage, TypeWebPage } from 'global-shared/model/dataTypes';

/**
 * @since 1.2.1
 * @description The hook that helps easily
 * navigate between project routes
 * @version 0.0.1
 * @package page/overview/hooks
 * @example
 *   const {
      handleNavigationDetailsPage,
      handleNavigationNewPage,
      handleNavigationPage,
    } = useNavigationPath();
 * @returns object with functions
 */
function useNavigationPath(){
  // variables
  const history = useHistory();
  const { getDetailsPageRoute, getNewPageRoute, getPageRoute } = useGetPageRoutesHook();

  // func
  const handleNavigationDetailsPage = (page: TypeWebPage, uuid: UUID) => {
    return history.push(getDetailsPageRoute(page, uuid));
  };

  const handleNavigationNewPage = (page: TypeWebNewPage) => {
    return history.push(getNewPageRoute(page));
  };

  const handleNavigationPage = (page: TypeWebPage) => {
    return history.push(getPageRoute(page));
  };

  return {
    handleNavigationDetailsPage,
    handleNavigationNewPage,
    handleNavigationPage,
  };
};

export { useNavigationPath as useNavigationPathHook };
