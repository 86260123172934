import React, { useEffect } from 'react';

import { TypographyFeature } from 'global-shared/features';
import { GLOSSARY } from 'global-shared/model';

import { Tenants, User, Uuids } from './features';

/**
 * @description The Profile page
*/
export function Profile() {
  //hooks
  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | ${GLOSSARY.profile}`;
  }, []);

  return (
    <div
      className='col'
      data-testid='test_id_tenants_page'
    >
      <TypographyFeature
        text={GLOSSARY.profile}
        customStyle='mt-12'
        fontSize='base'
        fontWeight='medium'
      />
      <User />
      <Tenants />
      <Uuids />
    </div>
  );
};
