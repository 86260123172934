const IconDownArrowGray = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-4 w-4'
    fill='none'
    viewBox='0 0 24 24'
    stroke='#CFCFCF'
    strokeWidth='1.5'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M19 9l-7 7-7-7'
    />
  </svg>
);

export { IconDownArrowGray };
