import React, { ReactNode } from 'react';

import classes from './index.module.scss';

type uiStyleButtons =
  'primary' |
  'secondary' |
  'red' |
  'white-with-icon-tooltip' |
  'gray' |
  'without-style' |
  'tab' |
  'only-text' |
  'menu-with-title-action'

type TProps = {
  /**
   * @default primary
   */
  uiStyle: uiStyleButtons,
  handleClick?: (event?: any) => void,
  title?: string,
  /**
   * @default button
   */
  type?: 'button' | 'submit' | 'reset' | undefined,
  /**
   * @default false
   */
  disabled?: boolean,
  /**
   * @default ''
   */
  customStyle?: string,
  /**
   * @default ''
   */
  children?: ReactNode,
  /**
   * @default ''
   */
  dataTestId?: string,
  handleMouseEnter?: () => void,
  handleMouseLeave?: () => void,
  /**
   * @default big
   */
  size?: 'small' | 'big'
};

/**
 * @deprecated
 * @description Button - a custom button component
 * @param uiStyle required
 * @param handleClick
 * @param title
 * @param type
 * @param disabled
 * @param customStyle
 * @param children
 * @param dataTestId
 * @param handleMouseEnter
 * @param handleMouseLeave
 * @param size
 *
 * @example
    <Button
      type='button'
      uiStyle='primary'
      handleClick={onClick}
      title={buttonTitle}
      dataTestId={dataTestIds.button}
    />
 *
 * @returns button
 */

export default function Button(props: TProps) {
  // props
  const {
    uiStyle='primary',
    handleClick,
    title,
    type,
    disabled=false,
    customStyle='',
    children='',
    dataTestId,
    handleMouseEnter,
    handleMouseLeave,
    size='big',
  } = props;

  // style
  const {
    primaryButtonClass,
    secondaryButtonClass,
    redButtonClass,
    whiteButtonWithIconAndTooltipClass,
    menuActionItemButtonClass,
    grayButtonClass,
    grayTabButtonClass,
    onlyTextButtonClass,
    disabledButtonClass,
    smallButtonClass,
    bigButtonClass,
    withoutStyleButtonClass,
  } = classes;

  // variables
  const isDisableStyle = disabled ? disabledButtonClass : '';
  const isSmallButton = size === 'small' ? smallButtonClass : bigButtonClass;

  // func
  const setUiStyle = () => {
    if (uiStyle === 'primary') return primaryButtonClass;
    if (uiStyle === 'secondary') return secondaryButtonClass;
    if (uiStyle === 'red') return redButtonClass;
    if (uiStyle === 'white-with-icon-tooltip') return whiteButtonWithIconAndTooltipClass;
    if (uiStyle === 'menu-with-title-action') return menuActionItemButtonClass;
    if (uiStyle === 'gray') return grayButtonClass;
    if (uiStyle === 'tab') return grayTabButtonClass;
    if (uiStyle === 'only-text') return onlyTextButtonClass;
    return '';
  };

  const getUiStyle = () => {
    if (uiStyle === 'without-style') {
      return `${withoutStyleButtonClass} ${isDisableStyle} ${customStyle}`;
    }
    return `${setUiStyle()} ${isDisableStyle} ${isSmallButton} ${customStyle}`;
  };

  return (
    <button
      data-testid={dataTestId}
      type={type}
      onClick={handleClick}
      className={getUiStyle()}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {title && title}
      {children}
    </button>
  );
};
