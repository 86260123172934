import { UUID } from 'crypto';

import React, { ChangeEvent, useState } from 'react';

import { AlertValidationFeature, ButtonFeature, SelectFeature, TypographyFeature } from 'global-shared/features';
import { DATA_TEST_IDS_TABS, GLOSSARY, IOptionSelect, TEST_IDS, TypeClusterSelect} from 'global-shared/model';
import { useMakeGetRequestClustersApi } from 'global-shared/api';

/**
 * @description
 * @param props
 * @example <FormTabsFeature />
 */
function FormTabs(props: InterfaceProps) {
  //state
  const [tab, setTab] = useState<'clusters' | 'uuid'>('clusters');
  const [selectedClusterName, setSelectedClusterName] = useState<string>('');

  //props
  const {
    clusterUuid,
    clusterError,
    onHandleClusterError,
    onChange,
  } = props;

  //variables
  const { request: { response } } = useMakeGetRequestClustersApi();
  const clusters: TypeClusterSelect[] = response.map((r) => ({...r, type: 'clusters'}));

  //func
  const handleTab = (type: 'clusters' | 'uuid') => {
    onChange('');
    setTab(type);
    onHandleClusterError('');
    setSelectedClusterName('');
  };

  const showCorrectValue = () => {
    if(selectedClusterName) {
      return selectedClusterName;
    };

    return 'Select Cluster';
  };

  const handleSelect = (option: IOptionSelect) => {
    onChange(option.uuid);
    onHandleClusterError('');
    setSelectedClusterName(option.name);
  };

  const handleClusterUuid = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(value);
    onHandleClusterError('');
    setSelectedClusterName('');
  };

  return (
    <div className='col gap-8'>
      <div className='row my-1'>
        <ButtonFeature
          title={GLOSSARY.descriptions.available_clusters}
          uiStyle='tab'
          customStyle={tab === 'clusters' ? 'border border-customColorLightGreen mx-1' : 'mx-1' }
          onClick={() => handleTab('clusters')}
          dataTestId={DATA_TEST_IDS_TABS.AVAILABLE_CLUSTERS_TAB}
        />
        <ButtonFeature
          title={GLOSSARY.descriptions.use_a_uuid}
          uiStyle='tab'
          customStyle={tab === 'uuid' ? 'border border-customColorLightGreen' : '' }
          onClick={() => handleTab('uuid')}
          dataTestId={DATA_TEST_IDS_TABS.USE_UUID_TAB}
        />
      </div>
      {tab === 'clusters' ?
        <div className='flex flex-col gap-2 relative'>
          <TypographyFeature
            text='Clusters'
            fontSize='s'
            fontWeight='medium'
            customStyle='ml-5'
          />
          <SelectFeature
            name='Cluster'
            value={showCorrectValue()}
            options={clusters}
            dataTestId={TEST_IDS.modal.cluster_select}
            isDisabled={response.length === 0 ? true : false}
            isError={clusterError ? true : false}
            onSelect={handleSelect}
          />
          {clusterError &&
            <AlertValidationFeature
              text={clusterError}
            />
          }
        </div>
        :
        <label htmlFor='cluster_uuid' className='relative'>
          <p>UUID</p>
          <input
            type='text'
            name='cluster_uuid'
            id='cluster_uuid'
            placeholder='Type uuid of cluster'
            onChange={(e) => handleClusterUuid(e)}
            value={clusterUuid}
            data-testid='test_id_cluster_uuid'
            required={clusterError ? true : false}
            autoComplete='off'
          />
          {clusterError && <AlertValidationFeature text={clusterError} />}
        </label>
      }
    </div>
  );
};

export { FormTabs as FormTabsFeature };

interface InterfaceProps {
  clusterUuid: UUID | string,
  clusterError: string,
  onChange: (uuid: UUID | string) => void,
  onHandleClusterError: (value: string) => void,
};
