import React from 'react';
import { NavLink } from 'react-router-dom';

import { useGetPageRoutesHook } from 'global-shared/hooks';
import { GLOSSARY, TEST_IDS } from 'global-shared/model';
import { ElementWithTooltipFeature, TypographyFeature } from 'global-shared/features';

import classes from '../index.module.scss';
import { IconInstances, IconIpv4s, IconNetworks, IconOverview, IconSshKeys } from '../assets';

export default function RoutesComponent() {
  //variables
  const { getPageRoute } = useGetPageRoutesHook();
  const routers: InterfaceRoute[] = [
    {
      path: '/',
      title: GLOSSARY.overview,
      icon: IconOverview,
      exact: true,
      testId: TEST_IDS.sidebar.routes.overview,
    },
    {
      path: getPageRoute('instances'),
      title: GLOSSARY.instances_plural,
      icon: IconInstances,
      exact: false,
      testId: TEST_IDS.sidebar.routes.instances,
    },
    {
      path: getPageRoute('networks'),
      title: GLOSSARY.networks_plural,
      icon: IconNetworks,
      exact: false,
      testId: TEST_IDS.sidebar.routes.networks,
    },
    {
      path: getPageRoute('sshKeys'),
      title: GLOSSARY.ssh_keys_plural,
      icon: IconSshKeys,
      exact: false,
      testId: TEST_IDS.sidebar.routes.ssh_keys,
    },
    {
      path: getPageRoute('ipv4s'),
      title: GLOSSARY.ipv4s_plural,
      icon: IconIpv4s,
      exact: false,
      testId: TEST_IDS.sidebar.routes.ipv4s,
    },
  ];

  return (
    <>
      {routers.map(route => (
        <div key={route.path}>
          <div
            className='max-2xl:block hidden'
          >
            <ElementWithTooltipFeature
              text={route.title}
              element={
                <CustomLink
                  route={route}
                />
              }
              position='right'
            />
          </div>
          <div
            className='2xl:block hidden'
          >
            <CustomLink
              route={route}
            />
          </div>
        </div>
      ))}
    </>
  );
};

interface InterfaceRoute {
  path: string,
  title: string,
  icon: any,
  exact: boolean,
  testId: string,
};

const CustomLink = ({route}: {route: InterfaceRoute}) => (
  <NavLink
    className={classes.navlinkClass}
    activeClassName={classes.activeClass}
    to={route.path}
    exact={route.exact}
  >
    <div className={classes.navIconLinkClass}>
      <route.icon />
    </div>
    <TypographyFeature
      text={route.title}
      color='white'
      customStyle={classes.navTitleLinkClass}
      fontSize='s'
      fontWeight='thin'
      dataTestId={route.testId}
    />
  </NavLink>
);
