import { InterfaceFlavor } from './data-types';

export const BMS_FLAVORS: InterfaceFlavor[] = [
  {
    cluster: {
      uuid: '',
      name: '',
    },
    selected: true,
    core_count: 128,
    gpu_count: 8,
    gpu_type: 'H100',
    name: 'H100-BareMetal-InfiniBand',
    nvswitch_count: 8,
    ram: 2048,
    slug: 'CPU_128-RAM_2048-STORAGE_4000-GPU_8-GPUTYPE_H100-BAREMETAL',
    storage_size: 4000,
    uuid: '15f71841-0a8d-00fc-93ca-d0e67516c451', //fake uuid for demo
    available: false,
    cpu_type: 'CPU',
    nvswitch_type: 'NVSWITCH H100',
  },
];
