import { useRef, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import TooltipContainer from '../tooltip/index';

/**
 * @description The PortalTooltip component
*/
const PortalTooltip = (props: InterfaceProps) => {
  //state
  const [tooltipHeight, setTooltipHeight] = useState(0);

  //props
  const {
    text='default text',
    description,
    targetRect,
    position,
  } = props;

  //hooks
  const ref = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const { height } = ref?.current?.getBoundingClientRect();
      setTooltipHeight(height);
    }
  }, []);

  //variables
  let tooltipX = 0;
  let tooltipY = 0;

  if (targetRect !== null) {
    tooltipX = targetRect.left;
    tooltipY = targetRect.top - tooltipHeight;
    if (tooltipY < 0) {
      // It doesn't fit above, so place below.
      tooltipY = targetRect.bottom;
    };
  };

  return createPortal(
    <TooltipContainer
      x={tooltipX}
      y={tooltipY}
      contentRef={ref}
      text={text}
      position={position}
      description={description}
    />,
    document.body,
  );
};

export { PortalTooltip };

interface InterfaceProps {
  /**
   * @default 'tooltip ui component'
  */
  text?: string,
  description?: string,
  /**
   * @default 'test_id_tooltip'
  */
  dataTestId?: string,
  targetRect: any,
  position: 'left' | 'top' |  'right' | 'bottom',
};
