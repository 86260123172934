import React, { useEffect, useState } from 'react';

import { useDashboard } from 'context/dashboard';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import ContentViewMultyItems from 'feature/bulk-operations/delete/ContentViewMultyItems';
import useCreateErrorsObjects from 'feature/bulk-operations/delete/useCreateErrorsObjects';
import useCreateSuccesObjects from 'feature/bulk-operations/delete/useCreateSuccesObjects';
import { TActionsDependsOfStatus, TErrorNotifications, TSuccessNotifications } from 'types';
import useUpdateStatusVmsItems from 'feature/bulk-operations/delete/useUpdateStatusVmsItems';
import { setNotifi, setPreselectedRemovingItems } from 'actions/dashboard';
import getVMsStatus from 'helpers/getStyleVMStatus';
import { ButtonFeature, ModalBulkOperationsFeature, TypographyFeature } from 'global-shared/features';
import useSelectedOrDeselectedAllRemovingItemsVms from 'hooks/useSelectedOrDeselectedAllRemovingItemsVms';
import ModalWindowBulkOperations from 'feature/bulk-operations/delete/ModalWindowBulkOperations';
import { DATA_TEST_IDS_BULK_OPERATIONS, GLOSSARY } from 'global-shared/model';

export default function VirtualMachinesActions() {
  // state
  const [openPortalModalWindowUpdateStatusVms, setOpenPortalModalWindowUpdateStatusVms] = useState<boolean>(false);
  const [updateStatusOfVm, setUpdateStatusOfVm] = useState<TActionsDependsOfStatus>('VM_ACTIVE');
  const [successNotifications, setSuccessNotifications] = useState<TSuccessNotifications[]>([]);
  const [errorNotifications, setErrorNotifications] = useState<TErrorNotifications[]>([]);
  const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(false);

  // variables
  const page = 'virtualmachine';
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc, preselectedRemovingItems, instanceName } = state;
  const { loading: updateLoading } = fetchFunc[page].post;
  const newErrorsObject = useCreateErrorsObjects(errorNotifications);
  const newSuccessObject = useCreateSuccesObjects(successNotifications);
  const howManyItemsSelected = preselectedRemovingItems[page].uuid.length;
  const hideBtnsUpdate = updateLoading || (newErrorsObject.length !== 0 || newSuccessObject.length !== 0);
  const isPluralFormName = howManyItemsSelected === 1 ? GLOSSARY.virtual_machine : GLOSSARY.virtual_machines_plural;
  const handleUpdateStatusVms = useUpdateStatusVmsItems(
    setSuccessNotifications,
    setErrorNotifications,
    'virtualmachine',
    'post',
    updateStatusOfVm,
  );
  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingItemsVms(false, page);

  // func
  useEffect(() => {
    return () => {
      dispatch(setPreselectedRemovingItems(page, 'uuid', []));
      dispatch(setPreselectedRemovingItems(page, 'name', []));
      setErrorNotifications([]);
      setSuccessNotifications([]);
    };
  }, [dispatch, page]);

  const handleItemsModalUpdateVms = (action: TActionsDependsOfStatus) => {
    setUpdateStatusOfVm(action);
    setOpenPortalModalWindowUpdateStatusVms(true);
    setErrorNotifications([]);
    setSuccessNotifications([]);
  };

  const handleCloseItemsModalUpdateVms = () => {
    setOpenPortalModalWindowUpdateStatusVms(false);
  };

  const handleRemoveItemsModal = () => {
    dispatch(setNotifi('errors', []));
    dispatch(setNotifi('success', []));
    setOpenPortalModalWindow(true);
  };

  return (
    <>
      {howManyItemsSelected >= 1 ?
        <ModalBulkOperationsFeature>
          <ButtonFeature
            uiStyle='without-style'
            onClick={handleDeselectedAllItems}
            isDisabled={howManyItemsSelected=== 0 ? true : false}
            customStyle='mr-5 text-white'
            size='small'
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.CLOSE_BTN}
          >
            X
          </ButtonFeature>
          <TypographyFeature
            text={`${howManyItemsSelected} items selected`}
            fontSize='xs'
            fontWeight='light'
            color='white'
            customStyle='mr-10'
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.SELECTED_ITEMS_TEXT}
          />
          <ButtonFeature
            uiStyle='dark-blue'
            onClick={() => handleItemsModalUpdateVms('POWER_OFF')}
            isDisabled={howManyItemsSelected=== 0 ? true : false}
            customStyle='mr-1'
            size='small'
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.POWER_OFF_BTN}
          >
            Power off
          </ButtonFeature>
          <ButtonFeature
            uiStyle='dark-blue'
            onClick={() => handleItemsModalUpdateVms('VM_ACTIVE')}
            isDisabled={howManyItemsSelected=== 0 ? true : false}
            customStyle='mr-1'
            size='small'
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.POWER_ON_BTN}
          >
            Power on
          </ButtonFeature>
          <ButtonFeature
            uiStyle='dark-blue'
            onClick={() => handleItemsModalUpdateVms('REBOOT_REQUESTED')}
            isDisabled={howManyItemsSelected=== 0 ? true : false}
            customStyle='mr-1'
            size='small'
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.REBOOT_BTN}
          >
            Reboot
          </ButtonFeature>
          <ButtonFeature
            uiStyle='dark-blue'
            onClick={() => handleItemsModalUpdateVms('RESET_REQUESTED')}
            isDisabled={howManyItemsSelected=== 0 ? true : false}
            customStyle='mr-1'
            size='small'
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.RESET_BTN}
          >
            Reset
          </ButtonFeature>
          <ButtonFeature
            uiStyle='dark-blue'
            onClick={handleRemoveItemsModal}
            isDisabled={howManyItemsSelected=== 0 ? true : false}
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.DELETE_BTN}
            size='small'
          >
            Delete
          </ButtonFeature>
        </ModalBulkOperationsFeature>
        : null
      }
      {openPortalModalWindowUpdateStatusVms &&
        <PortalModal
          open={openPortalModalWindowUpdateStatusVms}
          onCancel={handleCloseItemsModalUpdateVms}
        >
          <FormModalTemplate
            handleSubmitButtonName={getVMsStatus(updateStatusOfVm).action}
            onCancel={handleCloseItemsModalUpdateVms}
            handleSubmit={handleUpdateStatusVms}
            hideBtns={hideBtnsUpdate}
            redBtn={true}
            header={`${getVMsStatus(updateStatusOfVm).action} ${isPluralFormName}`}
          >
            <ContentViewMultyItems
              actionName={updateStatusOfVm}
              itemsName={instanceName}
              fetchMethod='post'
              page={page}
              numberOfItems={howManyItemsSelected || 0}
              errorsObject={newErrorsObject}
              successObject={newSuccessObject}
            />
          </FormModalTemplate>
        </PortalModal>
      }
      <ModalWindowBulkOperations
        openPortalModalWindow={openPortalModalWindow}
        setOpenPortalModalWindow={setOpenPortalModalWindow}
        page={page}
        itemsName={GLOSSARY.virtual_machine as 'Virtual Machine'}
      />
    </>
  );
};
