import React from 'react';
import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid';

// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';
import { InterfaceIpv4 } from 'global-shared/model/dataTypes';
import { GLOSSARY } from 'global-shared/model';

import useRemoveIp from './useRemoveIp';

import classes from '../instance.module.scss';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 * @param param0
 * @returns
 */
export default function SelectedIps({dataTestIds}: {dataTestIds: {}}) {
  //variables
  const { useValue } = NewInstanceFormContext;
  const { instance: { ips: ipAddresses} } = useValue();
  const handleRemoveIp = useRemoveIp();

  return (
    <div data-testid={dataTestIds}>
      {ipAddresses?.map((ip: InterfaceIpv4)  =>
        <div
          key={ip.address4}
          className={classes.ipItemClass}
        >
          {ip.address4}
          <ArchiveBoxXMarkIcon
            onClick={() => handleRemoveIp(ip.address4)}
            className='w-4 fill-customColorDarkBlue cursor-pointer'
          />
        </div>,
      )}
      {ipAddresses.length === 0 &&
        <div className={`${classes.sectionWrapperClass} justify-start pl-6 bg-customBgLightGray2`}>
          <p className='text-customGray3 mr-6 text-sm font-medium'>{GLOSSARY.ipv4}</p>
          <p className='text-sm font-medium'> No {GLOSSARY.ipv4s_plural} added </p>
        </div>
      }
      <p className='text-xxs font-normal ml-4 mt-[5px]'>
        Added {ipAddresses.length} {ipAddresses.length === 1 ? GLOSSARY.ipv4 : GLOSSARY.ipv4s_plural}
      </p>
    </div>
  );
};
