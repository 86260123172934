import React, { ReactNode, useEffect, useState } from 'react';

import { NotificationFeature, SpinnerFeature, TypographyFeature } from 'global-shared/features';
import { InterfaceFlavorAPI } from 'global-shared/model/dataTypes';
import { GLOSSARY } from 'global-shared/model';
import { useMakeGetRequestFlavorsApi } from 'global-shared/api';

import classes from './index.module.scss';

import { SectionTitleFeature } from '../../shared/features';
import { BMS_FLAVORS, NewInstanceFormContext } from '../../shared/model';
import { InterfaceFlavor } from '../../shared/model/data-types';

/**
 * @todo add switch component, refactoring
 * @description
 * @example <FlavorsFeature />
 * @returns React Element
 */
const Flavors = () => {
  //variables
  const {
    request: {
      error,
      isPending,
      response,
    },
  } = useMakeGetRequestFlavorsApi();

  if (error) return (
    <Wrapper>
      <NotificationFeature
        status='error'
        msg={error}
      />
    </Wrapper>
  );

  if (isPending) return (
    <Wrapper>
      <SpinnerFeature />
    </Wrapper>
  );

  return (
    <Wrapper>
      <ReturnContent
        response={response}
      />
    </Wrapper>
  );
};

const Wrapper = ({children}: {children: ReactNode}) => (
  <div className='col-span-6'>
    <SectionTitleFeature title={GLOSSARY.flavor} />
    <div className='mt-6'>
      {children}
    </div>
  </div>
);

const ReturnContent = ({response}: {response: InterfaceFlavorAPI[]}) => {
  //state
  const [flavorsA6000, setFlavorsA6000] = useState<InterfaceFlavor[]>(() => getA6000Flavors(response));
  //variables
  const { useDispatch, setInstance, useValue, setInstanceValidation } = NewInstanceFormContext;
  const dispatch = useDispatch();
  const { form: { type }} = useValue();
  const flavors = type === 'vm' ? flavorsA6000  : BMS_FLAVORS;
  const tabName = type === 'vm' ? 'NVIDIA RTX A6000' : 'NVIDIA HGX H100';

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const findSelectedFlavor = flavors?.find(f => f.selected) || {};
      dispatch(setInstance(findSelectedFlavor, 'flavor'));
      dispatch(setInstanceValidation(false, 'isFlavor'));
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, flavors, setInstance, setInstanceValidation]);

  //func
  const handleSelectedFlavor = (flavor: InterfaceFlavor) => {
    dispatch(setInstance(flavor, 'flavor'));
    dispatch(setInstanceValidation(false, 'isFlavor'));
    setFlavorsA6000(
      flavorsA6000.map((f) => {
        if (f.uuid === flavor.uuid) {
          return {
            ...f,
            selected: true,
          };
        } else {
          return {
            ...f,
            selected: false,
          };
        }
      }),
    );
  };

  return (
    <>
      <Tabs name={tabName} />
      <div className='column'>
        <div className='row justify-end pt-[10px] pb-[25px]'>
          {/* Switch */}
        </div>
        <FlavorsTable
          flavors={flavors}
          onSelectFlavorsA6000={type === 'vm' ? handleSelectedFlavor : () => {}}
        />
      </div>
    </>
  );
};

/**
 * @todo refactoring
 * @description
 * @example <FlavorsFeature />
 * @returns React Element
 */
const Tabs = ({name}: {name: string}) => {
  //variables
  const tabs = [name];

  //func
  const handleSelectedFlavorTab = (element: string) => {};

  return (
    <div className='flex row w-full border-b border-customBgLightGray'>
      {tabs?.map((element) => (
        <div
          key={element}
          onClick={() => handleSelectedFlavorTab(element)}
          className='cursor-pointer'
        >
          <TypographyFeature
            text={element}
            fontSize='sm'
            customStyle={`${classes.tabsTitleClass} all-items-center`}
          />
        </div>
      ))}
    </div>
  );
};

/**
 * @todo refactoring
 * @returns
 */
const FlavorsTable = ({ flavors, onSelectFlavorsA6000 }: InterfacePropsFlavorsTable) => {
  //variables
  const headData = ['', 'Storage size', 'Core', 'RAM'];
  const theadClass = 'text-s font-medium text-customTextBlack2withOpacity7 text-center';

  return (
    <table className={classes.tableClass}>
      <thead>
        <tr>
          {headData.map((item, index) =>(
            <th
              key={index}
              scope='col'
              className={`${theadClass}`}
            >
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {flavors?.map((item: InterfaceFlavor) => (
          <tr
            key={item.uuid}
            onClick={() => onSelectFlavorsA6000(item)}
            className={`
              text-xs hover:opacity-70 hover:cursor-pointer
              ${item.selected ? 'font-medium bg-customBgLightGray2' : 'bg-white'}
            `}
            data-testid={item.uuid}
          >
            <td
              className={`
                row all-items-center text-s font-bold
                ${item.selected ? classes.selected : ''}
              `}
            >
              <input
                aria-label='gpu'
                type='checkbox'
                className='mr-4 hover:cursor-pointer'
                checked={item.selected}
                onChange={() => onSelectFlavorsA6000(item)}
              />
              {item.gpu_count} GPU
            </td>
            <td className={`text-s font-bold ${item.selected ? classes.selected : ''}`}>
              {item.storage_size} GB
            </td>
            <td className={`text-s font-bold ${item.selected ? classes.selected : ''}`}>
              {item.core_count} CPUs
            </td>
            <td className={`text-s font-bold ${item.selected ? classes.selected : ''}`}>
              {item.ram} GB
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/**
 *
 * @param data
 * @returns
 */
const getA6000Flavors = (data: InterfaceFlavorAPI[]) => {
  //variables
  const A6000Flavors: InterfaceFlavor[] = data
    ?.filter((item: InterfaceFlavorAPI) => item.gpu_type === 'A6000')
    ?.map((item: InterfaceFlavorAPI) => ({
      ...item,
      cluster: {
        uuid: '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5',
        name: 'ndcloud-underground',
      },
      selected: false,
    }));
  (A6000Flavors && A6000Flavors[0]) && (A6000Flavors[0].selected = true);

  return A6000Flavors;
};

export { Flavors as FlavorsFeature };

interface InterfacePropsFlavorsTable {
  flavors: InterfaceFlavor[],
  onSelectFlavorsA6000: (flavor: InterfaceFlavor) => void,
};
