import React, { ReactNode } from 'react';

import {
  TDataTestIdsPortalModalForm,
} from 'data-test-ids/types/general-pages/portalModal';
import { returnIds } from 'data-test-ids/pages/GeneralPages';
import { TDataTestIds } from 'data-test-ids/types/general-pages';

import Header from './Header';
import ButtonsWrappper from './ButtonsWrapper';

type TProps = {
  handleSubmitButtonName: string,
  children: ReactNode,
  onCancel: () => void,
  handleSubmit?: (value?: any) => void,
  redBtn?: boolean,
  hideBtns?: boolean,
  header: ReactNode,
  dataTestIds?: TDataTestIdsPortalModalForm,
};

const defaultDataTestIds: TDataTestIds = returnIds('default');

/**
 * @deprecated
 */
export default function FormModalTemplate(props: TProps) {
  const {
    handleSubmitButtonName,
    children,
    onCancel,
    handleSubmit,
    redBtn = false,
    hideBtns = false,
    header,
    // testDataId,
    dataTestIds = defaultDataTestIds.modalForm.portalModal.form,
  } = props;

  return (
    <>
      <Header
        dataTestId={dataTestIds.header}
        header={header}
        onCancel={onCancel}
      />
      {children}
      {!hideBtns &&
        <ButtonsWrappper
          handleSubmitButtonName={handleSubmitButtonName}
          handleSubmit={handleSubmit}
          handleCancel={onCancel}
          redBtn={redBtn}
          dataTestIds={dataTestIds.btnWrapper}
        />
      }
    </>
  );
};
