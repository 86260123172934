import React, { ReactElement } from 'react';

import { TDataTestIdsOverviewPageContentMainInfo } from 'data-test-ids/types/overview-page';

import { PDPItemsFeature } from './items';

/**
  * @description a component that shows all information about PDP. (network, ipv4)
  * @param props
  * @example
  * <PDPInfoSectionFeature
  *   dataTestIds={{}}
  *   items={{}}
  * />
  * @returns React Element
*/
var PDPInfoSection = (props: InterfaceProps) => {
  //props
  const { dataTestIds, children, items } = props;

  return (
    <div
      data-testid={dataTestIds.name}
      className='mt-6 pl-6 flex gap-2 row items-baseline justify-between'
    >
      <PDPItemsFeature
        items={items}
        dataTestIds={dataTestIds.items}
      />
      {children}
    </div>
  );
};

export { PDPInfoSection as PDPInfoSectionFeature };

interface InterfaceProps {
  items: {title: string, value: string}[],
  children: ReactElement,
  dataTestIds: TDataTestIdsOverviewPageContentMainInfo,
};
