import React from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';

import { useDashboard } from 'context/dashboard';
import { useGetPageRoutesHook } from 'global-shared/hooks';
import { PortalModalFeature } from 'global-shared/features';

function SuccessfullyCreatedInstanceModal(){
  // variables
  const { getPageRoute } = useGetPageRoutesHook();
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { instanceName } = stateDashboard;

  return (
    <PortalModalFeature.ModalFeature
      modalType='info-box'
    >
      <div className='col py-[42px] all-items-center'>
        <div className='bg-primaryColorEasternBlue w-[50px] h-[50px] row all-items-center rounded-full'>
          <CheckIcon className='w-[16.5px] h-[15px]' fill='#fff' stroke='#fff' />
        </div>
        <p className='text-base font-medium mt-6 mb-[5px]'>
          Successfully created a new {instanceName}
        </p>
        <Link className='text-sm font-medium underline' to={getPageRoute('instances')}>
          Redirect to {instanceName}s
        </Link>
      </div>
    </PortalModalFeature.ModalFeature>
  );
};

export { SuccessfullyCreatedInstanceModal as SuccessfullyCreatedInstanceModalFeature };
