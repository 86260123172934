import { XMarkIcon } from '@heroicons/react/24/solid';

import { TEST_IDS } from 'global-shared/model';

import classes from './index.module.scss';

/**
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=7440-4546&node-type=frame&t=JO528ZYk6b22Yufq-0 }
 * @example <HeadingFeature />
 */
function Heading(props: InterfaceProps) {
  // props
  const {
    heading='Heading',
    onCancel,
  } = props;

  return (
    <div className={`${classes.portalModalBoxTitleClass} border-b-$customBgLightGray`}>
      <div
        data-testid='test_id_modal_heading'
        className='truncate w-3/4'
      >
        {heading}
      </div>
      <XMarkIcon
        data-testid={TEST_IDS.modal.close_btn}
        onClick={onCancel}
        className={classes.closeClass}
      />
    </div>
  );
};

export { Heading as HeadingFeature };

interface InterfaceProps {
  heading?: string,
  onCancel: () => void,
};
