import { UUID } from 'crypto';

import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import PageLayout from 'layouts/pages';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import { dataTestIdsNetwork } from 'data-test-ids';
import { API_URLS, GLOSSARY } from 'global-shared/model';
import { InterfaceIpv4 } from 'global-shared/model/dataTypes';
import { useGetPageRoutesHook } from 'global-shared/hooks';
import HeaderWithBreadcrumbs from 'components/HeaderWithBreadcrumbs';
import { PDPAttachedSectionFeature, PDPInfoSectionFeature, TypographyFeature } from 'global-shared/features';

import Header from './header';

/**
 * @description a component is a detailed page of a single IPv4,
 * sets the title in the browser tab, makes a request to the API
 */
var Ipv4Detail = () => {
  // variables
  const contextDashboard = useDashboard();
  const { ipv4Id, tenantId } = useParams<{ipv4Id: UUID, tenantId: UUID}>();
  const state = contextDashboard[0];
  const url = `${API_URLS.ipv4}/${ipv4Id}`;
  const { fetchFunc } = state;
  const { data } = fetchFunc.ip.get;
  const { getPageRoute, getProductDetailPageRoute } = useGetPageRoutesHook();
  const handleFetchRequest = useHandleFetchRequest();
  const location = useLocation();
  const items: {title: string, value: string}[] = [
    {
      title: GLOSSARY.uuid,
      value: data.uuid,
    },
    {
      title: GLOSSARY.cluster,
      value: data.cluster_uuid,
    },
  ];

  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | ${GLOSSARY.ipv4}`;
  }, [data]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (tenantId && ipv4Id) {
      const getIp = api.useFetchData<[], InterfaceIpv4>(url, 'get', signal);
      handleFetchRequest('ip', 'get', getIp);
    }
    return () => {
      controller.abort();
    };
  }, [url, ipv4Id, handleFetchRequest, tenantId]);

  return (
    <PageLayout
      page='ip'
      emptyTextPlaceholder={`${GLOSSARY.instances_plural} in your ${GLOSSARY.ipv4}`}
      renderSuccess={
        <>
          <HeaderWithBreadcrumbs
            route={getPageRoute('ipv4s')}
            routeName={GLOSSARY.ipv4s_plural}
            dataTestIds={dataTestIdsNetwork.content.header}
          />
          <div
            id='main-wrapper'
            data-testid={dataTestIdsNetwork.content.main.name}
          >
            <div
              data-testid={dataTestIdsNetwork.content.main.name}
              className={'lightGrayWrapperClass mt-4'}
            >
              <Header
                dataTestIds={dataTestIdsNetwork.content.main.header.networkPage}
              />
            </div>
            <PDPInfoSectionFeature
              dataTestIds={dataTestIdsNetwork.content.main.info}
              items={items}
            >
              <PDPAttachedSectionFeature
                isAttached={!data.vm_uuid ? false : true}
                dataTestIds={dataTestIdsNetwork.content.main.info.aside}
              >
                <>
                  {data?.vm_uuid &&
                    <Link
                      key={data?.vm_uuid}
                      data-testid={dataTestIdsNetwork}
                      to={{
                        pathname: getProductDetailPageRoute(ipv4Id, data.vm_uuid as UUID),
                        state: { pdpInstancePage: location },
                      }}
                    >
                      <TypographyFeature
                        text={data?.vm_uuid}
                        fontWeight='thin'
                        fontSize='s'
                        color='customColorDarkBlue80'
                        customStyle='pl-6 py-4 underline cursor-pointer'
                      />
                    </Link>
                  }
                </>
              </PDPAttachedSectionFeature>
            </PDPInfoSectionFeature>
          </div>
        </>
      }
    />
  );
};

export { Ipv4Detail as Ipv4DetailPage };
