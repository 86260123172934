import { GLOSSARY } from 'global-shared/model';

import {
  EmptyStateFeature,
  NavigateToCreateVmPageFeature,
} from '../shared';

/**
 * @since 1.2.0
 * @description Empty state for the instances
 * tabs on the overview page
 * @param tabsType
 * @version 0.0.2
 * @package pages/overview/features
 * @example
 *   <InstanceTabsEmptyStateFeature
 *     tabsType='all'
 *   />
 * @returns React Element | div
 */
function InstancesTabsEmptyState({tabsType}: {
  tabsType: 'all' | 'vms' | 'bms',
}){

  if(tabsType === 'all') {
    return <ShowTabAllEmptyState />;
  };

  return (
    <div className='col mt-12 h-full items-center justify-end'>
      <EmptyStateFeature
        text={GLOSSARY.empty_state.no_instances}
      />
      <NavigateToCreateVmPageFeature />
    </div>
  );
};

export { InstancesTabsEmptyState as InstancesTabsEmptyStateFeature };

function ShowTabAllEmptyState(){
  return (
    <div className='row mt-12 justify-evenly'>
      <div className='col items-center'>
        <EmptyStateFeature
          text={GLOSSARY.empty_state.no_instances}
        />
        <NavigateToCreateVmPageFeature />
      </div>
    </div>
  );
};
