import { GLOSSARY } from 'global-shared/model';

import { EmptyStateFeature, NavigateToCreateVmPageFeature } from '../shared';

/**
 * @since 1.2.1
 * @description Empty state for the overview page
 * @version 0.0.1
 * @package pages/overview/features
 * @example <OverviewPageEmptyStateFeature />
 * @returns React Element | div
 */
function OverviewPageEmptyState() {
  return (
    <div className='col py-6 rounded-md bg-[#FBFCFE] border border-customColorLightGray items-center justify-center'>
      <EmptyStateFeature
        text={GLOSSARY.empty_state.you_dont_have_any_resources_yet}
      />
      <NavigateToCreateVmPageFeature />
    </div>
  );
};

export { OverviewPageEmptyState as OverviewPageEmptyStateFeature };
