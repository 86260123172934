const IconGtcLogo = ({text = 'white'}: { text: 'white' | 'blue'}) => {
  //func
  const changeColor = () => {
    if (text === 'blue') return {
      bg: '#02061D00', // '#76b90000' - transparent
      text: '#02061D',
    };
    return {
      bg: '#02061D00', // '#76b90000' - transparent
      text: '#fff',
    };
  };

  return (
    <svg id='nvidia-gtc24-logo' xmlns='http://www.w3.org/2000/svg' width='104' height='104' viewBox='0 0 104 104'>
      <rect id='Rectangle_5175' data-name='Rectangle 5175' width='104' height='104' fill={changeColor().bg}></rect>
      <g id='Group_12024' data-name='Group 12024' transform='translate(14.385 26.452)'>
        <path id='Eye_Mark' data-name='Eye Mark' d='M285.5,514.469v-1.287c.125-.009.251-.016.38-.02,3.521-.111,5.831,3.025,5.831,3.025s-2.495,3.465-5.17,3.465a3.244,3.244,0,0,1-1.041-.167v-3.9c1.371.166,1.646.771,2.471,2.145l1.833-1.545a4.853,4.853,0,0,0-3.593-1.755,6.651,6.651,0,0,0-.71.042m0-4.253v1.923c.126-.01.253-.018.38-.023,4.9-.165,8.087,4.016,8.087,4.016s-3.664,4.456-7.482,4.456a5.634,5.634,0,0,1-.985-.087v1.189a6.474,6.474,0,0,0,.82.053c3.552,0,6.121-1.814,8.609-3.961.412.33,2.1,1.134,2.448,1.486-2.365,1.98-7.878,3.576-11,3.576-.3,0-.591-.018-.875-.045v1.671H299V510.216H285.5Zm0,9.27V520.5c-3.286-.586-4.2-4-4.2-4a7.1,7.1,0,0,1,4.2-2.031v1.114h-.005a3.153,3.153,0,0,0-2.449,1.119,4.376,4.376,0,0,0,2.454,2.785m-5.836-3.134a8.137,8.137,0,0,1,5.836-3.17v-1.042c-4.307.346-8.036,3.993-8.036,3.993s2.112,6.107,8.036,6.666V521.69c-4.347-.547-5.836-5.338-5.836-5.338Z' transform='translate(-277.463 -510.216)' fill={changeColor().text}></path>
        <path id='Eye_Mark-2' data-name='Eye Mark' d='M285.5,514.469v-1.287c.125-.009.251-.016.38-.02,3.521-.111,5.831,3.025,5.831,3.025s-2.495,3.465-5.17,3.465a3.244,3.244,0,0,1-1.041-.167v-3.9c1.371.166,1.646.771,2.471,2.145l1.833-1.545a4.853,4.853,0,0,0-3.593-1.755,6.651,6.651,0,0,0-.71.042m0-4.253v1.923c.126-.01.253-.018.38-.023,4.9-.165,8.087,4.016,8.087,4.016s-3.664,4.456-7.482,4.456a5.634,5.634,0,0,1-.985-.087v1.189a6.474,6.474,0,0,0,.82.053c3.552,0,6.121-1.814,8.609-3.961.412.33,2.1,1.134,2.448,1.486-2.365,1.98-7.878,3.576-11,3.576-.3,0-.591-.018-.875-.045v1.671H299V510.216H285.5Zm0,9.27V520.5c-3.286-.586-4.2-4-4.2-4a7.1,7.1,0,0,1,4.2-2.031v1.114h-.005a3.153,3.153,0,0,0-2.449,1.119,4.376,4.376,0,0,0,2.454,2.785m-5.836-3.134a8.137,8.137,0,0,1,5.836-3.17v-1.042c-4.307.346-8.036,3.993-8.036,3.993s2.112,6.107,8.036,6.666V521.69c-4.347-.547-5.836-5.338-5.836-5.338Z' transform='translate(-277.463 -510.216)' fill={changeColor().text}></path>
        <path id='NVIDIA' d='M781.9,561.93v9.355h2.642V561.93Zm-20.784-.013v9.368h2.666v-7.113h2.065a1.942,1.942,0,0,1,1.5.522,3.509,3.509,0,0,1,.589,2.477v4.113h2.582v-5.175c0-3.694-2.355-4.192-4.658-4.192h-4.744m25.038.013v9.355h4.285c2.283,0,3.028-.38,3.834-1.231a5.239,5.239,0,0,0,.938-3.344,5.551,5.551,0,0,0-.855-3.218c-.979-1.306-2.389-1.562-4.494-1.562Zm2.621,2.037h1.136c1.648,0,2.714.74,2.714,2.66s-1.066,2.661-2.714,2.661h-1.136ZM778.09,561.93l-2.2,7.414-2.113-7.414H770.92l3.018,9.355h3.808l3.041-9.355Zm18.35,9.355h2.642V561.93h-2.643Zm7.406-9.352-3.689,9.348h2.605l.584-1.652h4.366l.553,1.652h2.829l-3.718-9.349Zm1.715,1.705,1.6,4.38H803.91Z' transform='translate(-736.04 -559.236)' fill={changeColor().text}></path>
      </g>
      <g id='Group_12025' data-name='Group 12025' transform='translate(15.084 47.307)'>
        <path id='Path_5705' data-name='Path 5705' d='M304.351,942.447q-6.606,0-10.012-3.572t-3.407-10.468v-1.734a17.3,17.3,0,0,1,1.548-7.639,11.15,11.15,0,0,1,4.44-4.873,13.322,13.322,0,0,1,6.854-1.693h.455a12.8,12.8,0,0,1,6.854,1.735,9.536,9.536,0,0,1,4.006,5.037l-4.336,1.528q-1.362-4.17-6.565-4.17h-.371a7.319,7.319,0,0,0-6.008,2.6q-2.128,2.6-2.126,7.391v1.941q0,9.786,8.629,9.786h.372a16.557,16.557,0,0,0,3.489-.351A10.323,10.323,0,0,0,311,936.955v-6.647h-6.731l.537-3.964h10.735v12.883a14.283,14.283,0,0,1-4.707,2.374,20.691,20.691,0,0,1-6.07.846h-.414Z' transform='translate(-290.932 -912.469)' fill={changeColor().text}></path>
        <path id='Path_5706' data-name='Path 5706' d='M818.343,951.73V926.914h-8.961v-4.088h22.67v4.088h-8.961V951.73Z' transform='translate(-782.503 -922.29)' fill={changeColor().text}></path>
        <path id='Path_5707' data-name='Path 5707' d='M1282.515,942.448q-6.235,0-9.518-3.716t-3.283-10.446V926.55a17.06,17.06,0,0,1,1.528-7.494,11.21,11.21,0,0,1,4.4-4.873,13.179,13.179,0,0,1,6.875-1.713h.414a12.28,12.28,0,0,1,6.978,1.879,10.029,10.029,0,0,1,3.964,5.306l-4.376,1.528a6.084,6.084,0,0,0-2.415-3.448,7.349,7.349,0,0,0-4.15-1.135h-.372a7.4,7.4,0,0,0-5.947,2.519q-2.148,2.519-2.147,7.349v1.941q0,4.955,2.085,7.433a7.415,7.415,0,0,0,6.009,2.478h.372a7.922,7.922,0,0,0,4.294-1.115,5.868,5.868,0,0,0,2.477-3.344l4.294,1.651a9.855,9.855,0,0,1-4.067,5.12,12.825,12.825,0,0,1-7,1.817h-.414Z' transform='translate(-1218.969 -912.47)' fill={changeColor().text}></path>
      </g>
    </svg>
  );
};

export { IconGtcLogo };
