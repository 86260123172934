import { ButtonFeature, TypographyFeature } from 'global-shared/features';
import { DATA_TEST_IDS_OVERVIEW_PAGE, GLOSSARY } from 'global-shared/model';

import classes from './index.module.scss';

/**
 * @since 1.2.1
 * @description The docs section on the overview page,
 * simply shows text and a button to go to our documentation
 * @version 0.0.1
 * @package pages/overview/features
 * @example <Docs />
 * @returns React Element | div
 */
function Docs() {
  // style
  const { docsSectionClass } = classes;

  return (
    <div className={docsSectionClass}>
      <TypographyFeature
        text={GLOSSARY.descriptions.docs}
        color='white'
        fontSize='xs'
      />
      <ButtonFeature
        title=''
        uiStyle='primary'
        size='small'
        onClick={() => {}}
        dataTestId={DATA_TEST_IDS_OVERVIEW_PAGE.HERE_BTN}
      >
        <a
          rel='noopener noreferrer'
          target='_blank'
          href='https://documenation.scrollhelp.site/docs'
        >
          Here
        </a>
      </ButtonFeature>
    </div>
  );
};

export { Docs as DocsFeature };
