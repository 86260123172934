import React, { useEffect } from 'react';

import { dataTestIdsNetworks } from 'data-test-ids';
import { GLOSSARY } from 'global-shared/model';
import CreateNewNetworkPage from 'components/forms/create-network/CreateNewNetworkPage';

function NewNetwork() {
  //hooks
  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | New ${GLOSSARY.network}`;
  }, []);

  return (
    <CreateNewNetworkPage
      dataTestIds={dataTestIdsNetworks.modalForm}
    />
  );
};

export { NewNetwork as NewNetworkPage };
