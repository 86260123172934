import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';
import { GLOSSARY } from 'global-shared/model';

import SectionWithTags from './SectionWithTags';
import Tag from './Tag';

import useRemoveIp from '../ips/useRemoveIp';

export default function Ipv4s() {
  const { useValue } = NewInstanceFormContext;
  const { instance: { ips } } = useValue();
  const handleRemoveIp = useRemoveIp();

  const showIps = () => {
    return (
      ips?.map(item => (
        <Tag
          key={item.address4}
          name={item.address4}
          handleRemoveTag={handleRemoveIp}
          value={item.address4}
        />
      ))
    );
  };

  return (
    <SectionWithTags
      title={ips?.length > 1 ? GLOSSARY.ipv4s_plural : GLOSSARY.ipv4}
      testDataId='ipv4_address'
    >
      <>{
        ips.length === 0 ?
          `Choose ${GLOSSARY.ipv4} (optional)` :
          showIps()
      }</>
    </SectionWithTags>
  );
};
