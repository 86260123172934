import React from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonFeature, PortalModalFeature, TypographyFeature } from 'global-shared/features';
import { IconGtcLogo } from 'global-shared/assets';

function Content() {
  //variables
  const history = useHistory();

  //func
  const handleCloseModalForm = () => {
    history.goBack();
  };

  return (
    <>
      <PortalModalFeature.HeadingFeature
        heading='Information Dialog'
        onCancel={handleCloseModalForm}
      />
      <div
        className='col items-start justify-start p-6 min-h-[18.125rem] relative gap-6'
        data-testid='test-id-demo-page'
      >
        <TypographyFeature
          text='Unfortunately, this feature is not available in the demo version.'
          fontSize='s'
          fontWeight='thin'
          customStyle='w-11/12'
        />
        <TypographyFeature
          text='Get your own account and create your first instance with Taiga Cloud'
          customStyle='w-11/12'
          fontSize='sm'
        />
        <ButtonFeature
          uiStyle='primary'
        >
          <a
            rel='noreferrer noopener'
            target='_blank'
            href='https://taigacloud.com'
            data-testid='test-id-demo-link-main-web-site'
          >
            Let's do it
          </a>
        </ButtonFeature>
        <div className='absolute bottom-0 right-0 mr-6 my-4'>
          <IconGtcLogo text='blue' />
        </div>
      </div>
    </>
  );
};

export { Content as ContentFeature };
