import { useEffect, useState } from 'react';

import { api } from 'helpers/api';
import { useDashboard } from 'context/dashboard';
import { setFetchFunc, setMetrics } from 'actions/dashboard';
import { METRICS } from 'reducers/dashboard';
import { TFetchFuncPage } from 'types/index';
import { API_URLS } from 'global-shared/model';
import { useTenants } from 'global-shared/features';

import { TypePromise } from '../shared/dataTypes';

/**
 * @since 1.2.0
 * @description The hook that makes four API requests
 * (instances, networks, ipv4s, ssh keys) at a time and returns one of the response states
 * @version 0.0.2
 * @package pages/overview/hooks
 * @example
 * const { loading, error, status } = useGetMetricsHook();
 * @returns object
 */
function useGetMetrics() {
  // state
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number | unknown>(0);

  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const context = useDashboard();
  const dispatch = context[1];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const showLoading = (isLoading: boolean) => {
      setLoading(isLoading);
      dispatch(setFetchFunc(isLoading, 'instances', 'get', 'loading'));
      dispatch(setFetchFunc(isLoading, 'networks', 'get', 'loading'));
      dispatch(setFetchFunc(isLoading, 'ips', 'get', 'loading'));
      dispatch(setFetchFunc(isLoading, 'sshKeys', 'get', 'loading'));
    };

    const cleanState = () => {
      setError('');
      dispatch(setFetchFunc('', 'instances', 'get', 'error'));
      dispatch(setFetchFunc('', 'networks', 'get', 'error'));
      dispatch(setFetchFunc('', 'ips', 'get', 'error'));
      dispatch(setFetchFunc('', 'sshKeys', 'get', 'error'));

      setStatus('');
      dispatch(setFetchFunc(0, 'instances', 'get', 'status'));
      dispatch(setFetchFunc(0, 'networks', 'get', 'status'));
      dispatch(setFetchFunc(0, 'ips', 'get', 'status'));
      dispatch(setFetchFunc(0, 'sshKeys', 'get', 'status'));
    };

    if (selectedTenantId) {
      cleanState();
      async function loadData() {
        // loading
        showLoading(true);

        const promiseGetInstances = api.useFetchData<[], TypePromise>(`${API_URLS.instances}/${selectedTenantId}?brief=true`, 'get', signal);
        const promiseGetNetworks = api.useFetchData<[], TypePromise>(`${API_URLS.networks}/${selectedTenantId}?brief=true`, 'get', signal);
        const promiseGetIpv4s = api.useFetchData<[], TypePromise>(`${API_URLS.ipv4s}/${selectedTenantId}?brief=true`, 'get', signal);
        const promiseGetSshkeys = api.useFetchData<[], TypePromise>(`${API_URLS.sshKeys}/${selectedTenantId}`, 'get', signal);

        try {
          const request  = await Promise.allSettled([
            promiseGetInstances, promiseGetNetworks, promiseGetIpv4s, promiseGetSshkeys,
          ]);
          const [instances, networks, ipv4s, sshKeys] = request as unknown as TypePromise[];

          const checkIsFulfilled = (item: PromiseSettledResult<TypePromise>, page: TFetchFuncPage) => {
            if (item.status === 'fulfilled') {
              dispatch(setFetchFunc(item?.value, page, 'get', 'data'));
              dispatch(setFetchFunc(200, page, 'get', 'status'));
            } else {
              dispatch(setFetchFunc(item?.reason.message, page, 'get', 'error'));
              dispatch(setFetchFunc(item?.reason.cause, page, 'get', 'status'));
              dispatch(setFetchFunc([], page, 'get', 'data'));
            };
          };

          request.map((item, index) => {
            if (index === 0){
              checkIsFulfilled(item, 'instances');
            } else if (index === 1){
              checkIsFulfilled(item, 'networks');
            } else if (index === 2){
              checkIsFulfilled(item, 'ips');
            } else {
              checkIsFulfilled(item, 'sshKeys');
            }

            return null;
          });

          const newMetrics = {
            tenant_uuid: '',
            instances: instances?.value?.length,
            ipv4s: ipv4s?.value?.length,
            networks: networks?.value?.length,
            created: '',
            last_updated: '',
            ssh_keys: sshKeys?.value?.length,
          };

          dispatch(setMetrics(newMetrics));
        }
        finally {
          showLoading(false);
        }
      }

      loadData();
    } else {
      setError('Not authorized');
      setStatus(403);
      throw new Error('Not authorized', { cause: 403 });
    };

    return () => {
      controller.abort();
      dispatch(setMetrics(METRICS));
    };
  }, [dispatch, selectedTenantId]);

  return { loading, status, error };
};

export { useGetMetrics as useGetMetricsHook };
