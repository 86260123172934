import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import ubuntuImage from 'assets/images/ubuntu-logo.png';
import debianImage from 'assets/images/debian.png';
import rockyImage from 'assets/images/rocky.png';
import Keys from 'feature/new-instance/summary/Keys';
import Ipv4s from 'feature/new-instance/summary/Ipv4s';
import { GLOSSARY } from 'global-shared/model';

import classes from './index.module.scss';

import { INSTANCE_LOCATIONS, NewInstanceFormContext } from '../../../../shared/model';

/**
 * @todo refactoring
 * @description A component that has all the elements that are presented in the summary
 * @returns React Element
 */
function Items() {
  //variables
  const { useValue } = NewInstanceFormContext;
  const {
    form: {
      location,
      error,
    },
    instance: {
      name,
      network,
      image,
      flavor,
    },
    validation,
  } = useValue();

  const renderWrapperFlavorValue = () => {
    return (
      <span className='row items-center'>
        NVIDIA {flavor.name}
      </span>
    );
  };

  const data = [
    {
      title: 'Name',
      value: name,
      validationText: error ?  error : 'Add Name',
      isValid: validation.isName,
      testDataId: 'vm_name',
    },
    {
      title: 'Location',
      value: location,
      validationText: '',
      isValid: validation.isCluster,
      testDataId: 'location',
    },
    {
      title: GLOSSARY.flavor,
      value: renderWrapperFlavorValue(),
      validationText: '',
      isValid: validation.isFlavor,
      testDataId: 'flavor',
    },
    {
      title: GLOSSARY.image,
      value: !image?.name ? '' : image?.name,
      validationText: '',
      isValid: validation.isImage,
      testDataId: 'image',
    },
    {
      title: GLOSSARY.network,
      value: network?.network_name,
      validationText: 'Add Network',
      isValid: validation.isNetwork,
      testDataId: 'network',
    },
  ];

  const showInstanceTypeInData = data;

  const returnIconImage = (imageFamily: string) => {
    const name = imageFamily?.toLowerCase();
    if (name?.includes('debian')) return debianImage;
    if (name?.includes('rocky')) return rockyImage;
    return ubuntuImage;
  };

  const selectedLocation = INSTANCE_LOCATIONS.filter((item) => item.title === location)[0];

  return (
    <dl className='divide-y divide-gray-200 border-b border-gray-200 m-0'>
      {showInstanceTypeInData.map((item) => (
        <div key={item.title} className='col pl-6 py-[10px] font-medium'>
          <dt className={classes.titleClass}>{item.title}</dt>
          <dd className='row justify-start items-center text-sm' data-testid={item.testDataId}>
            {(item.title === 'Location' && selectedLocation) &&
              <selectedLocation.icon />
            }
            {item.title === GLOSSARY.image &&
              <img
                src={returnIconImage(image?.name)}
                alt='norway'
                className='w-4 mr-2'
              />
            }
            {(((item.title === GLOSSARY.network && !network.uuid) || (item.title === 'Name' && !name && !name.trim()))  && !item.isValid) ?
              <p className='text-customTextBlack2withOpacity1'>Add {item.title}</p> :
              item.value ? <p className={`truncate ${!name || name === ' ' ? '' : 'w-72'}`}>{item.value}</p> : null
            }
            {item.isValid &&
              <div className='row'>
                <ExclamationCircleIcon className='fill-customDarkRed w-[18px] mr-[10px]' />
                <p className='text-customDarkRed '>{item.validationText}</p>
              </div>
            }
          </dd>
        </div>
      ))}
      <Keys />
      <Ipv4s />
    </dl>
  );
};

export { Items as ItemsFeature };
