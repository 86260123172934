import React, { useEffect } from 'react';

import IPv4sList from 'feature/ipv4s/IPv4sList';
import { useDashboard } from 'context/dashboard';
import PageLayout from 'layouts/pages';
import { useCleanState } from 'hooks/useCleanState';
import BulkOperationsSection from 'feature/bulk-operations/delete/BulkOperationsSection';
import useSelectedOrDeselectedAllRemovingIps from 'feature/ipv4s/useSelectedOrDeselectedAllRemovingIps';
import PortalNotification from 'portals/Notification';
import { useMakeGetRequestIpv4sImmediatelyHook, useGoToModalFormPageHook } from 'global-shared/hooks';
import { PageHeaderFeature, PaginationFeature } from 'global-shared/features';
import { GLOSSARY } from 'global-shared/model';
import { setNewIpv4 } from 'actions/dashboard';

/**
 * @since 0.0.1
 * @description A component that instantly receives data about all ipv4s and
 * shows a whole page with a header, bulk operations
 * @version 0.0.4
 * @example <IPv4s />
 * @returns React Fragment
*/
export function IPv4s() {
  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatchDashboard = context[1];
  const { fetchFunc } = state;
  const cleanState = useCleanState();
  const page = 'ips';
  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingIps(false);
  const { handleOpenCreateForm } = useGoToModalFormPageHook();

  // hooks
  useMakeGetRequestIpv4sImmediatelyHook();

  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | ${GLOSSARY.ipv4s_plural}`;
  }, []);

  useEffect(() => {
    return () => {
      cleanState('ip', 'post');
    };
  }, [cleanState]);

  const handleOpenModalForm = () => {
    handleOpenCreateForm('ipv4s');
    cleanState('ip', 'post');
    dispatchDashboard(
      setNewIpv4({
        tenant_uuid: '',
        cluster_uuid: '',
      }),
    );
  };

  return (
    <>
      <BulkOperationsSection
        page={page}
        handleDeselectedAllItems={handleDeselectedAllItems}
        itemsName='Ipv4'
      />
      <PageLayout
        page={page}
        emptyTextPlaceholder={GLOSSARY.ipv4s_plural}
        renderSuccess={
          <>
            <PageHeaderFeature
              headerTitle={GLOSSARY.ipv4s_plural}
              btnTitle={`${GLOSSARY.actions.create} ${GLOSSARY.ipv4}`}
              onClick={handleOpenModalForm}
            />
            <IPv4sList />
          </>
        }
      />
      <PaginationFeature page={page} />
      {fetchFunc.ip.post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};
