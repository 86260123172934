/**
 * @todo refactofing
 */
import { useCallback, useEffect } from 'react';

import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { setSelectedInstanceType } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
import { InterfaceInstance } from 'global-shared/model/dataTypes';
import { useTenants } from 'global-shared/features';
import { API_URLS } from 'global-shared/model';

/**
 * @description useMakeGetRequestVmsByClick - a hook that returns a function
 * that makes an API /GET request to get all instances of type virtualmachine
 * @returns handleGetVms
 */
function useMakeGetRequestVmsByClick() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const page = 'virtualmachine';
  const url = `${API_URLS.instances}/${selectedTenantId}?brief=true&mtype=virtualmachine`;
  const fetchMethod = 'get';
  const handleFetchRequest = useHandleFetchRequest();
  const context = useDashboard();
  const dispatch = context[1];

  const handleGetVms = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (selectedTenantId) {
      const getVms = api.useFetchData<[], Array<InterfaceInstance>>(url, fetchMethod, signal);
      handleFetchRequest(page, fetchMethod, getVms);
      dispatch(setSelectedInstanceType('virtualmachine'));
    }
    return () => {
      controller.abort();
    };
  }, [handleFetchRequest, selectedTenantId, url, dispatch]);

  return { handleGetVms };
};

/**
 * @description useMakeGetRequestVmsImmediately - a hook that makes an API /GET request
 * to immediately get all instances of type virtualmachine using useEffect
 * @returns null
 */
function useMakeGetRequestVmsImmediately() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { handleGetVms } = useMakeGetRequestVmsByClick();

  useEffect(() => {
    if (selectedTenantId) {
      handleGetVms();
    }
    return () => {};
  }, [selectedTenantId, handleGetVms]);
};

/**
 * @description useMakeGetRequestBareMetalByClick - a hook that returns a function
 * that makes an API /GET request to get all instances of type berametal
 * @returns handleGetBareMatel
 */
function useMakeGetRequestBareMetalByClick() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const page = 'baremetal';
  const url = `${API_URLS.instances}/${selectedTenantId}?brief=true&mtype=baremetal`;
  const fetchMethod = 'get';
  const handleFetchRequest = useHandleFetchRequest();
  const context = useDashboard();
  const dispatch = context[1];

  const handleGetBareMatel = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (selectedTenantId) {
      const getBeraMetal = api.useFetchData<[], Array<InterfaceInstance>>(url, fetchMethod, signal);
      handleFetchRequest(page, fetchMethod, getBeraMetal);
      dispatch(setSelectedInstanceType('baremetal'));
    }
    return () => {
      controller.abort();
    };
  }, [handleFetchRequest, selectedTenantId, url, dispatch]);

  return { handleGetBareMatel };
};

/**
 * @description useMakeGetRequestBareMetalImmediately - a hook that makes an API /GET request
 * to immediately get all instances of type berametal using useEffect
 * @returns null
 */
function useMakeGetRequestBareMetalImmediately() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { handleGetBareMatel } = useMakeGetRequestBareMetalByClick();

  useEffect(() => {
    if (selectedTenantId) {
      handleGetBareMatel();
    }
    return () => {};
  }, [selectedTenantId, handleGetBareMatel]);
};

/**
 * @description useMakeGetRequestInstancesByClick - a hook that returns a function
 * that makes an API /GET request to get all instances
 * @returns handleGetInstances
 */
function useMakeGetRequestInstancesByClick(page: 'instances' | 'baremetal' | 'virtualmachine') {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const typeParam = page === 'instances' ? '' :`mtype=${page}`;
  const url = `${API_URLS.instances}/${selectedTenantId}?brief=true&${typeParam}`;
  const fetchMethod = 'get';
  const handleFetchRequest = useHandleFetchRequest();
  const context = useDashboard();
  const dispatch = context[1];

  // func
  const handleGetInstances = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      const getInstances = api.useFetchData<[], InterfaceInstance[]>(url, fetchMethod, signal);
      handleFetchRequest(page, fetchMethod, getInstances);
      if (page !== 'instances') {
        dispatch(setSelectedInstanceType(page));
      }
    }
    return () => {
      controller.abort();
    };
  }, [handleFetchRequest, selectedTenantId, url, page, dispatch]);

  return { handleGetInstances };
};

/**
 * @description useMakeGetRequestInstancesImmediately - a hook that makes an API /GET request
 * to immediately get all instances using useEffect
 * @returns null
 */
function useMakeGetRequestInstancesImmediately(page: 'instances' | 'baremetal' | 'virtualmachine') {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { handleGetInstances } = useMakeGetRequestInstancesByClick(page);

  // hooks
  useEffect(() => {
    if (selectedTenantId) {
      handleGetInstances();
    }
    return () => {};
  }, [selectedTenantId, handleGetInstances, page]);
};

export {
  useMakeGetRequestInstancesByClick as useMakeGetRequestInstancesByClickHook,
  useMakeGetRequestInstancesImmediately as useMakeGetRequestInstancesImmediatelyHook,
  useMakeGetRequestBareMetalByClick as useMakeGetRequestBareMetalByClickHook,
  useMakeGetRequestBareMetalImmediately as useMakeGetRequestBareMetalImmediatelyHook,
  useMakeGetRequestVmsByClick as useMakeGetRequestVmsByClickHook,
  useMakeGetRequestVmsImmediately as useMakeGetRequestVmsImmediatelyHook,
};
