import React, { ChangeEvent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import CustomInput from 'components/input/Input';
import { IconBigFullColorLogo, IconBigFullLogo } from 'global-shared/assets';
import { ButtonFeature, MessageFeature, SpinnerFeature, TypographyFeature } from 'global-shared/features';
import { DATA_TEST_ID_LOGIN_PAGE, GLOSSARY } from 'global-shared/model';
// import { useDashboard } from 'context/dashboard';
// import { setIsApiMaintenance } from 'actions/dashboard';
import { IconGtcLogo } from 'global-shared/assets/GtcLogo';

import classes from './login.module.scss';

export function SignIn() {
  // state
  const [error, setError] = useState<string>('');
  const [form, setFrom] = useState<{email: string, password: string}>({
    email: '',
    password: '',
  });
  const [sessionToken, setSessionToken] = useState<string>('');

  // variables
  // const contextDashboard = useDashboard();
  // const dispatchDashboard = contextDashboard[1];
  // const stateDashboard = contextDashboard[0];
  // const { isApiMaintenance } = stateDashboard;

  const { oktaAuth, authState } = useOktaAuth();
  const { email, password } = form;
  // const url = API_URLS.statusMaintenance;
  const data = [
    {
      label: 'Username',
      id: 'email',
      name: 'email',
      type: 'text',
      autoComplete: 'email',
      value: email,
      placeholder: 'Enter username',
    },
    {
      label: 'Password',
      id: 'password',
      name: 'password',
      type: 'password',
      autoComplete: 'current-password',
      value: password,
      placeholder: 'Enter password',
    },
  ];

  // styles
  const {
    loginPageWrapper,
    loginPageLeftSide,
    loginPageLeftSideLogoWrapper,
    loginPageFormClass,
    loginPageRigthSide,
    loginPageRightSideLogoWrapperWrapper,
    loginPageRightSideLogoWrapper,
    loginPageRightSideVideoBg,
  } = classes;

  // func
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();

    return () => {};
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFrom({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
      username: email,
      password: password,
    };

    oktaAuth
      .signInWithCredentials(data)
      .then((res) => {
        const sessionToken = res.sessionToken;
        setSessionToken(sessionToken || '');
        oktaAuth.signInWithRedirect({
          originalUri: '/tenants',
          sessionToken: sessionToken,
        });
      })
      .catch(() => setError('Authentication process failed'));
  };

  // const url = `${process.env.REACT_APP_STATUS_PAGE_API_ENV}/maintenance_api`;

  // useEffect(() => {
  //   const controller = new AbortController();
  //   const signal = controller.signal;

  //   (async () => {
  //     const data: { status: 'progress' | 'default' } = await fetch(url, {signal}).then(res => res.json());
  //     dispatchDashboard(setIsApiMaintenance(data.status));
  //   })();
  // }, [dispatchDashboard, url]);

  if (!authState) {
    return <SpinnerFeature />;
  };

  if (authState?.isAuthenticated) {
    return <Redirect to={{ pathname: '/' }}/>;
  };

  // if(isApiMaintenance === 'progress') {
  //   return <Redirect to='/maintenance' />;
  // };

  if (sessionToken) {
    return null;
  };

  // const handleCheck = () => {
  //   (async () => {
  //     const data: { status: 'progress' | 'default' } = await fetch(url).then(res => res.json());
  //     dispatchDashboard(setIsApiMaintenance(data.status));
  //   })();
  // };

  return (
    <div
      // onClick={handleCheck}
      className={loginPageWrapper}
    >
      <div className={loginPageLeftSide}>
        <div className={classes.loginPageFormWrapper}>
          <div className={loginPageLeftSideLogoWrapper}>
            <IconBigFullColorLogo />
          </div>
          <form
            onSubmit={handleSubmit}
            className={loginPageFormClass}
          >
            <TypographyFeature
              text={GLOSSARY.welcome}
              fontSize='xxl'
              fontWeight='medium'
            />
            <TypographyFeature
              text={GLOSSARY.descriptions.please_enter_your_login_data}
              color='customColorDarkBlue60'
              fontSize='xxs'
              fontWeight='medium'
              customStyle={classes.loginPageFormClassText}
            />
            {data.map(item => (
              <div key={item.id} className='mb-[27px]'>
                <CustomInput
                  name={item.name}
                  type={item.type}
                  value={item.value}
                  handleChange={(event) => handleChange(event)}
                  placeholder={item.placeholder}
                  inputFeedback=''
                  inputStatus='error'
                  label={item.label}
                />
              </div>
            ))}
            {error &&
            <MessageFeature
              status='error'
              msg={GLOSSARY.notifications.errors.authentication_failed_wrong_email_or_password}
            />
            }
            <ButtonFeature
              type='submit'
              customStyle='mt-14 w-full'
              uiStyle='primary'
              title='Login'
              onClick={handleSubmit}
              dataTestId={DATA_TEST_ID_LOGIN_PAGE.LOGIN_BTN}
            />
          </form>
        </div>
      </div>
      <div className={loginPageRigthSide}>
        <div className={loginPageRightSideLogoWrapperWrapper}>
          <div className={loginPageRightSideLogoWrapper}>
            <IconBigFullLogo color='white'/>
          </div>
        </div>
        <div className={loginPageRightSideVideoBg}></div>
        <video
          loop={true}
          muted={true}
          playsInline={true}
          autoPlay={true}
          id='hero-video'
          className='w-full h-full object-cover'
          data-video-mobile-src='https://26683789.fs1.hubspotusercontent-eu1.net/hubfs/26683789/videos/Cloud_KV12_Contact_compressed_H.264.mp4'
          data-video-desktop-src='https://26683789.fs1.hubspotusercontent-eu1.net/hubfs/26683789/videos/Cloud_KV12_Contact.mp4'
          src='https://26683789.fs1.hubspotusercontent-eu1.net/hubfs/26683789/videos/Cloud_KV12_Contact.mp4'
        >
        </video>
        <div className='absolute bottom-0 right-0 mr-8 my-4'>
          <IconGtcLogo text='white' />
        </div>
      </div>
    </div>
  );
};
