import React from 'react';

import Button from 'components/buttons';
import {
  TDataTestIdsPortalModalFormBtnWrapper,
} from 'data-test-ids/types/general-pages/portalModal';

type TProps = {
  handleSubmitButtonName: string,
  handleCancel: () => void,
  handleSubmit?: (value?: any) => void,
  redBtn?: boolean,
  dataTestIds: TDataTestIdsPortalModalFormBtnWrapper,
};
/**
 *
 * @deprecated
 */
export default function ButtonsWrappper(props: TProps) {
  const {
    handleSubmitButtonName,
    handleCancel,
    handleSubmit,
    redBtn = false,
    dataTestIds,
  } = props;

  const styleButton = redBtn ? 'red' : 'primary';

  return (
    <div
      className='mt-10 pb-4 pr-6 items-center flex justify-end'
      data-testid={dataTestIds.name}
    >
      <Button
        type='button'
        customStyle='all-items-center mr-[5px]'
        title='Cancel'
        handleClick={handleCancel}
        uiStyle='gray'
        dataTestId={dataTestIds.btnCancel}
      />
      <Button
        type='button'
        uiStyle={`${styleButton}`}
        handleClick={handleSubmit}
        title={handleSubmitButtonName}
        dataTestId={dataTestIds.btnSubmit}
      />
    </div>
  );
};
