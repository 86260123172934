import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import 'styles/index.scss';

import Header from 'components/header-component';
import Sidebar from 'components/sidebar';
import { useTenants } from 'global-shared/features';

/**
 * @description The Template component
 * @param children
 */
export function Template({children}: {children: ReactNode}) {
  // variables
  const { authState } = useOktaAuth();
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;

  if (!selectedTenantId && !authState?.isAuthenticated && !authState?.accessToken) {
    return <Redirect to={'/login'} />;
  };

  if (!selectedTenantId) {
    return <Redirect to={'/tenants'} />;
  };

  return (
    <div className='row h-screen'>
      <Sidebar />
      <div className='col flex-1'>
        <Header />
        <div className='px-14 overflow-auto min-h-[90%]'>
          {children}
        </div>
      </div>
    </div>
  );
};
