import { useCallback, useEffect } from 'react';

import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import { useTenants } from 'global-shared/features';
import { API_URLS, IIpv4Api } from 'global-shared/model';

/**
 * @description useMakeGetRequestIpv4sByClick - a hook that returns a function
 * that makes an API /GET request to get all ipv4s
 * @returns handleGetIpv4s
 */
function useMakeGetRequestIpv4sByClick() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const url = `${API_URLS.ipv4s}/${selectedTenantId}?brief=true`;
  const handleFetchRequest = useHandleFetchRequest();
  const page = 'ips';
  const fetchMethod = 'get';

  // func
  const handleGetIpv4s = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      const getIpv4s = api.useFetchData<[], Array<IIpv4Api>>(url, fetchMethod, signal);
      handleFetchRequest(page, fetchMethod, getIpv4s);
    }
    return () => {
      controller.abort();
    };
  }, [handleFetchRequest, selectedTenantId, url]);

  return { handleGetIpv4s };
};

/**
 * @description useMakeGetRequestIpv4sImmediately - a hook that makes an API /GET request
 * to immediately get all ipv4s using useEffect
 * @returns null
 */
function useMakeGetRequestIpv4sImmediately() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { handleGetIpv4s } = useMakeGetRequestIpv4sByClick();

  // hooks
  useEffect(() => {
    if (selectedTenantId) {
      handleGetIpv4s();
    }
    return () => {};
  }, [selectedTenantId, handleGetIpv4s]);
};

export {
  useMakeGetRequestIpv4sByClick as useMakeGetRequestIpv4sByClickHook,
  useMakeGetRequestIpv4sImmediately as useMakeGetRequestIpv4sImmediatelyHook,
};
