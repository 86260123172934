import React from 'react';

import { ElementWithTooltipFeature, TypographyFeature } from 'global-shared/features';
import { TEST_IDS } from 'global-shared/model';

import classes from '../index.module.scss';
import { IconApi, IconDoc, IconStatus, IconSupport } from '../assets';

export default function LinksComponent() {
  return (
    <div className={classes.externalLinksClass}>
      {LINKS.map(link => (
        <div key={link.title}>
          <div
            className='max-2xl:block hidden'
          >
            <ElementWithTooltipFeature
              text={link.title}
              element={
                <CustomLink
                  link={link}
                />
              }
              position='right'
            />
          </div>
          <div
            className='2xl:block hidden'
          >
            <CustomLink
              link={link}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const LINKS: InterfaseLink[] = [
  {
    href: 'https://documenation.scrollhelp.site/docs',
    title: 'Documentation',
    icon: IconDoc,
    testId: TEST_IDS.sidebar.links.documentation,
  },
  {
    href: 'https://northerndata.atlassian.net/servicedesk/customer/portal/12',
    title: 'Support',
    icon: IconSupport,
    testId: TEST_IDS.sidebar.links.support,
  },
  {
    href: 'https://status-cloudservices.northerndata.de',
    title: 'Status',
    icon: IconStatus,
    testId: TEST_IDS.sidebar.links.status,
  },
  {
    href: 'https://api.nd-cloud.com/docs#',
    title: 'Swagger API',
    icon: IconApi,
    testId: TEST_IDS.sidebar.links.swagger_api,
  },
];

interface InterfaseLink {
  href: string,
  title: 'Documentation' | 'Support' | 'Status' | 'Swagger API',
  icon: any,
  testId: string,
};

const CustomLink = ({link}: {link: InterfaseLink}) => (
  <a
    rel='noreferrer'
    target='_blank'
    className={classes.navlinkClass}
    href={link.href}
  >
    {/* link */}
    <div className={classes.navIconLinkClass}>
      <link.icon />
    </div>
    <TypographyFeature
      text={link.title}
      color='white'
      customStyle={classes.navTitleLinkClass}
      fontSize='s'
      fontWeight='thin'
      dataTestId={link.testId}
    />
  </a>
);
