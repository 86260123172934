const IconSmallLogo = ({color = 'white'}: { color?: 'white' | 'black' | 'colorfull'}) => {
  //func
  const changeColor = () => {
    if (color === 'white') return 'fill-white';
    if (color === 'colorfull') return 'fill-white';
    return '';
  };

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      xmlSpace='preserve'
      viewBox='0 0 250 170'
    >
      <g>
        <path className={changeColor()} d='M406.1,166.2'/>
        <polygon className={changeColor()} points='65.5,105.7 114.2,105.7 114.2,113 114.2,113 114.2,144.5 106.8,138.5 106.8,113 74.6,113  '/>
        <path className={changeColor()} d='M155.7,54.2c-9.5-0.3-18.3,3.8-24,11c-1.2,1.5-0.8,3.6,0.8,4.6l0.8,0.5c1.3,0.8,3.1,0.5,4.1-0.7   c4.7-5.9,12.1-9,19.9-8.1c10.1,1.2,18.3,9.4,19.3,19.5c1.4,13.3-9,24.5-22,24.5h-24.1l-0.1,0.3v-0.3l-7.4,0v45.9l7.4,5.9v-33.8   l0-10.8h24.3c16.8,0,30.3-14.1,29.3-31.1C183.1,66.9,170.7,54.8,155.7,54.2z'/>
        <path className={changeColor()} d='M70.7,99.2c-0.8-3.3-1.3-6.6-1.3-10.1c0-22.3,17.9-40.4,39.9-40.4c7.5,0,14.7,2.1,20.9,6   c1.1,0.7,2.5,0.7,3.6,0.1l1.8-1c1.9-1.1,2-3.8,0.2-5.1c-7.8-5.3-17-8.1-26.5-8.1c-26.4,0-48,21.8-48,48.6c0,3.4,0.4,6.8,1,10.1   H70.7z'/>
      </g>
    </svg>
  );
};

export { IconSmallLogo };
