import React, { useEffect, useState } from 'react';

import { useDashboard } from 'context/dashboard';
import { setNotifi, setPreselectedRemovingItems } from 'actions/dashboard';
import { ButtonFeature, ModalBulkOperationsFeature, TypographyFeature } from 'global-shared/features';
import { DATA_TEST_IDS_BULK_OPERATIONS } from 'global-shared/model';
import { TModalActionsItemsName } from 'types/index';

import ModalWindowBulkOperations from './ModalWindowBulkOperations';

export default function BulkOperationsSection(props: InterfaceProps) {
  // state
  const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(false);

  // props
  const { page, handleDeselectedAllItems, itemsName } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { preselectedRemovingItems } = state;
  const howManyItemsSelected = preselectedRemovingItems[page].uuid.length;

  // func
  useEffect(() => {
    return () => {
      dispatch(setPreselectedRemovingItems(page, 'uuid', []));
      dispatch(setPreselectedRemovingItems(page, 'name', []));
    };
  }, [dispatch, page]);

  const handleRemoveItemsModal = () => {
    dispatch(setNotifi('errors', []));
    dispatch(setNotifi('success', []));
    setOpenPortalModalWindow(true);
  };

  return (
    <>
      {howManyItemsSelected >= 1 &&
        <ModalBulkOperationsFeature>
          <ButtonFeature
            uiStyle='without-style'
            onClick={handleDeselectedAllItems}
            isDisabled={howManyItemsSelected=== 0 ? true : false}
            customStyle='mr-5 text-white'
            size='small'
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.CLOSE_BTN}
          >
            X
          </ButtonFeature>
          <TypographyFeature
            text={`${howManyItemsSelected} items selected`}
            fontSize='xs'
            fontWeight='light'
            color='white'
            customStyle='mr-10'
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.SELECTED_ITEMS_TEXT}
          />
          <ButtonFeature
            uiStyle='dark-blue'
            onClick={handleRemoveItemsModal}
            isDisabled={howManyItemsSelected=== 0 ? true : false}
            size='small'
            dataTestId={DATA_TEST_IDS_BULK_OPERATIONS.DELETE_BTN}
          >
            Delete
          </ButtonFeature>
        </ModalBulkOperationsFeature>
      }
      {
        <ModalWindowBulkOperations
          openPortalModalWindow={openPortalModalWindow}
          setOpenPortalModalWindow={setOpenPortalModalWindow}
          page={page}
          itemsName={itemsName}
        />
      }
    </>
  );
};

interface InterfaceProps {
  page: 'ips' | 'sshKeys' | 'networks',
  handleDeselectedAllItems: () => void,
  itemsName: TModalActionsItemsName,
};
