import { useLocation } from 'react-router-dom';

import Aside from 'components/aside';
import Ipv6Section from 'feature/vm/ip-addresses/Ipv6Section';
import Ipv4sSection from 'feature/vm/ip-addresses/ipv4s-section';
import { GLOSSARY } from 'global-shared/model';

import Items from './info-items';

/**
 * @todo refactoring
 * @description The IDPInfoSection component is used by Instance, Network and Ipv4 (in modal form) pages
 * @param props
 * @example <IDPInfoSectionFeature dataTestIds={} />
 * @returns React Element
*/
var IDPInfoSection = ({ dataTestIds }: {dataTestIds?: {}}) => {
  // hooks
  const location = useLocation();

  // variables
  const isInstancesPage = location.pathname.includes('/instances');
  const paddingContentDependsFromPage = isInstancesPage ? 'pl-6 pr-0' : 'pl-14 pr-6';

  return (
    <div
      data-testid='test_id_vm_overview_modal_info'
      className={`mt-10 ${paddingContentDependsFromPage} gap-4 flex row items-start justify-between`}
    >
      <Items />
      <Aside
        title={GLOSSARY.ip_addresses_plural}
        dataTestIds={dataTestIds}
      >
        <Ipv6Section />
        <Ipv4sSection />
      </Aside>
    </div>
  );
};

export { IDPInfoSection as IDPInfoSectionFeature };
