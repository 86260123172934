import React, { ReactNode } from 'react';

import classes from './index.module.scss';

/**
 * @since 1.0.0
 * @description A component that returns a button
 * with one of the button styles of this project
 * @param props
 * @version 0.0.3
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=2936-560&node-type=frame&t=fwXhzH8OgKE9slbO-0}
 * @package global-shared/button
 * @example
    <Button
      type='button'
      uiStyle='primary'
      title={buttonTitle}
      dataTestId={dataTestIds.button}
      onClick={onClick}
    />
 *
 * @returns React Element - Custom Button
 */
function Button(props: InterfaceProps) {
  // props
  const {
    uiStyle='primary',
    title='',
    type='button',
    customStyle='',
    children='',
    dataTestId='data-test-id',
    size='big',
    isDisabled=false,
    onClick=()=>{},
    onMouseEnter=() => {},
    onMouseLeave=() => {},
  } = props;

  // variables
  const isSmallButton = size === 'small' ? classes.smallButtonClass : classes.bigButtonClass;

  // func
  const setUiStyle = () => {
    switch (uiStyle) {
    case 'gray':
      return classes.grayButtonClass;
    case 'secondary':
      return classes.secondaryButtonClass;
    case 'red':
      return classes.redButtonClass;
    case 'white-with-icon-tooltip':
      return classes.whiteButtonWithIconAndTooltipClass;
    case 'menu-with-title-action':
      return classes.menuActionItemButtonClass;
    case 'tab':
      return classes.grayTabButtonClass;
    case 'only-text':
      return classes.onlyTextButtonClass;
    case 'pagination':
    case 'pagination-active':
      return classes.paginationClass;
    case 'dark-blue':
      return classes.darkBlueButtonClass;
    default:
      return classes.primaryButtonClass;
    }
  };

  const getUiStyle = () => {
    if (uiStyle === 'without-style') {
      return `${classes.withoutStyleButtonClass} ${customStyle}`;
    }
    return `${setUiStyle()} ${isSmallButton} ${customStyle}`;
  };

  return (
    <button
      data-testid={dataTestId}
      type={type}
      className={getUiStyle()}
      disabled={isDisabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {title && title}
      {children}
    </button>
  );
};

export { Button as ButtonFeature };

type TypeUIButton =
  | 'primary'
  | 'secondary'
  | 'red'
  | 'white-with-icon-tooltip'
  | 'gray'
  | 'without-style'
  | 'tab'
  | 'only-text'
  | 'menu-with-title-action'
  | 'pagination'
  | 'pagination-active'
  | 'dark-blue'

interface InterfaceProps {
  /**
   * @default primary
   */
  uiStyle?: TypeUIButton,
  title?: string,
  /**
   * @default button
  */
  type?: 'button' | 'submit' | 'reset' | undefined,
  /**
    * @default false
  */
  isDisabled?: boolean,
  customStyle?: string,
  dataTestId?: string,
  children?: ReactNode,
  /**
   * @default big
  */
  size?: 'small' | 'big'
  onClick?: (event?: any) => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
};
