// tests
const apiPrefix = false ? 'extras/' : '';

export const API_URLS = {
  instance: apiPrefix + 'vms/vm',
  instances: apiPrefix + 'vm/vms',
  tenants: apiPrefix + 'info/customers',
  network: apiPrefix + 'network/network',
  networks: apiPrefix + 'network/networks',
  ipv4: apiPrefix + 'network/ipv4',
  ipv4s: apiPrefix + 'network/ipv4s',
  sshKeyNew: 'sshkeys/sshkey',
  sshKeys: apiPrefix + 'sshkeys',
  images: 'info/images',
  flavors: 'info/flavors',
  clusters: 'info/clusters',
  // maintenance: `${process.env.REACT_APP_STATUS_PAGE_API_ENV}/maintenance_and_incidents_and_scheduled`,
  // statusMaintenance: `${process.env.REACT_APP_STATUS_PAGE_API_ENV}/maintenance_api`,
};
