import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

import { TFetchFuncPage } from 'types';
import { useDashboard } from 'context/dashboard';
import { MessageFeature, SpinnerFeature } from 'global-shared/features';
import { useLogoutHook } from 'global-shared/hooks';
import { Empty, InternalServerError, NotFound } from 'pages';

/**
 * @description a component that returns a specific page state
 * @param props
 * @example
 *   <PageLayout
      page=''
      emptyTextPlaceholder=''
      renderSuccess={
        <DetailsPageLayout
          dataTestIds={{}}
          routeNav=''
          detailsPageName=''
        />
      }
    />
 * @returns null | React Element
 */
export default function PageLayout(props: InterfaceProps) {
  // props
  const { page, renderSuccess, emptyTextPlaceholder } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { loading, error, data: response, status } = fetchFunc[page].get;
  // const validCode5xx: RegExp = new RegExp(/5[0-9][0-9]/g);
  const { handleLogout } = useLogoutHook();

  if (Array.isArray(response)) {
    if (status === 200 && response.length === 0) return <>{renderSuccess} <Empty description={emptyTextPlaceholder} /></> ;
  };
  if (status === 403) {
    handleLogout();
    return null;
  }
  if (status === 401) return <Redirect  to='/login' />;
  if (status === 404) return (page === 'instances' || page === 'virtualmachine' || page === 'baremetal' || page === 'sshKeys') ?
    <>{renderSuccess} <Empty description={emptyTextPlaceholder} /></> :
    <NotFound /> ;
  if (status === 500) return <InternalServerError code={status} />;
  if (error) return <MessageFeature status='error' msg={error} />;
  if (loading) return <SpinnerFeature />;
  if (status === 200) return renderSuccess;

  return null;
};

interface InterfaceProps {
  page: TFetchFuncPage,
  renderSuccess: ReactElement,
  emptyTextPlaceholder: string,
};
