const IconBigFullLogo = ({color = 'white'}: { color?: 'white' | 'black'}) => {
  //func
  const changeColor = () => {
    if (color === 'black') return 'fill-black' ;
    return 'fill-white';
  };

  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' xmlSpace='preserve' viewBox='61.3 40.6 432.6 143.4'>
      <g>
        <path className={changeColor()} d='M406.1,166.2'/>
        <polygon className={changeColor()} points='65.5,105.7 114.2,105.7 114.2,113 114.2,113 114.2,144.5 106.8,138.5 106.8,113 74.6,113  '/>
        <path className={changeColor()} d='M155.7,54.2c-9.5-0.3-18.3,3.8-24,11c-1.2,1.5-0.8,3.6,0.8,4.6l0.8,0.5c1.3,0.8,3.1,0.5,4.1-0.7   c4.7-5.9,12.1-9,19.9-8.1c10.1,1.2,18.3,9.4,19.3,19.5c1.4,13.3-9,24.5-22,24.5h-24.1l-0.1,0.3v-0.3l-7.4,0v45.9l7.4,5.9v-33.8   l0-10.8h24.3c16.8,0,30.3-14.1,29.3-31.1C183.1,66.9,170.7,54.8,155.7,54.2z'/>
        <g>
          <g>
            <g>
              <path className={changeColor()} d='M230.6,113V66.8h-22.6V57h56.2v9.8h-22.6V113H230.6z'/>
              <path className={changeColor()} d='M265.5,113l26.2-56H304l26.1,56h-11.9l-23.9-52.1h6.9L277.2,113H265.5z M278.3,99.9l3.4-9.8h31.9l3.4,9.8      H278.3z'/>
              <path className={changeColor()} d='M338.9,113V57h11v56H338.9z'/>
              <path className={changeColor()} d='M391.6,113.8c-6.6,0-12.2-1.2-17-3.6c-4.7-2.4-8.4-5.8-10.9-10.2c-2.5-4.3-3.8-9.3-3.8-15      c0-5.6,1.3-10.6,3.8-14.9c2.6-4.3,6.2-7.7,11-10.2c4.7-2.5,10.4-3.7,17-3.7c6,0,11,0.9,15.2,2.6c4.1,1.8,7.3,4.1,9.5,7.2      c2.2,3,3.5,6.4,3.7,10.2h-11.6c-0.2-1.1-0.5-2.3-1.2-3.5c-0.6-1.2-1.5-2.3-2.8-3.3c-1.3-1-2.9-1.8-4.9-2.5s-4.4-1-7.3-1H391      c-4,0-7.5,0.7-10.5,2.2c-3,1.5-5.3,3.7-7,6.5C371.8,77.5,371,81,371,85c0,4,0.8,7.4,2.5,10.3c1.7,2.9,4,5,7,6.5      c3,1.5,6.5,2.2,10.4,2.2h1.3c3.9,0,7.2-0.5,9.8-1.6s4.7-2.3,6.1-3.9c1.4-1.5,2.1-3.1,2.1-4.6v-0.6h-18.4v-9.8h21.4      c2.5,0,4.3,0.7,5.6,2c1.3,1.3,2,3.2,2,5.7V113h-10.6v-6.2c-0.7,0.9-1.8,1.9-3.4,3c-1.6,1.1-3.6,2-6.1,2.8      C398.3,113.4,395.2,113.8,391.6,113.8z'/>
              <path className={changeColor()} d='M425.2,113l26.2-56h12.3l26.1,56h-11.9l-23.9-52.1h6.9L436.9,113H425.2z M438,99.9l3.4-9.8h31.9l3.4,9.8      H438z'/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path className={changeColor()} d='M233.2,154.3c-2.8,0-5.3-0.5-7.4-1.6c-2.1-1.1-3.6-2.6-4.7-4.5c-1.1-1.9-1.6-4.1-1.6-6.6      c0-2.4,0.6-4.6,1.7-6.5c1.1-1.9,2.7-3.4,4.8-4.5c2.1-1.1,4.5-1.7,7.3-1.7c2.4,0,4.5,0.4,6.2,1.2c1.7,0.8,3.1,1.8,4.1,3.1      c1,1.3,1.6,2.7,1.9,4.2h-5.1c-0.2-0.9-0.6-1.7-1.3-2.3s-1.4-1.1-2.4-1.4c-1-0.3-2-0.5-3.1-0.5h-0.6c-1.8,0-3.3,0.4-4.6,1.1      c-1.3,0.8-2.2,1.8-2.9,3c-0.7,1.3-1,2.7-1,4.2c0,1.6,0.3,3,1,4.2c0.7,1.3,1.7,2.3,2.9,3c1.3,0.8,2.8,1.1,4.6,1.1h0.6      c1.7,0,3.2-0.3,4.4-1c1.3-0.7,2.1-1.7,2.4-3.1h5.1c-0.3,1.6-0.9,3.1-2,4.4c-1,1.3-2.4,2.3-4.2,3      C237.5,153.9,235.5,154.3,233.2,154.3z'/>
              <path className={changeColor()} d='M257,153.9v-24.7h4.9v20.4h16.5v4.3H257z'/>
              <path className={changeColor()} d='M301.7,154.3c-2.7,0-5-0.5-7.2-1.5c-2.1-1-3.8-2.5-5-4.4c-1.2-1.9-1.8-4.2-1.8-6.8c0-2.6,0.6-4.9,1.8-6.8      c1.2-1.9,2.9-3.3,5-4.4c2.1-1,4.5-1.5,7.2-1.5c2.7,0,5.1,0.5,7.2,1.5s3.8,2.5,5,4.4c1.2,1.9,1.8,4.2,1.8,6.8      c0,2.6-0.6,4.9-1.8,6.8c-1.2,1.9-2.9,3.3-5,4.4S304.4,154.3,301.7,154.3z M301.5,150h0.6c1.6,0,3.1-0.3,4.4-1      c1.3-0.7,2.4-1.6,3.2-2.9c0.8-1.3,1.2-2.8,1.2-4.5c0-1.8-0.4-3.3-1.2-4.5s-1.9-2.2-3.2-2.9c-1.3-0.7-2.8-1-4.4-1h-0.6      c-1.6,0-3,0.3-4.4,1c-1.3,0.7-2.4,1.6-3.2,2.9c-0.8,1.2-1.2,2.8-1.2,4.5c0,1.8,0.4,3.3,1.2,4.5c0.8,1.3,1.9,2.2,3.2,2.9      S299.9,150,301.5,150z'/>
              <path className={changeColor()} d='M338.4,154.3c-3.8,0-6.6-0.9-8.4-2.7c-1.9-1.8-2.8-4.3-2.8-7.4v-14.9h4.9v14.7c0,2,0.5,3.5,1.6,4.5      s2.5,1.6,4.5,1.6h0.6c2,0,3.5-0.5,4.5-1.6s1.6-2.5,1.6-4.5v-14.7h4.9v14.9c0,3.1-0.9,5.5-2.8,7.4      C344.9,153.3,342.1,154.3,338.4,154.3z'/>
              <path className={changeColor()} d='M362.7,153.9v-24.7h11.8c2.7,0,4.9,0.5,6.9,1.5c1.9,1,3.4,2.4,4.5,4.2c1,1.8,1.6,4.1,1.6,6.6      s-0.5,4.8-1.6,6.6c-1,1.8-2.5,3.3-4.5,4.2c-1.9,1-4.2,1.5-6.9,1.5H362.7z M367.6,149.6h6.6c2.8,0,4.8-0.7,6.2-2      c1.4-1.4,2.1-3.4,2.1-6c0-2.7-0.7-4.7-2.1-6c-1.4-1.4-3.5-2-6.2-2h-6.6V149.6z'/>
            </g>
          </g>
        </g>
        <path className={changeColor()} d='M70.7,99.2c-0.8-3.3-1.3-6.6-1.3-10.1c0-22.3,17.9-40.4,39.9-40.4c7.5,0,14.7,2.1,20.9,6   c1.1,0.7,2.5,0.7,3.6,0.1l1.8-1c1.9-1.1,2-3.8,0.2-5.1c-7.8-5.3-17-8.1-26.5-8.1c-26.4,0-48,21.8-48,48.6c0,3.4,0.4,6.8,1,10.1   H70.7z'/>
        <g>
          <g>
            <g>
              <path className={changeColor()} d='M221.3,183.8v-10.5h7.5c0.7,0,1.2,0.1,1.8,0.3c0.5,0.2,0.9,0.5,1.2,1c0.3,0.4,0.4,0.9,0.4,1.5      c0,0.6-0.2,1.1-0.5,1.5c-0.3,0.4-0.7,0.7-1.1,0.8c0.3,0.1,0.7,0.2,0.9,0.5c0.3,0.2,0.5,0.5,0.7,0.9c0.2,0.3,0.3,0.7,0.3,1.2      c0,0.6-0.2,1.1-0.5,1.5c-0.3,0.4-0.7,0.8-1.2,1c-0.5,0.2-1.1,0.4-1.8,0.4H221.3z M223.2,177.6h5.3c0.6,0,1-0.1,1.3-0.4      s0.5-0.6,0.5-1s-0.2-0.7-0.5-1s-0.8-0.3-1.3-0.3h-5.3V177.6z M223.2,182.1h5.5c0.6,0,1.1-0.1,1.4-0.3c0.3-0.2,0.5-0.6,0.5-1.1      c0-0.5-0.2-0.8-0.5-1c-0.3-0.2-0.8-0.4-1.4-0.4h-5.5V182.1z'/>
              <path className={changeColor()} d='M238.5,183.8v-4.5l-5.3-6h2.5l4.4,5.1h-1.4l4.4-5.1h2.5l-5.2,6v4.5H238.5z'/>
              <path className={changeColor()} d='M250.3,183.8v-10.5h2.5l7.8,9.1l-0.6,0.1v-9.1h1.9v10.5h-2.5l-7.8-9.1l0.6-0.1v9.2H250.3z'/>
              <path className={changeColor()} d='M271.1,184c-1,0-1.9-0.1-2.7-0.4c-0.8-0.2-1.6-0.6-2.2-1c-0.6-0.5-1.1-1-1.5-1.7c-0.4-0.7-0.5-1.4-0.5-2.3      c0-0.9,0.2-1.6,0.5-2.3c0.4-0.7,0.9-1.2,1.5-1.7c0.6-0.5,1.4-0.8,2.2-1c0.8-0.2,1.8-0.4,2.7-0.4c1,0,1.9,0.1,2.7,0.4      c0.8,0.2,1.6,0.6,2.2,1c0.6,0.5,1.1,1,1.5,1.7s0.5,1.4,0.5,2.3c0,0.9-0.2,1.6-0.5,2.3s-0.9,1.2-1.5,1.7c-0.6,0.5-1.4,0.8-2.2,1      C273,183.8,272.1,184,271.1,184z M270.9,182.3h0.3c0.7,0,1.3-0.1,1.9-0.2c0.6-0.2,1.1-0.4,1.6-0.7c0.5-0.3,0.8-0.7,1.1-1.2      c0.3-0.5,0.4-1,0.4-1.6c0-0.6-0.1-1.1-0.4-1.6c-0.3-0.5-0.6-0.9-1.1-1.2c-0.5-0.3-1-0.6-1.6-0.7c-0.6-0.2-1.2-0.2-1.9-0.2h-0.3      c-0.7,0-1.3,0.1-1.9,0.2c-0.6,0.2-1.1,0.4-1.6,0.7c-0.5,0.3-0.8,0.7-1.1,1.2c-0.3,0.5-0.4,1-0.4,1.6c0,0.6,0.1,1.1,0.4,1.6      c0.3,0.5,0.6,0.9,1.1,1.2c0.5,0.3,1,0.6,1.6,0.7C269.6,182.2,270.3,182.3,270.9,182.3z'/>
              <path className={changeColor()} d='M280.3,183.8v-10.5h7.3c0.7,0,1.4,0.1,1.9,0.4s1,0.6,1.3,1c0.3,0.4,0.5,0.9,0.5,1.5c0,0.4-0.1,0.8-0.3,1.2      c-0.2,0.3-0.4,0.6-0.7,0.9c-0.3,0.2-0.6,0.4-1,0.5c0.6,0,1,0.1,1.3,0.4c0.3,0.3,0.4,0.7,0.4,1.2v3.5h-1.9v-3      c0-0.4-0.1-0.7-0.3-0.8c-0.2-0.1-0.5-0.2-0.9-0.2h-5.8v4.1H280.3z M282.2,178.1h5.1c0.6,0,1.1-0.1,1.5-0.4s0.5-0.7,0.5-1.1      c0-0.5-0.2-0.9-0.5-1.1c-0.4-0.3-0.9-0.4-1.5-0.4h-5.1V178.1z'/>
              <path className={changeColor()} d='M297.7,183.8V175h-5v-1.7h12v1.7h-5v8.8H297.7z'/>
              <path className={changeColor()} d='M306.5,183.8v-10.5h1.9v4.4h8.1v-4.4h1.9v10.5h-1.9v-4.5h-8.1v4.5H306.5z'/>
              <path className={changeColor()} d='M321.3,183.8v-10.5h10.8v1.7h-8.9v7.2h9.1v1.7H321.3z M322.9,179.2v-1.7h8.3v1.7H322.9z'/>
              <path className={changeColor()} d='M334.5,183.8v-10.5h7.3c0.7,0,1.4,0.1,1.9,0.4s1,0.6,1.3,1c0.3,0.4,0.5,0.9,0.5,1.5c0,0.4-0.1,0.8-0.3,1.2      c-0.2,0.3-0.4,0.6-0.7,0.9s-0.6,0.4-1,0.5c0.6,0,1,0.1,1.3,0.4c0.3,0.3,0.4,0.7,0.4,1.2v3.5h-1.9v-3c0-0.4-0.1-0.7-0.3-0.8      c-0.2-0.1-0.5-0.2-0.9-0.2h-5.8v4.1H334.5z M336.4,178.1h5.1c0.6,0,1.1-0.1,1.5-0.4c0.4-0.3,0.5-0.7,0.5-1.1      c0-0.5-0.2-0.9-0.5-1.1c-0.4-0.3-0.9-0.4-1.5-0.4h-5.1V178.1z'/>
              <path className={changeColor()} d='M348.1,183.8v-10.5h2.5l7.8,9.1l-0.6,0.1v-9.1h1.9v10.5h-2.5l-7.8-9.1l0.6-0.1v9.2H348.1z'/>
              <path className={changeColor()} d='M367.1,183.8v-10.5h6.5c1.2,0,2.2,0.2,3.1,0.6c0.9,0.4,1.6,1,2.1,1.8s0.8,1.7,0.8,2.8      c0,1.1-0.2,2.1-0.8,2.8s-1.2,1.4-2.1,1.8c-0.9,0.4-1.9,0.6-3.1,0.6H367.1z M369,182.1h4.3c1.4,0,2.5-0.3,3.2-0.9      c0.8-0.6,1.1-1.5,1.1-2.7c0-1.2-0.4-2.1-1.1-2.7s-1.8-0.9-3.2-0.9H369V182.1z'/>
              <path className={changeColor()} d='M380,183.8l5.8-10.5h2.4l5.8,10.5h-2.2l-5.1-9.5h0.6l-5.1,9.5H380z M382.6,181.3v-1.7h8.6v1.7H382.6z'/>
              <path className={changeColor()} d='M398.8,183.8V175h-5v-1.7h12v1.7h-5v8.8H398.8z'/>
              <path className={changeColor()} d='M405.7,183.8l5.8-10.5h2.4l5.8,10.5h-2.2l-5.1-9.5h0.6l-5.1,9.5H405.7z M408.3,181.3v-1.7h8.6v1.7H408.3z'/>
              <path className={changeColor()} d='M431.4,184c-1.5,0-2.8-0.2-3.8-0.7c-1-0.5-1.8-1.1-2.3-1.9c-0.5-0.8-0.8-1.7-0.8-2.8c0-1.1,0.3-2,0.8-2.8      c0.6-0.8,1.4-1.5,2.4-1.9c1-0.5,2.3-0.7,3.8-0.7c1.3,0,2.4,0.2,3.2,0.5s1.6,0.8,2.1,1.3c0.5,0.6,0.8,1.2,0.9,1.9h-2.1      c0-0.2-0.1-0.4-0.3-0.7c-0.2-0.2-0.4-0.5-0.7-0.7c-0.3-0.2-0.7-0.4-1.2-0.5c-0.5-0.1-1.1-0.2-1.8-0.2h-0.3c-1,0-1.8,0.1-2.5,0.4      c-0.7,0.3-1.3,0.7-1.7,1.3s-0.6,1.2-0.6,2c0,0.8,0.2,1.5,0.6,2c0.4,0.6,0.9,1,1.7,1.3c0.7,0.3,1.6,0.4,2.6,0.4h0.3      c1,0,1.8-0.1,2.5-0.3c0.7-0.2,1.2-0.5,1.5-0.8s0.5-0.7,0.5-1.1V180h-4.7v-1.7h5.3c0.5,0,0.8,0.1,1,0.4c0.2,0.3,0.3,0.6,0.3,1      v4.2H436v-1.4c-0.2,0.2-0.4,0.4-0.8,0.7c-0.4,0.2-0.9,0.4-1.5,0.6C433.1,183.9,432.3,184,431.4,184z'/>
              <path className={changeColor()} d='M440.3,183.8v-10.5h7.3c0.7,0,1.4,0.1,1.9,0.4s1,0.6,1.3,1c0.3,0.4,0.5,0.9,0.5,1.5c0,0.4-0.1,0.8-0.3,1.2      c-0.2,0.3-0.4,0.6-0.7,0.9s-0.6,0.4-1,0.5c0.6,0,1,0.1,1.3,0.4s0.4,0.7,0.4,1.2v3.5h-1.9v-3c0-0.4-0.1-0.7-0.3-0.8      c-0.2-0.1-0.5-0.2-0.9-0.2h-5.8v4.1H440.3z M442.2,178.1h5.1c0.6,0,1.1-0.1,1.5-0.4c0.4-0.3,0.5-0.7,0.5-1.1      c0-0.5-0.2-0.9-0.5-1.1c-0.4-0.3-0.9-0.4-1.5-0.4h-5.1V178.1z'/>
              <path className={changeColor()} d='M460.1,184c-1,0-1.9-0.1-2.7-0.4c-0.8-0.2-1.6-0.6-2.2-1c-0.6-0.5-1.1-1-1.5-1.7s-0.5-1.4-0.5-2.3      c0-0.9,0.2-1.6,0.5-2.3s0.9-1.2,1.5-1.7c0.6-0.5,1.4-0.8,2.2-1c0.8-0.2,1.8-0.4,2.7-0.4c1,0,1.9,0.1,2.7,0.4      c0.8,0.2,1.6,0.6,2.2,1c0.6,0.5,1.1,1,1.5,1.7c0.4,0.7,0.5,1.4,0.5,2.3c0,0.9-0.2,1.6-0.5,2.3c-0.4,0.7-0.9,1.2-1.5,1.7      c-0.6,0.5-1.4,0.8-2.2,1C462,183.8,461.1,184,460.1,184z M460,182.3h0.3c0.7,0,1.3-0.1,1.9-0.2c0.6-0.2,1.1-0.4,1.6-0.7      c0.5-0.3,0.8-0.7,1.1-1.2c0.3-0.5,0.4-1,0.4-1.6c0-0.6-0.1-1.1-0.4-1.6s-0.6-0.9-1.1-1.2c-0.5-0.3-1-0.6-1.6-0.7      c-0.6-0.2-1.2-0.2-1.9-0.2H460c-0.7,0-1.3,0.1-1.9,0.2c-0.6,0.2-1.1,0.4-1.6,0.7c-0.5,0.3-0.8,0.7-1.1,1.2      c-0.3,0.5-0.4,1-0.4,1.6c0,0.6,0.1,1.1,0.4,1.6c0.3,0.5,0.6,0.9,1.1,1.2c0.5,0.3,1,0.6,1.6,0.7      C458.7,182.2,459.3,182.3,460,182.3z'/>
              <path className={changeColor()} d='M474.6,184c-1.2,0-2.3-0.2-3.1-0.6c-0.8-0.4-1.4-1-1.8-1.7c-0.4-0.7-0.6-1.6-0.6-2.5v-5.8h1.9v5.7      c0,1.1,0.3,1.9,0.9,2.5s1.4,0.8,2.6,0.8h0.3c1.1,0,2-0.3,2.6-0.8c0.6-0.5,0.9-1.4,0.9-2.5v-5.7h1.9v5.8c0,1-0.2,1.8-0.6,2.5      c-0.4,0.7-1,1.3-1.9,1.7C476.8,183.8,475.8,184,474.6,184z'/>
              <path className={changeColor()} d='M482.9,183.8v-10.5h7c0.8,0,1.5,0.1,2.1,0.4s1,0.7,1.4,1.2c0.3,0.5,0.5,1.1,0.5,1.7c0,0.6-0.2,1.2-0.5,1.7      c-0.3,0.5-0.8,0.9-1.4,1.2s-1.3,0.4-2.1,0.4h-5.1v3.9H482.9z M484.8,178.2h4.8c0.8,0,1.3-0.1,1.7-0.4s0.6-0.7,0.6-1.2      c0-0.5-0.2-0.9-0.6-1.2s-0.9-0.4-1.7-0.4h-4.8V178.2z'/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { IconBigFullLogo };
