import React from 'react';
import AlertMsg from 'components/alerts/AlertMsg';
import { GLOSSARY } from 'global-shared/model';
import { PortalModalFeature, SpinnerFeature, TypographyFeature } from 'global-shared/features';

/**
 * @description
 * @param loading
 * @returns
 */
function CreatingInstanceModal() {
  return (
    <PortalModalFeature.ModalFeature
      modalType='info-box'
    >
      <div className='col py-[42px] all-items-center'>
        <SpinnerFeature />
        <TypographyFeature
          text={`Creating a new ${GLOSSARY.virtual_machine}`}
          fontWeight='medium'
          customStyle='mt-5 mb-[23px]'
        />
        <AlertMsg typeOfAlert='info'>
          <p>{GLOSSARY.descriptions.please_do_not_close_your_browser}</p>
        </AlertMsg>
      </div>
    </PortalModalFeature.ModalFeature>
  );
};

export { CreatingInstanceModal as CreatingInstanceModalFeature };
