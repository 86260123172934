import React, { useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { setFetchFunc } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';
import { GLOSSARY } from 'global-shared/model';

import SectionWithTags from './SectionWithTags';
import Tag from './Tag';

/**
 * @todo eslint-disable-next-line no-restricted-imports
 * @returns
 */
export default function Keys() {
  //variables
  const { useValue, useDispatch, setInstance, setInstanceValidation } = NewInstanceFormContext;
  const dispatch = useDispatch();
  const { instance, validation } = useValue();
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const { fetchFunc } = stateDashboard;
  const { data: sshKeys } = fetchFunc.sshKeys.get;

  const handleRemoveKey = (uuid: string) => {
    const newArray = instance.sshKeys.filter(key => key.uuid !== uuid);
    const selectedKeys = sshKeys.map(
      item => item.uuid === uuid ? {...item, checked: false} : item);
    dispatchDashboard(setFetchFunc(selectedKeys, 'sshKeys', 'get', 'data'));
    dispatch(setInstance(newArray, 'sshKeys'));
  };

  const showKeys = () => {
    return (
      instance.sshKeys?.map(item => (
        <Tag
          key={item.uuid}
          name={item.name}
          handleRemoveTag={instance.sshKeys.length <= 6 ? handleRemoveKey : () => {}}
          value={item.uuid}
        />
      ))
    );
  };

  useEffect(() => {
    if(instance.sshKeys.length === 0) {
      dispatch(setInstanceValidation(undefined, 'isSshKey'));
    };
  }, [instance.sshKeys, dispatch, setInstanceValidation]);

  return (
    <SectionWithTags title={GLOSSARY.authentication} testDataId='authentication'>
      <>
        {validation.isSshKey ?
          <>
            <ExclamationCircleIcon className='fill-customDarkRed w-[18px] mr-[10px]' />
            <p className='text-customDarkRed'> Add {GLOSSARY.ssh_key}</p>
          </>
          :
          instance.sshKeys.length === 0 ? `${GLOSSARY.actions.add} ${GLOSSARY.ssh_key}` : showKeys()
        }
      </>
    </SectionWithTags>
  );
};
