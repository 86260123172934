import { IIpv4, INetwork } from 'types';
import { TActionsDependsOfStatus, TFetchFuncRequestMethod } from 'types';

import { request } from './request';

type TNewIpv4 = {
  tenant_uuid: string,
  cluster_uuid: string,
};

type TBodyStatusUpdateVm = {
  status: TActionsDependsOfStatus | string,
};

type TNewVm = {
  tenant_uuid: string,
  sshkeys: { uuid: string}[],
  cloud_init_user_data: {},
  name: string,
  network_uuid: string,
  flavor_uuid: string,
  image_uuid: string,
  addresses4: IIpv4[],
  cluster_uuid: string,
};

interface INewSshKey {
  tenant_uuid: string,
  name: string,
  key: string,
};

type TFetchBody = [] | TNewIpv4 | TBodyStatusUpdateVm | TNewVm | INewSshKey | INetwork | IIpv4;

const useFetchData = <TBody extends TFetchBody, TResponse>(
  url: string,
  method: TFetchFuncRequestMethod,
  signal?: AbortSignal | null,
  body?: TBody,
) => {
  // variables
  const oktaToken = localStorage.getItem('okta-token-storage');
  const accessToken = JSON.parse(oktaToken || '');
  const token = accessToken.accessToken.accessToken;
  const tenant = sessionStorage.getItem('selectedTenantIdStorage');

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-type': 'application/json',
  };

  const options = { headers, method: method.toLocaleUpperCase(), signal };
  const optionsWithBody = { ...options, body: JSON.stringify(body) };
  const requestOptions = body ? optionsWithBody : options;

  if (!token && !tenant) {
    throw new Error('Not authenticated');
  };

  return request<TResponse>(url, requestOptions, 'default');
};

const api = { useFetchData };

export { api };
