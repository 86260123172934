import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { InterfaceFlavorAPI } from 'global-shared/model/dataTypes';
import { API_URLS } from 'global-shared/model';

/**
 * @todo refactoring
 * @description
 */
const useMakeGetRequestFlavors = () => {
  //state
  const [error, setError] = useState<string>('');
  const [isPending, setIsPending] = useState<boolean>(false);
  const [response, setResponse] = useState<InterfaceFlavorAPI[]>([]);
  const [status, setStatus] = useState<number>(0);

  //variables
  const url = `${process.env.REACT_APP_API_URL}/${API_URLS.flavors}`;
  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;

  //hooks
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const loadData = async () => {
      setIsPending(true);
      setError('');

      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json',
      };
      const options = { headers, method: 'GET', signal };

      try {
        const response = await fetch(url, options);
        const responseJson = await response.json();
        setStatus(response.status);
        setError('');
        setResponse(responseJson);
      } catch (error) {
        if (error instanceof Error) {
          setError(error?.message || '');
        }
      }

      setIsPending(false);
    };
    loadData();

    return () => {
      controller.abort();
      setIsPending(false);
      setError('');
    };
  }, [token, url]);

  const request = {
    isPending,
    status,
    response,
    error,
  };

  return { request };
};

export { useMakeGetRequestFlavors as useMakeGetRequestFlavorsApi };
