import React, { ChangeEvent, forwardRef } from 'react';

import classes from './forms.module.scss';

import Info from '../Info';

type TProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
  name: string,
  inputFeedback: string,
  placeholder: string,
  type: string,
  label: string,
  value: string,
  // showMaxLengthValue?: boolean,
  maxLength?: number,
  classNames?: string,
  testDataId?: string,
  required?: boolean,
  pattern?: string,
  inputStatus: 'error' | 'warning' | 'validation',
};

const CustomInput = forwardRef<HTMLInputElement, TProps>((props, ref) => {
  const { customInputClass, validationClass } = classes;
  const {
    handleChange,
    name,
    inputFeedback,
    inputStatus,
    placeholder,
    type,
    label,
    value,
    // showMaxLengthValue = false,
    maxLength=200,
    classNames,
    testDataId,
    required,
    pattern,
  } = props;

  const inputView = () => {
    if (inputStatus === 'validation' && inputFeedback) return `${classNames} ${validationClass} placeholder-customColorDarkBlue`;
    if (inputStatus === 'warning' && inputFeedback) return classNames;
    return classNames;
  };

  return (
    <>
      <label htmlFor={name}>
        {label}
      </label>
      <input
        ref={ref}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        maxLength={maxLength}
        className={`peer w-full ${inputView()} ${customInputClass}`}
        onChange={handleChange}
        value={value ?? ''}
        data-testid={testDataId}
        required={required}
        pattern={pattern}
      />
      {/* {showMaxLengthValue &&
        <p className='font-thin text-xxxs items-end flex justify-end'>
          {value.length}/{maxLength}
        </p>
      } */}
      {inputFeedback && <Info status={inputStatus} msg={inputFeedback} />}
    </>
  );
});

export default CustomInput;
