function AlertValidation({text}: {text: string}) {
  return (
    <div
      className={'text-customDarkRed ml-5 text-s font-medium absolute -bottom-8'}
    >
      {text}
    </div>
  );
};

export { AlertValidation as AlertValidationFeature };
