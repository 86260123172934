// Vms
export const TEST_ID_VMS_PAGE_CREATE_VM_BTN: string = 'create_vm_btn';

// create a new vm page
export const TEST_ID_VMS_PAGE_START_NEW_VM_BTN: string = 'start_vm_btn';

// create network on new vm page
export const TEST_ID_VMS_PAGE_CREATE_NEW_NETWORK_BTN: string = 'create_network_btn';

export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_NETWORK_NAME_INPUT: string = 'create_new_vm_network_name_input';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_CREATE_NETWORK_FORM: string = 'create_new_vm_create_network_form';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_SELECT_CLUSTER: string = 'create_new_vm_select_cluster';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_NEW_NETWORK_CANCEL_BTN: string = 'create_new_vm_cancel_btn';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_NEW_NETWORK_CREATE_BTN: string = 'create_new_vm_create_btn';

export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_NEW_NETWORK: string[] = [
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_NETWORK_NAME_INPUT,
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_CREATE_NETWORK_FORM,
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_SELECT_CLUSTER,
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_NEW_NETWORK_CANCEL_BTN,
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_NEW_NETWORK_CREATE_BTN,
];

// create ssh key on new vm page
export const TEST_ID_VMS_PAGE_ADD_SSH_KEY_BTN: string = 'add_ssh_key_btn';

export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_NAME_INPUT: string = 'ssh_name_input';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_FORM: string = 'ssh_form';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_VALUE_INPUT: string = 'ssh_value_input';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_CANCEL_BTN: string = 'cancel_btn';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_CREATE_BTN: string = 'create_btn';

export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_ADD_SSH_KEY: string[] = [
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_NAME_INPUT,
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_FORM,
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_VALUE_INPUT,
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_CANCEL_BTN,
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_SSH_KEY_CREATE_BTN,
];

// create ip on new vm page
export const TEST_ID_VMS_PAGE_CREATE_NEW_IPV4_BTN: string = 'create_new_ipv4_btn';

// add existing ip on new vm page
export const TEST_ID_VMS_PAGE_ADD_IPV4_BTN: string = 'add_ipv4_address_btn';

export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_IPV4_FORM: string = 'create_form';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_IPV4_CANCEL_BTN: string = 'cancel_btn';
export const TEST_ID_VMS_PAGE_CREATE_NEW_VM_IPV4_CREATE_BTN: string = 'create_ipv4_btn';

export const TEST_ID_VMS_PAGE_ADD_IPV4_ADDRESS: string[] = [
  '',
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_IPV4_FORM,
  '',
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_IPV4_CANCEL_BTN,
  TEST_ID_VMS_PAGE_CREATE_NEW_VM_IPV4_CREATE_BTN,
];

// Ssh keys
export const TEST_ID_SSH_KEYS_PAGE_ADD_SSH_KEY_BTN: string = 'add_ssh_key_btn';

export const TEST_ID_SSH_KEYS_PAGE_NAME_INPUT: string = 'ssh_name_input';
export const TEST_ID_SSH_KEYS_PAGE_SSH_FORM: string = 'ssh_form';
export const TEST_ID_SSH_KEYS_PAGE_SSH_VALUE_INPUT: string = 'ssh_value_input';
export const TEST_ID_SSH_KEYS_PAGE_CANCEL_BTN: string = 'cancel_btn';
export const TEST_ID_SSH_KEYS_PAGE_CREATE_BTN: string = 'create_btn';

export const TEST_ID_SSH_KEYS_PAGE: string[] = [
  TEST_ID_SSH_KEYS_PAGE_NAME_INPUT,
  TEST_ID_SSH_KEYS_PAGE_SSH_FORM,
  TEST_ID_SSH_KEYS_PAGE_SSH_VALUE_INPUT,
  TEST_ID_SSH_KEYS_PAGE_CANCEL_BTN,
  TEST_ID_SSH_KEYS_PAGE_CREATE_BTN,
];

export const TEST_ID_SSH_KEYS_PAGE_COPY_TOOLTIP: `test_id_${string}` = 'test_id_ssh_key_tooltip';
export const TEST_ID_SSH_KEYS_PAGE_SELECT_ALL_SSH_KEYS_BTN: string = 'select_all';


// Networks
export const TEST_ID_NETWORKS_PAGE_CREATE_NETWORK_BTN: string = 'create_network_btn';

export const TEST_ID_NETWORKS_PAGE_CREATE_NETWORK_NAME_INPUT: string = 'create_network_name_input';
export const TEST_ID_NETWORKS_PAGE_CREATE_NETWORK_FORM: string = 'create_network_form';
export const TEST_ID_NETWORKS_PAGE_SELECT_CLUSTER: string = 'select_cluster';
export const TEST_ID_NETWORKS_PAGE_CANCEL_BTN: string = 'cancel_btn';
export const TEST_ID_NETWORKS_PAGE_CREATE_BTN: string = 'create_btn';

export const TEST_ID_NETWORKS_PAGE: string[] = [
  TEST_ID_NETWORKS_PAGE_CREATE_NETWORK_NAME_INPUT,
  TEST_ID_NETWORKS_PAGE_CREATE_NETWORK_FORM,
  TEST_ID_NETWORKS_PAGE_SELECT_CLUSTER,
  TEST_ID_NETWORKS_PAGE_CANCEL_BTN,
  TEST_ID_NETWORKS_PAGE_CREATE_BTN,
];

export const TEST_ID_NETWORKS_PAGE_SELECT_ALL_NETWORKS_BTN: string = 'select_all';
export const TEST_ID_NETWORKS_PAGE_ALERT_SECTION: string = 'alert_network_page_section_id';

// IPs
export const TEST_ID_IPS_PAGE_CREATE_IPV4_ADDRESS_BTN: 'create_ipv4_address_btn' = 'create_ipv4_address_btn';

export const TEST_ID_IPS_PAGE_CREATE_IPV4_INPUT: string = 'create_ipv4_input';
export const TEST_ID_IPS_PAGE_CREATE_IPV4_FORM: string = 'create_form';
export const TEST_ID_IPS_PAGE_SELECT_CLUSTER: string = 'select_cluster';
export const TEST_ID_IPS_PAGE_CANCEL_BTN: string = 'cancel_btn';
export const TEST_ID_IPS_PAGE_CREATE_IPV4_BTN: string = 'create_ipv4_btn';

export const TEST_ID_IPS_PAGE: string[] = [
  TEST_ID_IPS_PAGE_CREATE_IPV4_INPUT,
  TEST_ID_IPS_PAGE_CREATE_IPV4_FORM,
  TEST_ID_IPS_PAGE_SELECT_CLUSTER,
  TEST_ID_IPS_PAGE_CANCEL_BTN,
  TEST_ID_IPS_PAGE_CREATE_IPV4_BTN,
];

export const TEST_ID_IPS_PAGE_SELECT_ALL_IPS_BTN: string = 'select_all';

// Sidebar
export const TEST_ID_SIDEBAR: string = 'sidebar_id';

// Alert
/**
 * @deprecated
 */
export const TEST_ID_ALERT_MSG: string = 'alert_msg_id';
