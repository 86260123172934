import { useEffect } from 'react';

import { useDashboard } from 'context/dashboard';
import PortalNotification from 'portals/Notification';
import { useLogoutHook } from 'global-shared/hooks';
import { GLOSSARY } from 'global-shared/model';
import {
  MessageFeature,
  PageHeaderFeature,
  SpinnerFeature,
  TypographyFeature,
  useTenants,
} from 'global-shared/features';
import { IconGtcLogo } from 'global-shared/assets';
import { useCleanState } from 'hooks/useCleanState';

import classes from './shared/index.module.scss';
import { useGetMetricsHook } from './hooks';
import { DocsFeature,
  InstancesFeature,
  Ipv4sFeature,
  NetworksFeature,
  OverviewPageEmptyStateFeature,
  SshKeyFeature,
} from './features';

/**
 * @since 1.2.0
 * @description The Overview page shows metrics and
 * the total number of items for instances,
 * networks, ipv4 addresses, and ssh keys.
 * @version 0.0.4
 * @package pages/overview
 * @example <OverviewPage />
 */
function Overview() {
  //variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { metrics: { instances, networks, ipv4s, ssh_keys }, fetchFunc } = stateDashboard;
  const { loading, status, error } = useGetMetricsHook();
  const { handleLogout } = useLogoutHook();
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { message: networkMsg } = fetchFunc.network.post;
  const { message: ipv4Msg } = fetchFunc.ip.post;
  const { message: sshKeyMsg } = fetchFunc.sshKey.post;
  const cleanState = useCleanState();

  //style
  const { overviewGridTemplateClass } = classes;

  //hooks
  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | ${GLOSSARY.overview}`;
  }, []);

  useEffect(() => {
    return () => {
      cleanState('network', 'post');
      cleanState('ip', 'post');
      cleanState('sshKey', 'post');
    };
  }, [cleanState]);


  if (status === 403) {
    handleLogout();
    return <MessageFeature status='error' msg={error} />;
  };

  return (
    <>
      <PageHeaderFeature
        headerTitle={GLOSSARY.descriptions.welcome_to_our_taiga}
        isShowBtn={false}
      >
        <TypographyFeature
          text={GLOSSARY.descriptions.lets_get_start}
          fontWeight='medium'
          color='customColorDarkBlue60'
          fontSize='xxxs'
        />
      </PageHeaderFeature>
      <div key={selectedTenantId}>
        <div className='flex row items-center justify-center'>
          {loading ?
            <SpinnerFeature />
            : null
          }
        </div>
        {(!error && !loading) &&
          <>
            {(instances > 0 || networks > 0 || ipv4s > 0 || ssh_keys > 0) ?
              <div className={`${overviewGridTemplateClass}`}>
                <InstancesFeature />
                <Ipv4sFeature />
                <NetworksFeature />
                <DocsFeature />
                <SshKeyFeature />
              </div>
              :
              <OverviewPageEmptyStateFeature />
            }
          </>
        }
      </div>
      <div className='absolute bottom-0 right-0 mr-8 my-4'>
        <IconGtcLogo text='blue' />
      </div>
      {networkMsg &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
      {ipv4Msg &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
      {sshKeyMsg &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};

export { Overview as OverviewPage };
