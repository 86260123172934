import { InterfaceLocation } from './data-types';

import { NorwayFlagSvg, SwedenFlagSvg } from '../assets';
// import { NorwayFlagSvg, PortugalFlagSvg, SwedenFlagSvg, UkFlagSvg } from '../assets';

export const INSTANCE_LOCATIONS: InterfaceLocation[] = [
  {
    id: 0,
    title: 'Norway',
    icon: NorwayFlagSvg,
    testDataId: 'norway_location',
    selected: true,
    clusters: [
      {
        uuid: '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5',
        name: 'ndcloud-underground',
      },
    ],
  },
  {
    id: 1,
    title: 'Sweden',
    icon: SwedenFlagSvg,
    testDataId: 'sweden_location',
    selected: false,
    clusters: [
      {
        uuid: 'bb7b0a68-265f-4734-a4ea-89911b2ddff7',
        name: 'ndcloud-arcticcircle',
      },
    ],
  },
  // {
  //   id: 2,
  //   title: 'United Kingdom',
  //   icon: UkFlagSvg,
  //   testDataId: 'uk_location',
  //   selected: false,
  //   clusters: [
  //     {
  //       uuid: '',
  //       name: '',
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   title: 'Portugal',
  //   icon: PortugalFlagSvg,
  //   testDataId: 'portugal_location',
  //   selected: false,
  //   clusters: [
  //     {
  //       uuid: '',
  //       name: '',
  //     },
  //   ],
  // },
];
