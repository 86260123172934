import { useDashboard } from 'context/dashboard';
import { DATA_TEST_IDS_OVERVIEW_PAGE, GLOSSARY } from 'global-shared/model';
import { useGoToModalFormPageHook } from 'global-shared/hooks';
import { ButtonFeature } from 'global-shared/features';

import { SectionsContentFeature } from '../shared';
import { InterfaceChartPieListItem } from '../shared/dataTypes';

/**
 * @since 1.2.0
 * @description The component returns a pie chart with
 * the total number of networks,
 * as well as an error or an empty state
 * @version 0.0.2
 * @package pages/overview/features
 * @example <NetworksFeature />
 * @returns React Element | SectionsContentFeature
 */
export function Networks(){
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc: { networks: { get: { data, error, loading, status } } } } = stateDashboard;
  const totalCount = data?.length;
  const customData: InterfaceChartPieListItem[] = [
    {
      name: 'all',
      value: totalCount,
      color: '#745BE6',
    },
  ];
  const { handleOpenCreateForm } = useGoToModalFormPageHook();

  return (
    <SectionsContentFeature
      heading={GLOSSARY.networks_plural}
      error={error}
      loading={loading}
      status={status}
      customData={customData}
      totalCount={totalCount}
      dataTestId={DATA_TEST_IDS_OVERVIEW_PAGE.TOTAL_NETWORKS_NUMBER}
    >
      <ButtonFeature
        title={`${GLOSSARY.actions.create} ${GLOSSARY.network}`}
        uiStyle='primary'
        customStyle='self-end min-w-[155px]'
        size='small'
        onClick={() => handleOpenCreateForm('networks')}
        dataTestId={DATA_TEST_IDS_OVERVIEW_PAGE.CREATE_NEW_NETWORK_BTN}
      />
    </SectionsContentFeature>
  );
};

export { Networks as NetworksFeature };
