import React, { useEffect } from 'react';

import { useDashboard } from 'context/dashboard';
import { TDataTestIdsNewVmMainAuthentication } from 'data-test-ids/types/new-vm';
import { useCleanState } from 'hooks/useCleanState';
import { useGetSshKeys } from 'hooks/useGetSshKeys';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';
import { GLOSSARY } from 'global-shared/model';
import { MessageFeature, useTenants } from 'global-shared/features';

import AddKeysSection from './AddKeysSection';
import Content from './Content';

import TitleSection from '../TitleSection';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 */
const Authentication = ({dataTestIds}: { dataTestIds: TDataTestIdsNewVmMainAuthentication }) => {
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = stateDashboard;
  const { error } = fetchFunc.sshKeys.get;
  const { useValue, useDispatch, setInstance, setInstanceValidation } = NewInstanceFormContext;
  const dispatchInstance = useDispatch();
  const { instance } = useValue();
  const cleanState = useCleanState();

  // hooks
  useGetSshKeys();

  useEffect(() => {
    return () => {
      cleanState('sshKey', 'post');
    };
  }, [cleanState]);

  useEffect(() => {
    if (selectedTenantId) {
      dispatchInstance(setInstance([], 'sshKeys'));
      dispatchInstance(setInstanceValidation(undefined, 'isSshKey'));
    }
  }, [selectedTenantId, dispatchInstance, setInstance, setInstanceValidation]);

  return (
    <div
      className='col-span-6 mt-5'
      data-testid={dataTestIds.wrapper.name}
    >
      <TitleSection
        title={GLOSSARY.authentication}
        dataTestId={dataTestIds.wrapper.title}
      />
      <Content />
      {instance.sshKeys.length < 6 ?
        <p
          data-testid={dataTestIds.wrapper.note}
          className='flex items-start text-black text-xxxs font-normal mt-5px ml-4'
        >
          You can only choose 6 {GLOSSARY.ssh_key}
        </p>
        :
        <p
          data-testid={dataTestIds.wrapper.note}
          className='flex items-start text-customDarkRed text-xxxs font-normal mt-5px ml-4'
        >
          You have already selected the maximum number of {GLOSSARY.ssh_keys_plural}
        </p>
      }
      <AddKeysSection
        dataTestIds={dataTestIds.wrapper.addKey}
      />
      {error &&
        <MessageFeature
          status='error'
          msg={error}
          dataTestId={dataTestIds.wrapper.error}
        />
      }
    </div>
  );
};

export default Authentication;
