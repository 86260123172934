function NorwayFlag() {
  return(
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' style={{ width: '1rem', height: '1rem', margin: '0.5rem' }} xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3_7)'>
        <path d='M16 0H0V16H16V0Z' fill='#BA0C2F'/>
        <path d='M0 8H16ZM5.81818 0V16Z' fill='black'/>
        <path d='M0 8H16M5.81818 0V16' stroke='white' strokeWidth='4'/>
        <path d='M0 8H16ZM5.81818 0V16Z' fill='black'/>
        <path d='M0 8H16M5.81818 0V16' stroke='#0052b4' strokeWidth='2'/>
      </g>
      <defs>
        <clipPath id='clip0_3_7'>
          <path d='M0 8C0 3.58172 3.58172 0 8 0V0C12.4183 0 16 3.58172 16 8V8C16 12.4183 12.4183 16 8 16V16C3.58172 16 0 12.4183 0 8V8Z' fill='white'/>
        </clipPath>
      </defs>
    </svg>
  );
};

export { NorwayFlag as NorwayFlagSvg };
