import React from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

import MenuComponent from 'components/menu';
import { useDashboard } from 'context/dashboard';
import Button from 'components/buttons';
import ActionItem from 'feature/actions/ActionItem';
import { InstanceStatusFeature } from 'global-shared/features';
import Typography from 'components/typography';
import { TDataTestIdsOverviewPageContentMainHeaderVmPage } from 'data-test-ids/types/overview-page';
import { useGoToModalFormPageHook } from 'global-shared/hooks';

import { getCorrectListOfActions } from '../../helpers/getCorrectListOfActions';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMainHeaderVmPage,
};

/**
 *
 * @param props dataTestIds
 * @returns
 */
export default function Subheader(props: TProps) {
  // props
  const {dataTestIds} = props;

  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { name, status, uuid } = fetchFunc.instance.get.data;
  const vmStatus = status === 'DELETED' || status === 'DELETION_REQUESTED';
  const resetStatus = status === 'RESET_REQUESTED' || status === 'REBOOT_REQUESTED' || status === 'REQUESTED';
  const instanceType = localStorage.getItem('selectedInstanceType');
  const { handleOpenCreateForm } = useGoToModalFormPageHook();
  const getType = fetchFunc.instance.get.data?.mtype ? fetchFunc.instance.get.data?.mtype : instanceType;

  return (
    <>
      <div>
        <Typography
          text={name}
          truncate='truncate'
          width={660}
          dataTestId={dataTestIds.name}
        />
        <InstanceStatusFeature
          status={status}
          fontSize='xxs'
          fontWeight='medium'
        />
      </div>
      {(getType === 'baremetal') ?
        null :
        <>
          <div className='row items-center'>
            {status === 'VM_ACTIVE' &&
              <Button
                type='button'
                customStyle='mr-2'
                handleClick={() => handleOpenCreateForm('demo')}
                title='Open Console'
                uiStyle='primary'
                dataTestId={dataTestIds.btnOpenTerminal}
              >
                <ArrowUpRightIcon
                  className='iconClass'
                  aria-hidden='true'
                />
              </Button>
            }
            {(!vmStatus && !resetStatus) &&
              <>
                <ActionItem
                  uuid={uuid}
                  url=''
                  route='vm/vm'
                  page='instance'
                  fetchMethod='post'
                  actionName='RESET_REQUESTED'
                  itemName={name}
                  typeAction='button-action'
                  dataTestIds={dataTestIds.tooltips}
                />
                <ActionItem
                  uuid={uuid}
                  url=''
                  route='vm/vm'
                  page='instance'
                  fetchMethod='post'
                  actionName='REBOOT_REQUESTED'
                  itemName={name}
                  typeAction='button-action'
                  dataTestIds={dataTestIds.tooltips}
                />
              </>
            }
            <MenuComponent
              uuid={uuid}
              lastElement={false}
              hideLeftMargin
              actions={getCorrectListOfActions(status, 'vm')}
              url='vm/vm'
              route=''
              page='instance'
              fetchMethod='post'
              itemName={name}
            />
          </div>
        </>
      }
    </>
  );
};
