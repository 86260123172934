import { useTenants } from 'global-shared/features';

/**
 * @deprecated
 * @since 1.1.6
 * @description The function returns the appropriate route for navigation
 * use useGetPageRoutesHook()
 * @version 0.0.2
 * @package hooks
 * @example
 *   const { handlePagePaths } = useGetRoutesPagePath();
 * @returns an object with a function
 */
export const useGetRoutesPagePath = () => {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;

  /**
   * @deprecated
   * @param id instanceId | networkId | ipv4Id => to get details page for one element
   * @description Use useGetPageRoutes instead of handlePagePaths
   * @returns an object with routes
   */
  const handlePagePaths = (id?: string) => {
    const pagePaths = {
      instance: `/${selectedTenantId}/instances/${id}`,
      instances: `/${selectedTenantId}/instances`,
      newInstance: `/${selectedTenantId}/instances/new`,
      network: `/${selectedTenantId}/networks/${id}`,
      networks: `/${selectedTenantId}/networks`,
      newNetwork: `/${selectedTenantId}/networks/new`,
      sshKeys: `/${selectedTenantId}/sshkeys`,
      newSshKey: `/${selectedTenantId}/sshkeys/new`,
      ipv4: `/${selectedTenantId}/ipv4s/${id}`,
      ipv4s: `/${selectedTenantId}/ipv4s`,
      newIpv4: `/${selectedTenantId}/ipv4s/new`,
      profile: `/${selectedTenantId}/profile`,
    };

    return pagePaths;
  };

  return { handlePagePaths };
};
