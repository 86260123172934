import { Link } from 'react-router-dom';

import { TypographyFeature } from 'global-shared/features';
import { APP_ROUTES_PAGES_PATHS } from 'global-shared/model';

export function InternalServerError({code}: {code: number | string}) {
  return (
    <div className='col all-items-center mt-[6.25rem]'>
      <TypographyFeature
        text={`${code} Internal Server Error`}
        fontSize='xxxl'
      />
      <TypographyFeature
        text='We apologize for the inconvenience, but we encountered server issues while trying to process your request. Please try again later.'
        fontSize='s'
      />
      <TypographyFeature
        text='In the meantime, here is what you can do:'
        fontSize='s'
      />
      <ul className='list-disc m-5 text-s'>
        <li>
          Refresh the page
        </li>
        <li>
          Try again in 30 minutes.
        </li>
        <li>
          Write us on the <a
            rel='noreferrer noopener'
            target='_blank'
            href='https://northerndata.atlassian.net/servicedesk/customer/portal/12'
            className='underline underline-offset-8'
          >
          support page
          </a> and tell us what happened
        </li>
      </ul>
      <Link
        to={APP_ROUTES_PAGES_PATHS.home}
        className='border-customColorLightGray bg-customGray4 px-6 py-4 rounded-md m-8'
      >
        <TypographyFeature
          text='Go to Home'
          fontSize='s'
          fontWeight='thin'
        />
      </Link>
    </div>
  );
};
