import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import PageLayout from 'layouts/pages';
import { useCleanState } from 'hooks/useCleanState';
import VirtualMachinesActions from 'feature/instances/VirtualMachinesActions';
import { setSelectedInstanceType } from 'actions/dashboard';
import InstancesList from 'feature/instances';
import {
  useGetPageRoutesHook,
  useMakeGetRequestVmsImmediatelyHook,
  useMakeGetRequestVmsByClickHook,
  useMakeGetRequestBareMetalByClickHook,
} from 'global-shared/hooks';
import { DATA_TEST_ID_INSTANCES_PAGE, GLOSSARY } from 'global-shared/model';
import { ButtonFeature, PageHeaderFeature, PaginationFeature } from 'global-shared/features';

/**
 * @since 1.0.0
 * @description A component that instantly receives data about all instances and
 * shows a whole page with a header, bulk operations, and has 2 active tabs that sort instances by type (virtual machines and bare metal)
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=5036-1170&node-type=section&t=mBGYBVjux5veRJX9-0}
 * @version 0.0.3
 * @package pages/instances/list
 * @example <ListInstancesPage />
 * @returns React Fragment
*/
function ListInstances() {
  // variables
  const history = useHistory();
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { selectedInstanceType } = state;
  const cleanState = useCleanState();
  const { getNewPageRoute } = useGetPageRoutesHook();
  const { handleGetVms } = useMakeGetRequestVmsByClickHook();
  const { handleGetBareMatel } = useMakeGetRequestBareMetalByClickHook();
  const page = selectedInstanceType; //'instances';

  // hooks
  useMakeGetRequestVmsImmediatelyHook();

  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | ${GLOSSARY.instances_plural}`;
    localStorage.setItem('selectedInstanceType', selectedInstanceType);
  }, [selectedInstanceType]);

  // func
  const handleOpenCreateVm = (): void => {
    cleanState('instance', 'post');
    history.push(getNewPageRoute('instances'));
  };

  const handleSelectVms = () => {
    handleGetVms();
    dispatch(setSelectedInstanceType('virtualmachine'));
  };

  const handleSelectBm = () => {
    handleGetBareMatel();
    dispatch(setSelectedInstanceType('baremetal'));
  };

  return (
    <>
      {(selectedInstanceType === 'virtualmachine') &&
        <VirtualMachinesActions />
      }
      <PageLayout
        page={page}
        emptyTextPlaceholder={GLOSSARY.instances_plural}
        renderSuccess={
          <>
            <PageHeaderFeature
              headerTitle={GLOSSARY.instances_plural}
              btnTitle={`${GLOSSARY.actions.create} ${GLOSSARY.instance}`}
              onClick={handleOpenCreateVm}
            >
              <div className='row my-4 gap-1'>
                <ButtonFeature
                  title={GLOSSARY.virtual_machines_plural}
                  uiStyle='tab'
                  customStyle={selectedInstanceType === 'virtualmachine' ? 'border border-customColorLightGreen' : '' }
                  onClick={handleSelectVms}
                  dataTestId={DATA_TEST_ID_INSTANCES_PAGE.VM_TAB}
                />
                <ButtonFeature
                  title={GLOSSARY.bare_metal}
                  uiStyle='tab'
                  customStyle={selectedInstanceType === 'baremetal' ? 'border border-customColorLightGreen' : '' }
                  onClick={handleSelectBm}
                  dataTestId={DATA_TEST_ID_INSTANCES_PAGE.BM_TAB}
                />
              </div>
            </PageHeaderFeature>
            <InstancesList />
          </>
        }
      />
      <PaginationFeature page={selectedInstanceType} />
    </>
  );
};

export { ListInstances as ListInstancesPage };
