import React, { useEffect } from 'react';

import { useDashboard } from 'context/dashboard';
import useSelectedItems from 'feature/bulk-operations/delete/useSelectedItems';
import { IIpv4 } from 'types';
import useSelectedOrDeselectedAllRemovingIps from 'feature/ipv4s/useSelectedOrDeselectedAllRemovingIps';
import { setPreselectedRemovingItems } from 'actions/dashboard';
import { TEST_ID_IPS_PAGE_SELECT_ALL_IPS_BTN } from 'data-test-ids/data-testid-variables';

import IPv4TableComponent from './IPv4TableComponent';

/**
 * @description This is a list of all the ipv4s in the table
 * @example <IPv4List />
 * @returns React Element - Table
 */
export default function IPv4List() {
  //variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc } = state;
  const { data: ipv4s }= fetchFunc.ips.get;
  const dataWithCustomValues: IIpv4[] = ipv4s && ipv4s.map(
    (item: IIpv4) => (
      {
        ...item,
        isSelected: item.isSelected || false,
        loading: false,
        status: 0,
        message: '',
        error: '',
        isDeleted: false,
        timeoutShow: 100,
        timeoutClose: 500,
        id: item.uuid,
      }
    ),
  );
  const handleSelectItems = useSelectedItems(dataWithCustomValues, 'ips');
  const allNotAttachedIps = dataWithCustomValues.filter((ip: IIpv4) => !ip.vm_uuid);
  const isAllSelected = allNotAttachedIps.length !== 0 ? allNotAttachedIps.every((ip: IIpv4) => ip.isSelected) : false;
  const handleSelectedAllItems = useSelectedOrDeselectedAllRemovingIps(true);
  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingIps(false);
  const isShowCheckbox = ipv4s.length === 0 ? false : true;

  //hooks
  useEffect(() => {
    return () => {
      dispatch(setPreselectedRemovingItems('ips', 'uuid', []));
      dispatch(setPreselectedRemovingItems('ips', 'name', []));
    };
  }, [dispatch]);

  return (
    <IPv4TableComponent
      isAllSelected={isAllSelected}
      handleDeselectItems={handleDeselectedAllItems}
      handleSelectedAllItems={handleSelectedAllItems}
      data={dataWithCustomValues}
      handleSelectItems={handleSelectItems}
      isShowCheckbox={isShowCheckbox}
      dataTestId={TEST_ID_IPS_PAGE_SELECT_ALL_IPS_BTN} //'select_all'
    />
  );
};
