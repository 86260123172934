import { UUID } from 'crypto';

import { useParams } from 'react-router-dom';

import { TypeWebPage } from 'global-shared/model/dataTypes';
import { PortalModalFeature } from 'global-shared/features';

import { DemoPage, Ipv4sPages, NetworksPage, SshKeyPage } from '..';

/**
 * @description
 */
function ModalForm() {
  //variables
  const { page } = useParams<{tenantId: UUID, page: TypeWebPage | 'demo', action: 'new'}>();

  //func
  const showContext = () => {
    switch (page) {
    case 'ipv4s':
      return <Ipv4sPages.NewIpv4Page />;
    case 'networks':
      return <NetworksPage.NewNetworkPage />;
    case 'sshKeys':
      return <SshKeyPage.NewSshKeyPage />;
    case 'demo':
      return <DemoPage />;
    default:
      break;
    }
  };

  return(
    <PortalModalFeature.ModalFeature
      modalType='form'
    >
      {showContext()}
    </PortalModalFeature.ModalFeature>
  );
};

export { ModalForm as ModalFormPage };
