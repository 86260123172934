import { createContext, useContext } from 'react';

import {
  TTenantsAction,
  TTenantsReducer,
  TFetchFuncStatus,
  TTenant,
} from 'types';

// action types
const SET_TENANTS = 'TENANTS.SET_TENANTS';
const SET_SELECTED_TENANT_ID = 'TENANTS.SET_SELECTED_TENANT_ID';
const SET_PRESELECTED_TENANT = 'TENANTS.SET_PRESELECTED_TENANT';

function setPreselectedTenant(preselectedTenant: TTenant) {
  return {
    type: SET_PRESELECTED_TENANT,
    preselectedTenant,
  };
};

function setSelectedTenantId(selectedTenantId: string) {
  return {
    type: SET_SELECTED_TENANT_ID,
    selectedTenantId,
  };
};

function setTenants(
  tenants: string | number | boolean | TTenant[],
  tenantKey: TFetchFuncStatus,
) {
  return {
    type: SET_TENANTS,
    tenants,
    tenantKey,
  };
};

// variables
const selectedTenantIdStorage = sessionStorage.getItem('selectedTenantIdStorage') || '';
const tenantsStorageParse = sessionStorage.getItem('tenantsStorage') || '';
const tenantsStorage = (tenantsStorageParse && JSON.parse(tenantsStorageParse)) || [];
const selectedTenantStorage = tenantsStorage.map((t: { uuid: string; }) => t.uuid === selectedTenantIdStorage);

const initialState: TTenantsReducer = {
  tenants: {
    error: '',
    status: 0,
    loading: false,
    message: '',
    data: tenantsStorage || [],
  },
  selectedTenantId: selectedTenantIdStorage || '',
  preselectedTenant: selectedTenantStorage || {
    customer_name: '',
    uuid: '',
    username: '',
  },
};

/**
 * @description Tenants reducer function
 * @param state
 * @param action
 * @returns
 */
function reducer(
  state: TTenantsReducer,
  action: TTenantsAction,
): TTenantsReducer {
  switch (action.type) {
  case SET_TENANTS:
    return {
      ...state,
      tenants: {
        ...state.tenants,
        [action.tenantKey]: action.tenants,
      },
    };
  case SET_SELECTED_TENANT_ID:
    return {
      ...state,
      selectedTenantId: action.selectedTenantId,
    };
  case SET_PRESELECTED_TENANT:
    return {
      ...state,
      preselectedTenant: action.preselectedTenant,
    };
  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  };
};

const context =
  createContext<[TTenantsReducer, (object: any) => void]>([initialState, () => {}]);
const useTenants = () => useContext(context);

export {
  setPreselectedTenant,
  setSelectedTenantId,
  setTenants,
  useTenants,
  context as TenantsContext,
  initialState as initialStateTenants,
  reducer as reducerTenants,
};
