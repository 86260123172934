import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import classes from './index.module.scss';

/**
 * @description a component that returns a black action section at the bottom
 * @param props
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=7136-2362&t=9gMG6WzGSOIEAtmS-4}
 * @example
 * <ModalBulkOperationsFeature>
      <TypographyFeature
        text={`${howManyItemsSelected} items selected`}
        fontSize='xs'
        fontWeight='light'
        color='white'
        customStyle='mr-10'
      />
    </ModalBulkOperationsFeature>
 * @returns React Portal
 */
var PortalModalBulkOperations = ({children}: {children: ReactNode}) => {
  return (
    <>
      {createPortal(
        <div className={classes.portalModalBgClass}>
          <div
            data-testid='test_id_bulk_operations_section'
            className={classes.portalModalBoxClass}
          >
            {children}
          </div>
        </div>,
        document.body,
      )}
    </>
  );
};

export { PortalModalBulkOperations as ModalBulkOperationsFeature };
