import React from 'react';

import { useDashboard } from 'context/dashboard';
import PortalNotification from 'portals/Notification';

import {
  ConfirmationDialogFeature,
  CreatingInstanceModalFeature,
  InstanceFormFeature,
  SuccessfullyCreatedInstanceModalFeature,
  NewInstanceFormContextProvider,
} from './features';

/**
 * @since 1.0.0
 * @description A component that returns a form to create a new instance,
 * and makes the necessary api requests to get additional values for the form
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=5036-1182&node-type=section&t=mBGYBVjux5veRJX9-0}
 * @version 0.0.4
 * @package pages/instances/new
 * @example <NewInstancePage />
 * @returns React Fragment
*/
function NewInstance() {
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;

  return (
    <>
      <NewInstanceFormContextProvider>
        <InstanceFormFeature />
      </NewInstanceFormContextProvider>
      {fetchFunc.instance.post.message === '' && <ConfirmationDialogFeature />}
      {fetchFunc.instance.post.loading && <CreatingInstanceModalFeature />}
      {fetchFunc.instance.post.message && <SuccessfullyCreatedInstanceModalFeature />}
      {fetchFunc.network.post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
      {fetchFunc.sshKey.post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};

export { NewInstance as NewInstancePage };
