import React, { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import {
  GLOSSARY,
  DATA_TEST_ID_PROFILE_PAGE,
  TypeUuidSelect,
  IOptionSelect,
  API_URLS,
} from 'global-shared/model';
import {
  ButtonFeature,
  MessageFeature,
  SpinnerFeature,
  TypographyFeature,
  CopyBtnFeature,
  SelectFeature,
  useTenants,
} from 'global-shared/features';

/**
 * @description Uuids section
*/
function UuidsSection() {
  // state
  const [selectedUuidTitle, setSelectedUuidTitle] = useState<string>('Tenants');
  const [error, setError] = useState<string>('');
  const [isPending, setIsPending] = useState<boolean>(false);
  const [response, setResponse] = useState<any>([]);

  // variables
  const contextTenants = useTenants();
  const state = contextTenants[0];
  const { tenants, selectedTenantId } = state;
  const isApi = process.env.REACT_APP_API_URL;
  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;
  const uuids: TypeUuidSelect[] = [
    {
      uuid: '0',
      type: 'uuids',
      title: GLOSSARY.images_plural,
    },
    {
      uuid: '1',
      type: 'uuids',
      title: GLOSSARY.flavors_plural,
    },
    {
      uuid: '2',
      type: 'uuids',
      title: GLOSSARY.tenants_plural,
    },
    {
      uuid: '3',
      type: 'uuids',
      title: GLOSSARY.clusters_plural,
    },
  ];

  // func
  const handleGetData = async(api: string) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const url = `${isApi}/${api}?tenantUUID=${selectedTenantId}`;

    if(selectedTenantId) {
      setIsPending(true);
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json',
      };
      const options = { headers, method: 'GET', signal };

      try {
        const response = await fetch(url, options);
        const responseJson = await response.json();
        setError('');
        setResponse(responseJson);
      } catch (error) {
        if (error instanceof Error) {
          setError(error?.message || '');
        }
      }

      setIsPending(false);
    }

    return () => {
      controller.abort();
    };
  };

  // func
  const handleSelect = (element: IOptionSelect) => {
    setSelectedUuidTitle(element.name);

    if (element.name === 'Images') {
      return handleGetData(API_URLS.images);
    }

    if (element.name === 'Flavors') {
      return handleGetData(API_URLS.flavors);
    }

    if (element.name === 'Clusters') {
      return handleGetData(API_URLS.clusters);
    }

    return null;
  };

  const returnUuids = () => {
    if (selectedUuidTitle === 'Tenants') return [JSON.stringify(tenants.data, null, 2), tenants.data];
    return [JSON.stringify(response, null, 2), response];
  };

  const handleTextFile = () => {
    const element = document.createElement('a');
    //pass data from localStorage API to blob
    const textFile = new Blob([JSON.stringify(returnUuids()[1])], {type: 'application/json'});
    element.href = URL.createObjectURL(textFile);
    element.download = `${selectedUuidTitle}_UUID.json`;
    document.body.appendChild(element);
    element.click();
  };

  const renderContent = () => {
    if (isPending){
      return <SpinnerFeature />;
    };

    if (error && !isPending) {
      return (
        <MessageFeature
          status='error'
          msg={error}
        />
      );
    };

    return (
      <>
        <div className='overflow-x-auto max-w-[45rem]'>
          <TypographyFeature
            text={returnUuids()[0]}
            fontSize='s'
            fontWeight='normal'
            color='customColorDarkBlue80'
          />
        </div>
        <div className='absolute top-10 right-10'>
          <CopyBtnFeature
            value={returnUuids()[0]}
            dataTestId='test_id_selected_uuid'
          />
        </div>
      </>
    );
  };

  return (
    <div>
      <TypographyFeature
        text='UUIDs'
        customStyle='pb-1'
        fontSize='sm'
      />
      <hr />
      <div className='pt-8 pb-14'>
        <TypographyFeature
          text={GLOSSARY.descriptions.uuids}
          customStyle='mb-4 ml-2'
          fontSize='s'
          color='customColorDarkBlue80'
          fontWeight='normal'
        />
        <div className='row justify-between mb-8'>
          <SelectFeature
            name='uuids'
            value={selectedUuidTitle}
            options={uuids}
            dataTestId={DATA_TEST_ID_PROFILE_PAGE.UUIDS_DROP_DOWN}
            isDisabled={false}
            isError={false}
            onSelect={handleSelect}
          />
        </div>
        <div className='row'>
          <pre className='flex-1 p-10 border rounded-md bg-slate-50 relative'>
            {renderContent()}
          </pre>
          <ButtonFeature
            uiStyle='primary'
            onClick={handleTextFile}
            title='Download'
            customStyle='ml-8 min-w-[12.75rem]'
            dataTestId={DATA_TEST_ID_PROFILE_PAGE.DOWNLOAD_BTN}
          />
        </div>
      </div>
    </div>
  );
};

export { UuidsSection as Uuids };
