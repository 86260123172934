import { InterfacePagePaths } from './dataTypes';

/**
 * @since 1.3.0
 * @description object with all the app routes
 * @version 0.0.1
 * @package global-shared/model
 * @example APP_ROUTES_PAGES_PATHS
 * @returns objects
 */
export const APP_ROUTES_PAGES_PATHS: InterfacePagePaths = {
  home: '/',
  tenants: '/tenants',
  maintenance: '/maintenance',
  login: '/login',
  loginCallback: '/login/callback',
  notFound: '*',
  profile: '/:tenantId/profile',
  instance: '/:tenantId/instances/:instanceId',
  instances: '/:tenantId/instances',
  newInstance: '/:tenantId/instances/new',
  pdpInstance: '/:tenantId/pdp/:pdpId/:instanceId',
  network: '/:tenantId/networks/:networkId',
  networks: '/:tenantId/networks',
  sshKeys: '/:tenantId/sshKeys',
  ipv4: '/:tenantId/ipv4s/:ipv4Id',
  ipv4s: '/:tenantId/ipv4s',
  modalForm: '/:tenantId/modalForm/:page/:action',
};
