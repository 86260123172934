import React, { useEffect } from 'react';

import { GLOSSARY } from 'global-shared/model';

import {  MessageFeature, SpinnerFeature } from 'global-shared/features';

import { useMakeGetRequestLocationsHook } from './useMakeGetRequestLocations';
import classes from './index.module.scss';

import { INSTANCE_LOCATIONS, NewInstanceFormContext, InterfaceLocation } from '../../shared/model';
import { SectionTitleFeature } from '../../shared/features';

/**
 * @description a component that returns all locations based on instance type and clusters
 * @example <LocationsFeature />
 */
function Locations() {
  //variables
  const { useDispatch, setInstanceForm, setInstance, setInstanceValidation } = NewInstanceFormContext;
  const dispatch = useDispatch();
  const { itemClass, currentItemClass } = classes;
  const {
    request: {
      error,
      isPending,
      response: locations,
      onSetResponse: onSetLocations,
    },
  } = useMakeGetRequestLocationsHook();

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const findSelectedLocation = locations?.find(f => f.selected) || INSTANCE_LOCATIONS[0];
      dispatch(setInstanceForm(findSelectedLocation?.title, 'location'));
      dispatch(setInstance(findSelectedLocation?.clusters[0], 'cluster'));
      dispatch(setInstanceValidation(false, 'isCluster'));
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, locations, setInstanceForm, setInstanceValidation, setInstance]);

  //func
  const handleChange = (item: InterfaceLocation) => {
    dispatch(setInstanceForm(item.title, 'location'));
    dispatch(setInstance(item.clusters[0], 'cluster'));
    dispatch(setInstanceValidation(false, 'isCluster'));
    onSetLocations(
      locations.map((l) => {
        if (l.title === item.title) {
          return {
            ...l,
            selected: true,
          };
        } else {
          return {
            ...l,
            selected: false,
          };
        }
      }),
    );
  };

  const returnContent = () => {
    if (error) return <MessageFeature status='error' msg={error} />;
    if (isPending) return <SpinnerFeature />;
    return (
      <div className='row mt-8'>
        {locations?.map((item: InterfaceLocation) => {
          const { title, testDataId } = item;
          return (
            <div
              key={title}
              className={`${itemClass} ${item.selected ? currentItemClass  : ''} all-items-center`}
              onClick={() => handleChange(item)}
              data-testid={testDataId}
            >
              <item.icon />
              {title}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      id='LocationSection'
      className='col-span-6'
    >
      <SectionTitleFeature
        title={GLOSSARY.location}
        description={GLOSSARY.descriptions.locations}
      />
      {returnContent()}
    </div>
  );
};

export { Locations as LocationsFeature };
