import { useEffect } from 'react';

import { GLOSSARY } from 'global-shared/model';
import CreateNewIpv4ModalForm from 'components/forms/create-ipv4';

function NewIpv4(){
  //hooks
  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | New ${GLOSSARY.ipv4}`;
  }, []);

  return <CreateNewIpv4ModalForm />;
};

export { NewIpv4 as NewIpv4Page };
