import { UUID } from 'crypto';

import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import PageLayout from 'layouts/pages';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import { dataTestIdsNetwork } from 'data-test-ids';
import { API_URLS, GLOSSARY } from 'global-shared/model';
import { InterfaceNetwork } from 'global-shared/model/dataTypes';
import { useGetPageRoutesHook } from 'global-shared/hooks';
import HeaderWithBreadcrumbs from 'components/HeaderWithBreadcrumbs';
import { PDPAttachedSectionFeature, PDPInfoSectionFeature, TypographyFeature } from 'global-shared/features';

import Header from './header';

/**
 * @description a component is a detailed page of a single network,
 * sets the title in the browser tab, makes a request to the API
*/
var NetworkDetail = () => {
  // variables
  const contextDashboard = useDashboard();
  const { networkId, tenantId } = useParams<{networkId: UUID, tenantId: UUID}>();
  const state = contextDashboard[0];
  const url = `${API_URLS.network}/${networkId}`;
  const { fetchFunc } = state;
  const { data } = fetchFunc.network.get;
  const { getProductDetailPageRoute, getPageRoute } = useGetPageRoutesHook();
  const handleFetchRequest = useHandleFetchRequest();
  const location = useLocation();
  const items: {title: string, value: string}[] = [
    {
      title: 'Subnet',
      value: data.prefix6,
    },
    {
      title: GLOSSARY.cluster,
      value: data.cluster_uuid,
    },
    {
      title: GLOSSARY.uuid,
      value: data.uuid,
    },
    {
      title: 'Region',
      value: 'Europa',
    },
  ];

  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | ${GLOSSARY.network}`;
  }, [data]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (tenantId && networkId) {
      const getNetwork = api.useFetchData<[], InterfaceNetwork>(url, 'get', signal);
      handleFetchRequest('network', 'get', getNetwork);
    }
    return () => {
      controller.abort();
    };
  }, [url, networkId, handleFetchRequest, tenantId]);

  return (
    <PageLayout
      page='network'
      emptyTextPlaceholder={`${GLOSSARY.networks_plural} in your ${GLOSSARY.network}`}
      renderSuccess={
        <>
          <HeaderWithBreadcrumbs
            route={getPageRoute('networks')}
            routeName={GLOSSARY.networks_plural}
            dataTestIds={dataTestIdsNetwork.content.header}
          />
          <div
            data-testid={dataTestIdsNetwork.content.main.name}
            className={'lightGrayWrapperClass' }
          >
            <Header
              dataTestIds={dataTestIdsNetwork.content.main.header.networkPage}
            />
          </div>
          <PDPInfoSectionFeature dataTestIds={dataTestIdsNetwork.content.main.info} items={items}>
            <PDPAttachedSectionFeature
              isAttached={data.assigned_addresses6?.length === 0 ? false : true}
              dataTestIds={dataTestIdsNetwork.content.main.info.aside}
            >
              <div className='divide-y divide-customColorLightGray'>
                {data.assigned_addresses6?.map((vm: InterfaceAssignetInstances) => {
                  const instanceId = (vm?.uuid ? vm?.uuid : vm?.vm_uuid) || '';
                  const isInstanceName = (vm?.name ? vm?.name : vm?.vm_uuid) || '';
                  return (
                    <div
                      key={instanceId}
                      className='pl-6 py-4 underline'
                    >
                      <Link
                        key={instanceId}
                        data-testid={dataTestIdsNetwork}
                        to={{
                          pathname: getProductDetailPageRoute(networkId, instanceId as UUID),
                          state: { pdpInstancePage: location },
                        }}
                      >
                        <TypographyFeature
                          text={isInstanceName}
                          fontWeight='thin'
                          fontSize='s'
                          color='customColorDarkBlue80'
                          customStyle='cursor-pointer'
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </PDPAttachedSectionFeature>
          </PDPInfoSectionFeature>
        </>
      }
    />
  );
};

export { NetworkDetail as NetworkDetailPage };

interface InterfaceAssignetInstances {
  vm_uuid: string,
  name?: string,
  address6: string,
  uuid?: string,
};
