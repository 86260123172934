import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { DATA_TEST_ID_MODALS } from 'global-shared/model';

import classes from './index.module.scss';

/**
 * @description
 * @param props
 * @see bm-info {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=7440-4546&node-type=frame&t=JO528ZYk6b22Yufq-0 }
 * @see form {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=6577-4629&node-type=frame&t=GxfcK5ripFNYs4tw-0 }
 * @example
 * @returns React Portal
 */
function Modal(props: InterfaceProps) {
  // props
  const {
    modalType,
    children,
  } = props;

  const getWidthDependsOnModalType = () => {
    switch (modalType) {
    case 'form':
      return classes.portalModalFormWidth;

    case 'bm-info':
      return classes.portalModalBmInfoWidth;

    //you can find in the new instance form (info box: creating instance, successfully created)
    case 'info-box':
      return classes.portalModalBmInfoWidth;

    default:
      return classes.portalModalFormDefaultWidth;
    }
  };

  return (
    <>
      {createPortal(
        <div className={classes.portalModalBgClass}>
          <div
            data-testid={DATA_TEST_ID_MODALS.PORTAL_MODAL}
            className={`${classes.portalModalBoxClass} ${getWidthDependsOnModalType()}`}
          >
            {children}
          </div>
        </div>,
        document.body,
      )}
    </>
  );
}

export { Modal as ModalFeature };

interface InterfaceProps {
  /**
   * @default 'default'
   */
  modalType: 'info-box' | 'form' | 'bm-info' | 'default',
  children: ReactNode,
};
