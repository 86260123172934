import { ReactElement, useReducer } from 'react';

import { NewInstanceFormContext } from '../../shared/model';

const NewInstanceFormProvider = ({children}: {children: ReactElement}) => {
  //variables
  const { reducer, initialState, ValueContext, DispatchContext } = NewInstanceFormContext;

  //hooks
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ValueContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </ValueContext.Provider>
  );
};

export { NewInstanceFormProvider as NewInstanceFormContextProvider };
