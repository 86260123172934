import { IIpv4Api, INetworkApi } from 'global-shared/model';

/**
 * @description
 * @example useFilterItemsByCluster(data, 'uuid')
 * @returns array
 */
function useFilterItemsByCluster(items: INetworkApi[] | IIpv4Api[], clusterUUID: string) {
  // variables
  const theItems = items?.filter(
    (item) => item.cluster_uuid === clusterUUID);

  return theItems;
};

export { useFilterItemsByCluster as useFilterItemsByClusterHook };
