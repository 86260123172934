import { useDashboard } from 'context/dashboard';
import { TypographyFeature } from 'global-shared/features';
import { DATA_TEST_IDS_PAGINATION } from 'global-shared/model';

// import { NextPage } from './NextPage';
// import { PrevPage } from './PrevPage';
// import { Pages } from './Pages';

/**
 * @since 1.3.0
 * @description A component that is a ready-made pagination and
 * shows the total number of elements, element indices on the page,
 * buttons for navigating between pages
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=7137-3764&node-type=FRAME&t=EPoDSIG9fXZKB1AL-0}
 * @version 0.0.1
 * @example <PaginationFeature totalCount={20} />
 * @returns React Element
 */
function Pagination({page}: {page: 'virtualmachine' | 'baremetal' | 'ips' | 'networks' | 'sshKeys'}) {
  // variables
  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc[page].get;
  const totalCount = data?.length;

  const selectedIndexFirstItemPerPage = 1;
  // const selectedIndexLastItemPerPage = 5;
  // const countFilteredData = 35;
  // const activePage = 2;
  // const pagesList = [1, 2, 3, '...', 7];

  return (
    <>
      {totalCount !== 0 &&
      <div className='row gap-2 justify-between pl-6 pr-14 h-12 bg-customColorBlueGray mb-20 items-center'>
        <TypographyFeature
          text={`Showing ${selectedIndexFirstItemPerPage} to ${totalCount} of ${totalCount} items`}
          fontSize='xs'
          fontWeight='normal'
          color='customColorDarkBlue60'
          dataTestId={DATA_TEST_IDS_PAGINATION.TEXT}
        />
        {/* <div className='row gap-1 items-center justify-center'>
          <PrevPage
            totalCountPage={countFilteredData}
            activePage={activePage}
          />
          <Pages
            pagesList={pagesList}
            activePage={activePage}
          />
          <NextPage
            totalCountPage={countFilteredData}
            activePage={activePage}
            lastPage={7}
          />
        </div> */}
      </div>
      }
    </>
  );
};

export { Pagination as PaginationFeature };
