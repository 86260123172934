import { TypographyFeature } from '../typography';

/**
 * @deprecated
 * @since 1.2.1
 * @description use MessageFeature.
 * A component that returns one of the notification views
 * (error, success, warning, info) with the text. (a small notification)
 * @param props
 * @version 0.0.2
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=3445-2637&node-type=frame&t=X1mPCRIXMA7P6Ppr-0}
 * @package global-shared/features
 * @example <NotificationFeature status='error' msg={error} />;
 * @returns React Element | div
 */
function Notification({status, msg, dataTestId}: InterfaceProps) {
  // func
  const chooseUI = () => {
    switch (status) {
    case 'error':
      return ['text-red-600', 'bg-red-50 border border-red-300 p-2 m-1 '];

    case 'warning':
      return ['text-customDarkYellow', 'border-0 mb-1.5 absolute mt-[5px] ml-5'];

    case 'success':
      return ['text-green-600', 'bg-green-50 border border-green-300 p-2 m-1'];

    default:
      return ['text-cyan-600', 'bg-cyan-50 border border-cyan-300 p-2 m-1 '];
    }
  };

  return (
    <div
      data-testid={dataTestId}
      className={`${chooseUI()[1]} leading-relaxed rounded-md`}
    >
      <TypographyFeature
        text={msg}
        fontSize='s'
        fontWeight='medium'
        customStyle={chooseUI()[0]}
      />
    </div>
  );
};

export { Notification as NotificationFeature };

interface InterfaceProps {
  status: 'error' | 'info' | 'warning' | 'success',
  msg: string | number,
  dataTestId?: string,
};
