import React from 'react';

import Aside from 'components/aside';
import { GLOSSARY } from 'global-shared/model';
import { useGetPageRoutesHook } from 'global-shared/hooks';
import HeaderWithBreadcrumbs from 'components/HeaderWithBreadcrumbs';

import classes from './index.module.scss';

import { InstanceFormFieldsFeature, SummaryFeature } from '..';
import { NewInstanceFormContext } from '../../shared/model';

/**
 * @since 1.0.
 * @description A form for creating a new instance
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=5036-1182&node-type=section&t=mBGYBVjux5veRJX9-0}
 * @example <InstanceFormFeature />
 * @returns React Element
*/
function InstanceForm() {
  // variables
  const { useValue } = NewInstanceFormContext;
  const { form: { type } } = useValue();
  const { getPageRoute } = useGetPageRoutesHook();
  const instanceType = type === 'vm' ? GLOSSARY.virtual_machine : GLOSSARY.bare_metal;

  return (
    <div className={classes.newInstanceFormClass}>
      <>
        <div className={classes.headerFormClass}>
          <HeaderWithBreadcrumbs
            headerTitle={`Create ${GLOSSARY.instance}`}
            route={getPageRoute('instances')}
            routeName={`Back to ${GLOSSARY.instances_plural}`}
          />
        </div>
        <InstanceFormFieldsFeature />
      </>
      <Aside
        title={instanceType}
        customStyle={`${classes.asideSummaryClass}`}
      >
        <SummaryFeature />
      </Aside>
    </div>
  );
};

export { InstanceForm as InstanceFormFeature };
