import React from 'react';

import { TDataTestIdsOverviewPageContentMainInfoItems } from 'data-test-ids/types/overview-page';
import { TypographyFeature } from 'global-shared/features';
import { useShowElementForPDPHook } from 'global-shared/hooks';

/**
  * @description a component that shows an informational list of items for pdp (Network, Ipv4)
  * @param props
  * @example
  *   <PDPItemsFeature
        items={items}
        dataTestIds={dataTestIds.items}
      />
  * @returns React Element
*/
var PDPItems = ({items, dataTestIds}: {items: {title: string, value: string}[], dataTestIds: TDataTestIdsOverviewPageContentMainInfoItems}) => {
  // variables
  var { showRightTypeOfElement } = useShowElementForPDPHook();

  return (
    <div
      data-testid={dataTestIds.name}
      className='grid grid-cols-2 gap-y-10 gap-x-28'
    >
      {items.map((item: {title: string, value: string}) =>
        <div
          key={item.title}
          className='flex col'
          data-testid={dataTestIds.itemsSection.name}
        >
          <div className='mb-2'>
            <TypographyFeature
              text={item.title}
              fontWeight='medium'
              color='customColorDarkBlue80'
              fontSize='s'
              dataTestId={dataTestIds.itemsSection.title}
            />
          </div>
          {showRightTypeOfElement(item.title, item.value)}
        </div>,
      )}
    </div>
  );
};

export { PDPItems as PDPItemsFeature };
