const ndcloudArcticband = {
  // text: 'ndcloud-arcticband',
  color: '#f72585',
  bg: 'bg-[#f72585]',
};

const ndcloudArctictheory = {
  // text: 'ndcloud-arctictheory',
  color: '#ff8a5b',
  bg: 'bg-[#ff8a5b]',
};

const ndcloudHeimdall = {
  // text: 'ndcloud-heimdall',
  color: '#C280F6',
  bg: 'bg-[#C280F6]',
};

const ndcloudLoki = {
  // text: 'ndcloud-loki',
  color: '#8fc93a',
  bg: 'bg-[#8fc93a]',
};

const ndcloudOdin = {
  // text: 'ndcloud-odin',
  color: '#5BCCDF',
  bg: 'bg-[#5BCCDF]',
};

const ndcloudThor = {
  // text: 'ndcloud-thor',
  color: '#a51c30',
  bg: 'bg-[#a51c30]',
};

const ndcloudUnderground = {
  // text: 'ndcloud-underground',
  // text: 'attached',
  color: '#27A9BF',
  bg: 'bg-[#27A9BF]',
};

const ndcloudArcticcircle = {
  // text: 'ndcloud-arcticcircle',
  color: '#745BE6',
  bg: 'bg-[#745BE6]',
};

/**
 * @since 1.2.0
 * @description The function that returns colors
 * for chart sections on the overview page
 * @param name
 * @version 0.0.2
 * @package pages/overview/utils
 * @example getColorsChartListUtils(item.name).bg
 * @returns object
 */
function getColorsChartList(name: string): IOverviewSectionNamesColors {
  switch (name) {
  case 'ndcloud-arcticband':
    return ndcloudArcticband;

  case 'ndcloud-arctictheory':
    return ndcloudArctictheory;

  case 'ndcloud-heimdall':
    return ndcloudHeimdall;

  case 'ndcloud-loki':
    return ndcloudLoki;

  case 'ndcloud-odin':
    return ndcloudOdin;

  case 'ndcloud-thor':
    return ndcloudThor;

  case 'ndcloud-underground':
  case 'attached':
    return ndcloudUnderground;

  case 'ndcloud-arcticcircle':
  case 'unattached':
  case 'all':
    return ndcloudArcticcircle;

  default:
    return  {
      color: '#543fb6',
      bg: 'bg-[#543fb6]',
    };
  };
};

export { getColorsChartList as getColorsChartListUtils };

interface IOverviewSectionNamesColors {
  // text: string,
  color: string,
  bg: string,
};
