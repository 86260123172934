import { useDashboard } from 'context/dashboard';
import { useFilterItemsByClusterHook, useMakeGetRequestNetworksImmediatelyHook } from 'global-shared/hooks';
import { INetworkApi } from 'global-shared/model';

/**
 * @returns
 */
const useFilterNetworksByCluster = (clusterUUID: string) => {
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { data } = fetchFunc.networks.get;
  useMakeGetRequestNetworksImmediatelyHook();
  const networks = useFilterItemsByClusterHook(data, clusterUUID);

  return networks as INetworkApi[];
};

export { useFilterNetworksByCluster as useFilterNetworksByClusterHook };
