import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';

import CustomInput from 'components/input/Input';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';
import { GLOSSARY } from 'global-shared/model';
import { useTenants } from 'global-shared/features';

import classes from './instance.module.scss';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 */
const InstanceName = forwardRef<HTMLInputElement>((props, ref) => {
  // state
  const [isWarning, setIsWarning] = useState<boolean>(false);

  // variables
  const { useValue, useDispatch, setInstance, setInstanceValidation, setInstanceForm } = NewInstanceFormContext;
  const dispatch = useDispatch();
  const { instance: { name }, validation: { isName: validationName } } = useValue();
  const { warningClass } = classes ;
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const validationStyle = '';

  useEffect(() => {
    if(!name.trim()) {
      dispatch(setInstanceValidation(undefined, 'isName'));
      dispatch(setInstance('', 'name'));
      dispatch(setInstanceForm(GLOSSARY.notifications.errors.please_enter_name, 'error'));
    } else {
      dispatch(setInstanceValidation(false, 'isName'));
    }
  }, [name, dispatch, setInstance, setInstanceValidation, setInstanceForm]);

  useEffect(() => {
    if (selectedTenantId) {
      dispatch(setInstance('', 'name'));
      dispatch(setInstanceValidation(undefined, 'isName'));
    }
  }, [selectedTenantId, dispatch, setInstance, setInstanceValidation]);

  useEffect(() => {
    if (name.length === 64) {
      setIsWarning(true);
    } else {
      setIsWarning(false);
    }
  }, [name]);

  // func
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement> ) => {
    const value = event.target.value;
    if (value === ' ') {
      dispatch(setInstanceValidation(undefined, 'isName'));
      dispatch(setInstance('', 'name'));
      dispatch(setInstance(value, 'name'));
      return;
    }
    dispatch(setInstance(value, 'name'));
  }, [dispatch, setInstance, setInstanceValidation]);

  const showMsg = useCallback(() => {
    if (isWarning) return GLOSSARY.notifications.errors.character_limit_reached_64_;
    if (validationName) return GLOSSARY.notifications.errors.please_enter_name;
    return '';
  }, [isWarning, validationName]);

  return (
    <div
      id='Instance Description'
      className='min-w-[310px] relative'
    >
      <CustomInput
        inputFeedback={showMsg()}
        inputStatus={isWarning ? 'warning' : 'validation'}
        label='Name'
        type='text'
        maxLength={64}
        // showMaxLengthValue
        ref={ref}
        name='name-instance'
        value={name}
        handleChange={(event) => handleChange(event)}
        placeholder='Name'
        classNames={`${ isWarning ? warningClass : ''}${ validationName ? validationStyle : ''}`}
        required={validationName ? true : false}
        testDataId='vm_name_input_field'
      />
    </div>
  );
});

export default InstanceName;
