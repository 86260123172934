import { TypographyFeature } from 'global-shared/features';

const SectionTitle = (props: InterfaceProps) => {
  //props
  const { title, nameInput='', description='', dataTestId='' } = props;

  return (
    <div data-testid={dataTestId}>
      <TypographyFeature
        text={title}
        fontSize={nameInput ? 's' : 'base'}
        fontWeight='medium'
        customStyle={nameInput ? 'ml-[21px]' : ''}
      />
      {description &&
        <TypographyFeature
          text={description}
          fontSize='xxs'
          fontWeight='normal'
        />
      }
    </div>
  );
};

export { SectionTitle as SectionTitleFeature };

interface InterfaceProps {
  title: string,
  nameInput?: boolean,
  description?: string,
  dataTestId?: string,
};
