import React, { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import classes from './modal.module.scss';

type TProps = {
  children: ReactNode,
  open: boolean,
  onCancel: () => void,
  hideTopMargin?: boolean,
  dataTestId?: string,
  classess?: string,
  width?: string | 'w-[430px]' | 'w-full',
};

/**
 * @deprecated
 * @param props
 * @returns
 */
export default function ModalWhiteBg(props: TProps) {
  const { children, open, onCancel, hideTopMargin, dataTestId, classess, width = 'w-[430px]' } = props;
  const isTopMargin = hideTopMargin ? '' : 'mt-[170px]';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={onCancel} data-testid={dataTestId}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-[0.61] transition-opacity' />
          </Transition.Child>
          <div className='fixed inset-0 z-10'>
            <div className={`flex min-h-full items-end justify-center p-4 ${isTopMargin} sm:items-start sm:p-0`}>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className={`${classes.dialogPanelClass} ${width} ${classess}`}>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
