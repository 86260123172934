// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 * @returns
 */
export default function useRemoveIp() {
  //variables
  const { useValue, useDispatch, setInstance, setInstanceForm } = NewInstanceFormContext;
  const dispatch = useDispatch();
  const {
    form: {
      preselectedIpv4s,
    },
    instance: {
      ips: ipAddresses,
    },
  } = useValue();

  //func
  const handleRemoveIp = (address: string) => {
    const newArray = ipAddresses.filter(ip => ip.address4 !== address);
    dispatch(setInstance(newArray, 'ips'));
    const updatePreselectedIps = preselectedIpv4s.filter((ip: string) => ip !== address);
    dispatch(setInstanceForm(updatePreselectedIps, 'preselectedIpv4s'));
  };

  return handleRemoveIp;
};
