import React from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/solid';

import { useDashboard } from 'context/dashboard';
import IconBlinkerDot from 'assets/icons/IconBlinkerDot';

import classes from './index.module.scss';

export default function Maintenance() {

  const {
    statusMaintenanceClass,
    statusMaintenanceTextClass,
    statusMaintenanceTitleClass,
    blinkerDotBig,
  } = classes;

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { maintenance } = stateDashboard;

  const allMaintenance = maintenance && maintenance.maintenance;
  const oneMaintenance = allMaintenance && allMaintenance[0];
  const maintenanceFullTime = allMaintenance && allMaintenance[0] && allMaintenance[0]?.full_date_for_ui;

  return (
    <a
      href='https://status-cloudservices.northerndata.de'
      rel='noreferrer noopener'
      target='_blank'
      className={statusMaintenanceClass}
      key={oneMaintenance?.id}
    >
      <IconBlinkerDot
        blinkerDotBig={blinkerDotBig}
        customColor='#ECAC4D'
      />
      <div className='col ml-3'>
        <p className={statusMaintenanceTitleClass}>
          {oneMaintenance?.name}
        </p>
        <div className={statusMaintenanceTextClass}>
          <p className='text-[#C09350]'>
            {maintenanceFullTime}
          </p>
          <ArrowUpRightIcon
            className='w-2.5 ml-1.5'
            stroke='#C09350'
            strokeWidth='1.5'
            strokeLinecap='round'
          />
        </div>
      </div>
    </a>
  );
};
