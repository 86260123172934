import React, { useCallback, useEffect, useState } from 'react';

import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import { useDashboard } from 'context/dashboard';
import { TEST_ID_VMS_PAGE_START_NEW_VM_BTN } from 'data-test-ids/data-testid-variables';
import { ButtonFeature, MessageFeature, useTenants } from 'global-shared/features';
import { API_URLS, GLOSSARY } from 'global-shared/model';
import { IIpv4Api, InterfaceInstance } from 'global-shared/model/dataTypes';

import { ItemsFeature } from './features';

import { NewInstanceFormContext } from '../../shared/model';

/**
 * @since 1.0.0
 * @description A component that shows all the selected items for a new instance
 * @param props
 * @version 0.0.4
 * @package pages/instances/new
 * @example <Summary />
 * @returns React Element
 */
function Summary(){
  //state
  const [disabled , setDisabled] = useState<boolean>(false);

  //variables
  const { useValue, useDispatch, setInstanceValidation } = NewInstanceFormContext;
  const {
    form: { type },
    instance: {
      name,
      flavor,
      image,
      sshKeys,
      network,
      ips,
      cluster,
    },
    validation,
  } = useValue();
  const url = API_URLS.instances;
  const dispatch = useDispatch();
  const handleFetchRequest = useHandleFetchRequest();
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { error } = fetchFunc.instance.post;
  const contextTenants = useTenants();
  const state = contextTenants[0];
  const { selectedTenantId } = state;

  //func
  const handleValidation = () => {
    if (!name) {
      dispatch(setInstanceValidation(true, 'isName'));
    };
    if (!flavor.uuid) {
      dispatch(setInstanceValidation(true, 'isFlavor'));
    };
    if (!image.uuid) {
      dispatch(setInstanceValidation(true, 'isImage'));
    };
    if (sshKeys.length === 0) {
      dispatch(setInstanceValidation(true, 'isSshKey'));
    };
    if (!network.uuid) {
      dispatch(setInstanceValidation(true, 'isNetwork'));
    };
    if (!cluster.uuid) {
      dispatch(setInstanceValidation(true, 'isCluster'));
    };
  };

  const sshkeys = sshKeys.map(key => ({ uuid: key.uuid }));

  const data: InterfaceNewVm = {
    tenant_uuid: selectedTenantId,
    sshkeys,
    cloud_init_user_data: {},
    name,
    network_uuid: network?.uuid,
    flavor_uuid: flavor?.uuid,
    image_uuid: image?.uuid,
    addresses4: ips,
    cluster_uuid: cluster?.uuid,
  };

  const handleCreateVm = () => {
    const request = api.useFetchData<InterfaceNewVm, InterfaceInstance>(url, 'post', null, data);
    handleFetchRequest('instance', 'post', request);
  };

  const handleCheckValues = useCallback(() => {
    if (Object.values(validation).every(item => item === false) || Object.values(validation).some(item => item === '')) {
      setDisabled(false);
    }
    if (Object.values(validation).some(item => item === true)) {
      setDisabled(true);
    }
  }, [validation]);

  useEffect(() => {
    handleCheckValues();
  }, [validation, handleCheckValues]);

  const handleSubmit = () => {
    handleValidation();
    handleCheckValues();
    if (Object.values(validation).every(item => item === false)) {
      handleCreateVm();
    }
  };


  return (
    <>
      <div data-testid='vm_summary' className=' sticky '>
        <ItemsFeature />
        <div className='px-4 pt-[50px] pb-4 w-full m-0'>
          {error && <MessageFeature status='error' msg={error} />}
          <ButtonFeature
            type='button'
            title={`
              ${GLOSSARY.actions.create}
              ${type === 'vm' ? GLOSSARY.virtual_machine : GLOSSARY.bare_metal}
            `}
            customStyle='w-full'
            uiStyle='primary'
            dataTestId={TEST_ID_VMS_PAGE_START_NEW_VM_BTN}
            isDisabled={disabled}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export { Summary as SummaryFeature };

interface InterfaceNewVm {
  tenant_uuid: string,
  sshkeys: {uuid: string}[],
  cloud_init_user_data: {},
  name: string,
  network_uuid: string,
  flavor_uuid: string,
  image_uuid: string,
  addresses4?: IIpv4Api[],
  cluster_uuid: string,
};
