import { Redirect } from 'react-router-dom';

import { setIsApiMaintenance } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
import { IconBigFullColorLogo } from 'global-shared/assets';

export function MaintenanceApi() {
  //variables
  const contextDashboard = useDashboard();
  const dispatchDashboard = contextDashboard[1];
  const stateDashboard = contextDashboard[0];
  const { isApiMaintenance, maintenance } = stateDashboard;
  const url = '';
  const allMaintenance = maintenance && maintenance?.maintenance;
  const maintenanceFullTime = allMaintenance && allMaintenance[0] && allMaintenance[0].full_date_for_ui;

  //func
  const handleCheck = () => {
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      const data: { status: 'progress' | 'default' } = await fetch(url, {signal}).then(res => res.json());
      dispatchDashboard(setIsApiMaintenance(data.status));
    })();
  };

  if(isApiMaintenance === 'default'){
    return <Redirect to='/login' />;
  };

  return (
    <div onClick={handleCheck}>
      <div className='col items-center justify-center h-screen'>
        <div className='w-40 m-5'>
          <IconBigFullColorLogo />
        </div>
        <h1 className='text-2xl'>
          We are down for a bit maintenance
        </h1>
        <h2>We'll be back soon:)</h2>
        <div className='m-3'>
          <h2>{maintenanceFullTime}</h2>
        </div>
        <div className='col all-items-center'>
          <p className='m-3'>
            <b>Please be aware that it may not be possible to spin up or shutdown instances during the full maintenance window.</b>
          </p>
          <p>We apologize for any inconvenience this may cause.</p>
          <p>
            Please reach out to <a
              rel='noreferrer'
              target='_blank'
              href='cloud-support@taigacloud.com'
              className='underline underline-offset-8 hover:text-primaryColorEasternBlueBtnHover'
            >
              cloud-support@taigacloud.com
            </a> in case of any questions.
          </p>
          <p className='mt-3'>
            For future updates and notifications, please subscribe to our status page on <a
              rel='noreferrer noopener'
              target='_blank'
              href='https://status-cloudservices.northerndata.de'
              className='underline underline-offset-8 hover:text-primaryColorEasternBlueBtnHover'
            >
              https://status-cloudservices.northerndata.de
            </a>.
          </p>
        </div>
      </div>
    </div>
  );
};
