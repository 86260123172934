import React, { ChangeEvent, useState, useEffect } from 'react';

import { useDashboard } from 'context/dashboard';
import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { setFetchFunc, setNewSshKey } from 'actions/dashboard';
import { API_URLS, GLOSSARY, INewSshKeyApi, ISshKeyApi } from 'global-shared/model';
import { PortalModalFeature, useTenants } from 'global-shared/features';
import { AlertValidationFeature, MessageFeature, SpinnerFeature } from 'global-shared/features';
import { useHistoryGoBackHook } from 'global-shared/hooks';
import { dataTestIdsKeys } from 'data-test-ids';
import { useCleanState } from 'hooks/useCleanState';

/*
 * @todo refactoring - the same form is used for overview/new instance/ssh keys pages
 */
function NewSshKey() {
  // state
  const [formData, updateFormData] = useState<INewSshKeyApi>({
    tenant_uuid: '',
    name: '',
    key: '',
  });
  const [inputNameError, setInputNameError] = useState<string>('');
  const [sshKeyError, setSshKeyError] = useState<string>('');

  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = state;
  const { loading, error, status } = fetchFunc.sshKey.post;
  const { data: allSshKeys } = fetchFunc.sshKeys.get;
  const url = `${API_URLS.sshKeyNew}/${selectedTenantId}`;
  const fetchMethod = 'post';
  const handleFetchRequest = useHandleFetchRequest();
  const hideBtns = (status > 0 || loading || error) ? false : true;
  const handleClose = useHistoryGoBackHook();
  const cleanState = useCleanState();

  //hooks
  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | New ${GLOSSARY.ssh_key}`;
  }, []);

  // func
  const handleSubmit = async () => {
    if (!formData.name || /^\s*$/.test(formData.name)) {
      return setInputNameError(GLOSSARY.notifications.errors.please_enter_name);
    };
    if (!formData.key || /^\s*$/.test(formData.key)) {
      return setSshKeyError(GLOSSARY.notifications.errors.please_enter_correct_ssh_key);
    };
    setInputNameError('');
    setSshKeyError('');
    if (selectedTenantId) {
      const request = api.useFetchData<INewSshKeyApi, ISshKeyApi>(url, fetchMethod, null, formData);
      handleFetchRequest('sshKey', fetchMethod, request);
      const newSshKey = await request;
      dispatch(setFetchFunc([...allSshKeys, newSshKey], 'sshKeys', 'get', 'data'));
      handleClose();
    };
  };

  const handleCloseModalForm = () => {
    cleanState('sshKey', 'post');
    dispatch(
      setNewSshKey({
        tenant_uuid: '',
        name: '',
        key: '',
      }),
    );
    handleClose();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputNameError('');
    setSshKeyError('');
    updateFormData({
      ...formData,
      tenant_uuid: selectedTenantId,
      [name]: value,
    });
  };

  const content = () => {
    if (loading) return  <SpinnerFeature />;
    if (error) return <MessageFeature status='error' msg={error} />;

    return (
      <>
        <label htmlFor='name' className='relative'>
          <p>Name</p>
          <input
            type='text'
            name='name'
            id='name'
            placeholder='Name'
            onChange={(e) => handleChange(e)}
            value={formData.name}
            // data-testid='test_id_new_ssh_key_form_input' //new
            data-testid={dataTestIdsKeys.modalForm.portalModal.form.content.inputText} //old
            required={inputNameError ? true : false}
            autoComplete='off'
          />
          {inputNameError && <AlertValidationFeature text={inputNameError} />}
        </label>
        <label htmlFor='key' className='relative'>
          <p>{GLOSSARY.ssh_key}</p>
          <textarea
            name='key'
            id='key'
            rows={3}
            placeholder='ssh-rsa | ssh-ed | ...'
            onChange={(e) => handleChange(e)}
            value={formData.key}
            required={sshKeyError ? true : false}
          />
          {sshKeyError && <AlertValidationFeature text={sshKeyError} />}
        </label>
      </>
    );
  };

  return (
    <form id='ssh-key-form'>
      <PortalModalFeature.HeadingFeature
        heading={`${GLOSSARY.actions.add} ${GLOSSARY.ssh_key}`}
        onCancel={handleCloseModalForm}
      />
      <div className='pt-8 px-6 gap-6 flex flex-col'>
        {content()}
      </div>
      {hideBtns ?
        <PortalModalFeature.FooterWithBtnsFeature
          submitBtnName={GLOSSARY.actions.add}
          onSubmit={handleSubmit}
          onCancel={handleCloseModalForm}
        />
        :
        null
      }
    </form>
  );
};

export { NewSshKey as NewSshKeyPage };
