import { UUID } from 'crypto';

import { ITenantApi } from 'global-shared/model';

const setTenantsStorage = (data: ITenantApi[], uuid: UUID | string) => {
  sessionStorage.setItem('tenantsStorage', JSON.stringify(data));
  sessionStorage.setItem('selectedTenantIdStorage', uuid);
};

export { setTenantsStorage as setTenantsStorageUtils };
