import { useEffect, useState } from 'react';

import { useOktaAuth } from '@okta/okta-react';

import { InterfaceLocation } from '../../shared/model/data-types';
import { INSTANCE_LOCATIONS, NewInstanceFormContext } from '../../shared/model';

/**
 * @description
 */
const useMakeGetRequestLocations = () => {
  //state
  const [error, setError] = useState<string>('');
  const [isPending, setIsPending] = useState<boolean>(false);
  const [response, setResponse] = useState<InterfaceLocation[]>([]);
  const [status, setStatus] = useState<number>(0);

  //variables
  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;
  const { useValue } = NewInstanceFormContext;
  const { form: { type } } = useValue();

  //hooks
  useEffect(() => {
    setIsPending(true);

    locationsPromise(type)
      .then((response) => {
        setStatus(200);
        setError('');
        setResponse(response);
      })
      .catch((reason) => {
        if (reason.cause) {
          setError(reason.cause || '');
        };
      })
      .finally(() => setIsPending(false));

    return () => {
      setIsPending(false);
      setError('');
    };
  }, [token, type]);

  const request = {
    isPending,
    status,
    response,
    error,
    onSetResponse: setResponse,
  };

  return { request };
};

export { useMakeGetRequestLocations as useMakeGetRequestLocationsHook };

const locationsPromise: (instanceType: 'vm' | 'bm') => Promise<InterfaceLocation[]> = (instanceType: 'vm' | 'bm') =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const vmLocations = [INSTANCE_LOCATIONS[0]];
      const bmLocations = INSTANCE_LOCATIONS;
      if(instanceType === 'vm') {
        resolve(vmLocations);
      } else {
        resolve(bmLocations);
      }
    }, 200);
  });
