import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  CopyBtnFeature,
  SelectFeature,
  setPreselectedTenant,
  TypographyFeature,
  useTenants,
} from 'global-shared/features';
import { IOptionSelect, ITenantApi, TypeTenantSelect } from 'global-shared/model';
import { useChangeTenantHook } from 'global-shared/hooks';

/**
 * @description Tenants is a component contains a select,
 * which allows you to select a tenant or shows text with the name and
 * id of the tenant if there is only one tenant.
 */
function SelectedTenant() {
  // variables
  const context = useTenants();
  const state = context[0];
  const dispatch = context[1];
  const { preselectedTenant, tenants: { data }, selectedTenantId } = state;
  const selectedTenant = data.find((t) => t.uuid === selectedTenantId);
  const location = useLocation();
  const history = useHistory();
  const { changeTenant } = useChangeTenantHook();
  const tenants: TypeTenantSelect[] = data.map((t: ITenantApi) => ({...t, type: 'tenants'}));

  // func
  const handleChange = (tenant: IOptionSelect) => {
    const { name, uuid } = tenant;
    if (location.pathname.includes('/instances/new')) {
      dispatch(setPreselectedTenant({...preselectedTenant, uuid: uuid, customer_name: name}));
      history.push(`/${uuid}/instances/new`);
    };

    if (location.pathname !== '/' && !location.pathname.includes('/instances/new')) {
      const selectedPath = location.pathname.split('/');
      history.push(`/${uuid}/${selectedPath[2]}`);
      changeTenant(name, uuid);
    };

    if (location.pathname === '/') {
      history.push('/');
      changeTenant(name, uuid);
    };
  };

  return (
    <div
      className='col items-center'
      data-testid='test-id-main-header-tenants'
    >
      {data.length > 1 ?
        <div className='row justify-evenly'>
          <SelectFeature
            name='tenant'
            value={selectedTenant?.customer_name || ''}
            options={tenants}
            dataTestId='test_id_selected_tenant_name'
            isDisabled={false}
            isError={false}
            onSelect={handleChange}
          />
        </div>
        :
        <TypographyFeature
          text={selectedTenant?.customer_name || ''}
          fontSize='s'
          dataTestId='test-id-main-header-selected-tenant-name'
        />
      }
      <div className='row justify-center items-center gap-1'>
        <TypographyFeature
          text={selectedTenant?.uuid || ''}
          fontSize='xxxs'
          color='customColorDarkBlue60'
          dataTestId='test-id-main-header-selected-tenant-uuid'
        />
        <CopyBtnFeature
          value={selectedTenant?.uuid || ''}
          dataTestId='test_id_tenant_uuid'
        />
      </div>
    </div>
  );
};

export { SelectedTenant };
