import React from 'react';

import { useDashboard } from 'context/dashboard';
import { ISshKey } from 'types';
import { SpinnerFeature, TypographyFeature } from 'global-shared/features';
import { GLOSSARY } from 'global-shared/model';

import Items from './Items';
import { IconDownArrowGray } from './IconDownArrowGray';

import classes from '../instance.module.scss';

/**
 * @description
 * @returns Items
 */
export default function Content() {
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { data: allSshKeys, loading } = fetchFunc.sshKeys.get;
  const { sectionWrapperClass, fakeSelectDisableState } = classes;

  const sshKeys = allSshKeys.map((key: ISshKey) => ({...key, checked: false}));

  if (loading) return (
    <div className={`${sectionWrapperClass} justify-center`}>
      <SpinnerFeature />
    </div>
  );

  if (sshKeys.length === 0) return (
    <div className={`${fakeSelectDisableState} justify-between row`}>
      <TypographyFeature
        text={GLOSSARY.empty_state.you_dont_have_ssh_keys}
        fontSize='sm'
        fontWeight='thin'
      />
      <IconDownArrowGray />
    </div>
  );

  return <Items />;
};
