import { useDashboard } from 'context/dashboard';
import { TypographyFeature } from 'global-shared/features';
import { DATA_TEST_IDS_OVERVIEW_PAGE } from 'global-shared/model';

import { InstanceStatusFeature } from '../../../global-shared/features/InstanceStatus';

import { ChartPieFeature } from '../shared';
import { useFiltersInstancesByStatusHook } from '../hooks';

/**
 * @since 1.2.1
 * @description Chart pie section with a list of statuses
 * for instances on the overview page
 * @version 0.0.1
 * @package pages/overview/features
 * @example <InstancesChartPieContent />
 * @returns React Element | div
 */
function InstancesChartPieContent(){
  // variables
  const customWidth = 140;
  const customHeight = 140;
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc: { instances: { get: { data } } } } = stateDashboard;
  const statuses = useFiltersInstancesByStatusHook(data);
  const totalCount = data?.length;

  return (
    <div className='ml-6 mt-12 mb-4'>
      <div className='row'>
        <ChartPieFeature
          customData={statuses}
          customHeight={customHeight}
          customWidth={customWidth}
          totalNumber={totalCount}
          customSize={18}
          customFontSize={32}
          dy={12}
          dataTestId={DATA_TEST_IDS_OVERVIEW_PAGE.TOTAL_INSTANCES_NUMBER}
        />
        <ul className='ml-12 grow'>
          {statuses.map((item) => (
            <li key={item.name} className='mt-[0.62rem]'>
              {item.value > 0 ?
                <div
                  className='
                    row
                    justify-between
                    border-b
                    border-b-customColorLightGray
                    pb-[0.62rem]
                    px-2
                  '
                >
                  <InstanceStatusFeature
                    status={item.name}
                    color='customColorDarkBlue60'
                    fontSize='xs'
                  />
                  <TypographyFeature
                    text={item.value}
                    color='customColorDarkBlue60'
                    fontSize='xs'
                  />
                </div>
                : null
              }
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { InstancesChartPieContent as InstancesChartPieContentFeature};
