import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import { IModalForm, ISshKey, TNewSshKey } from 'types';
import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { setFetchFunc, setNewSshKey } from 'actions/dashboard';
import { TDataTestIdsPortalModal } from 'data-test-ids/types/general-pages/portalModal';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';
import { API_URLS, GLOSSARY } from 'global-shared/model';
import { PortalModalFeature, useTenants } from 'global-shared/features';
import { AlertValidationFeature, MessageFeature, SpinnerFeature } from 'global-shared/features';
import { useCleanState } from 'hooks/useCleanState';

/**
 * @todo refactoring
 * @param props
 * @description the component is present only in the form "create new instance"
 */
export default function CreateNewSshForm(props: TProps) {
  // state
  const [formData, updateFormData] = useState<TNewSshKey>({
    tenant_uuid: '',
    name: '',
    key: '',
  });
  const [inputNameError, setInputNameError] = useState<string>('');
  const [sshKeyError, setSshKeyError] = useState<string>('');

  // props
  const { onCancel, dataTestIds } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { useValue, useDispatch, setInstance, setInstanceValidation, setInstanceForm } = NewInstanceFormContext;
  const dispatchInstance = useDispatch();
  const { instance } = useValue();
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = state;
  const { loading, error, status } = fetchFunc.sshKey.post;
  const { data: allSshKeys } = fetchFunc.sshKeys.get;
  const url = `${API_URLS.sshKeyNew}/${selectedTenantId}`;
  const fetchMethod = 'post';
  const handleFetchRequest = useHandleFetchRequest();
  const hideBtns = (status > 0 || loading || error) ? false : true;
  const { pathname } = useLocation();
  const cleanState = useCleanState();

  useEffect(() => {
    return () => {
      cleanState('sshKey', 'post');
    };
  }, [cleanState]);

  // func
  const handleSubmit = async () => {
    if (!formData.name || /^\s*$/.test(formData.name)) {
      return setInputNameError(GLOSSARY.notifications.errors.please_enter_name);
    };
    if (!formData.key || /^\s*$/.test(formData.key)) {
      return setSshKeyError(GLOSSARY.notifications.errors.please_enter_correct_ssh_key);
    };
    setInputNameError('');
    setSshKeyError('');
    if (selectedTenantId) {
      const request = api.useFetchData<TNewSshKey, ISshKey>(url, fetchMethod, null, formData);
      handleFetchRequest('sshKey', fetchMethod, request);
      const newSshKey = await request;
      dispatch(setFetchFunc([...allSshKeys, newSshKey], 'sshKeys', 'get', 'data'));

      if (pathname.includes('/instances/new')) {
        const isKeysLess6 = instance.sshKeys.length === 6 && !newSshKey.checked;
        if (isKeysLess6) {
          onCancel();
          return () => {};
        } else {
          const preselectNewSshKey = {...newSshKey, checked: true};
          dispatchInstance(setInstance([...instance.sshKeys, preselectNewSshKey], 'sshKeys'));
          dispatch(setFetchFunc([...allSshKeys, preselectNewSshKey] , 'sshKeys', 'get', 'data'));
          dispatchInstance(setInstanceValidation(false, 'isSshKey'));
          dispatchInstance(setInstanceForm('', 'error'));
        }
      }
      onCancel();
    };
  };

  const handleCloseModalForm = () => {
    dispatch(
      setNewSshKey({
        tenant_uuid: '',
        name: '',
        key: '',
      }),
    );
    onCancel();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputNameError('');
    setSshKeyError('');
    updateFormData({
      ...formData,
      tenant_uuid: selectedTenantId,
      [name]: value,
    });
  };

  const content = () => {
    if (loading) return  <SpinnerFeature />;
    if (error) return <MessageFeature status='error' msg={error} />;

    return (
      <>
        <label htmlFor='name' className='relative'>
          <p>Name</p>
          <input
            type='text'
            name='name'
            id='name'
            placeholder='Name'
            onChange={(e) => handleChange(e)}
            value={formData.name}
            // data-testid='test_id_new_ssh_key_form_input' //new
            data-testid={dataTestIds.portalModal.form.content.inputText} //old
            required={inputNameError ? true : false}
            autoComplete='off'
          />
          {inputNameError && <AlertValidationFeature text={inputNameError} />}
        </label>
        <label htmlFor='key' className='relative'>
          <p>{GLOSSARY.ssh_key}</p>
          <textarea
            data-testid={dataTestIds.portalModal.form.content.inputSelect}
            // data-testid='test_id_new_ssh_key_form_textarea' //new
            name='key'
            id='key'
            rows={3}
            placeholder='ssh-rsa | ssh-ed | ...'
            onChange={(e) => handleChange(e)}
            value={formData.key}
            required={sshKeyError ? true : false}
          />
          {sshKeyError && <AlertValidationFeature text={sshKeyError} />}
        </label>
      </>
    );
  };

  return (
    <PortalModalFeature.ModalFeature modalType='form'>
      <form id='ssh-key-form'>
        <PortalModalFeature.HeadingFeature
          heading={`${GLOSSARY.actions.add} ${GLOSSARY.ssh_key}`}
          onCancel={handleCloseModalForm}
        />
        <div className='pt-8 px-6 gap-6 flex flex-col'>
          {content()}
        </div>
        {hideBtns ?
          <PortalModalFeature.FooterWithBtnsFeature
            submitBtnName={GLOSSARY.actions.add}
            onSubmit={handleSubmit}
            onCancel={handleCloseModalForm}
          />
          :
          null
        }
      </form>
    </PortalModalFeature.ModalFeature>
  );
};

type TProps = IModalForm & {
  dataTestIds: TDataTestIdsPortalModal,
};
