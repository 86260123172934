import { UUID } from 'crypto';

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import { useCleanState } from 'hooks/useCleanState';
import { IDPInfoSectionFeature, PortalModalFeature, SpinnerFeature } from 'global-shared/features';
import { GLOSSARY } from 'global-shared/model';
import { useHistoryGoBackHook, useMakeGetRequestInstanceHook, useNavigationPathHook } from 'global-shared/hooks';

/**
 * @description a page that shows instance information for the corresponding product (Network, Ipv4)
*/
var PortalModalIDPInfo = () => {
  // variables
  const { instanceId } = useParams<{instanceId: UUID}>();
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc: { instance: { get: { data: { name }, loading } } } } = state;
  const { handleNavigationDetailsPage } = useNavigationPathHook();
  const cleanState = useCleanState();
  const handleCloseModalWindow = useHistoryGoBackHook();

  //hooks
  useMakeGetRequestInstanceHook();

  useEffect(() => {
    return () => {
      cleanState('instance', 'get');
    };
  }, [cleanState]);

  return (
    <>
      <PortalModalFeature.ModalFeature modalType='default'>
        <PortalModalFeature.HeadingFeature
          heading={`${GLOSSARY.instance} ${name || ''}`}
          onCancel={handleCloseModalWindow}
        />
        {loading ?
          <SpinnerFeature /> :
          <IDPInfoSectionFeature />
        }
        <PortalModalFeature.FooterWithBtnsFeature
          submitBtnName={`Go to ${GLOSSARY.instance}`}
          onSubmit={() => handleNavigationDetailsPage('instances', instanceId)}
          onCancel={handleCloseModalWindow}
        />
      </PortalModalFeature.ModalFeature>
    </>
  );
};

export { PortalModalIDPInfo as ModalIDPInfoPage };
