import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { TypographyFeature } from 'global-shared/features';

/**
 * @since 1.2.1
 * @description Empty state for the instances section and
 * tabs on the overview page
 * @param props
 * @version 0.0.1
 * @package pages/overview/features
 * @example <EmptyStateFeature text='test' />
 * @returns React Fragment
 */
function EmptyState({text}: {text: string}) {
  return (
    <>
      <ExclamationCircleIcon
        className='stroke-[#02061D33] w-12 h-12'
      />
      <TypographyFeature
        text={text}
        customStyle='my-6'
        fontSize='xs'
        fontWeight='normal'
      />
    </>
  );
};

export { EmptyState as EmptyStateFeature };
