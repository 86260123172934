import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

function useHistoryGoBack() {
  // variables
  const history = useHistory();

  const handleCloseModalWindow = useCallback((e?: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
    history.goBack();
  }, [history]);

  return handleCloseModalWindow;
};

export { useHistoryGoBack as useHistoryGoBackHook };
