import React from 'react';

import { useDashboard } from 'context/dashboard';

import IncidentComponent from './IncidentComponent';
import Maintenance from './Maintenance';

export default function HeaderMaintenanceStatuses() {

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { maintenance } = stateDashboard;
  const allMaintenance = maintenance && maintenance.maintenance;
  const incidents = maintenance && maintenance.incidents;

  return (
    <div
      data-testid='test-id-maintenance-statuses'
      className='row'
    >
      {incidents?.length !== 0 &&
        <IncidentComponent />
      }
      {allMaintenance?.length !== 0 &&
        <Maintenance />
      }
    </div>
  );
};
