import { TypographyFeature } from 'global-shared/features';

import classes from './index.module.scss';

import { ChartPieFeature } from '..';
import { InterfaceChartPieListItem } from '../dataTypes';
import { getColorsChartListUtils } from '../../utils';

/**
  * @since 1.2.1
  * @description The component returns a list
  * for chart pie sections on the overview page
  * @param props
  * @version 0.0.1
  * @package pages/overview/features
  * @example
      <ListWithChartPieFeature
        data={customData}
        totalCount={totalCount}
      />
  * @returns React Element | div
 */
function ListWithChartPie(props: InterfaceProps){
  // variables
  const { data, totalCount, dataTestId } = props;

  // style
  const {
    containerWrapperClass,
    contentListClass,
    contentListItemClass,
    contentListItemTextClass,
    contentListItemColorDotClass,
  } = classes;

  return (
    <div className={containerWrapperClass}>
      <div className={contentListClass}>
        {data?.map((item: InterfaceChartPieListItem) => (
          <div
            key={item.name}
            className={contentListItemClass}
          >
            <div className={contentListItemTextClass}>
              <div
                className={`
                  ${contentListItemColorDotClass}
                  ${getColorsChartListUtils(item.name).bg}
                `}
              />
              <TypographyFeature
                text={item.name}
                fontSize='xs'
                fontWeight='medium'
                color='customColorDarkBlue60'
              />
            </div>
            <TypographyFeature
              text={item.value}
              fontWeight='medium'
              fontSize='xs'
              color='customColorDarkBlue60'
            />
          </div>
        ))}
      </div>
      <ChartPieFeature
        customData={data}
        customHeight={57}
        customSize={10}
        customWidth={57}
        totalNumber={totalCount}
        customFontSize={11}
        dy={5}
        dataTestId={dataTestId}
      />
    </div>
  );
};

export { ListWithChartPie as ListWithChartPieFeature };

interface InterfaceProps {
  data: InterfaceChartPieListItem[],
  totalCount: number,
  dataTestId: string,
};
