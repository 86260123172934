import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import ModalWhiteBg from 'layouts/ModalWhiteBg';

type TProps = {
  children: ReactNode,
  open: boolean,
  onCancel: () => void,
  dataTestId?: string,
  width?: string,
};

/**
 *
 * @deprecated
 * @description use <ModalFeature /> instead of it
 */

export default function PortalModal(props: TProps) {
  // props
  const { children, open, onCancel, dataTestId, width } = props;

  return (
    <>
      {createPortal(
        <ModalWhiteBg
          open={open}
          onCancel={onCancel}
          dataTestId={dataTestId}
          width={width}
        >
          {children}
        </ModalWhiteBg>,
        document.body,
      )}
    </>
  );
}
