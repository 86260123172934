import { useHistory, useLocation } from 'react-router-dom';

import { useGetPageRoutesHook } from 'global-shared/hooks';
import { TWebNewModalFormPage } from 'global-shared/model';

/**
 * @example const { handleOpenCreateForm } = useGoToModalFormPageHook();
*/
function useGoToModalFormPage() {
  // variables
  const { getModalFormPageRoute } = useGetPageRoutesHook();
  const location = useLocation();
  const history = useHistory();

  // func
  const handleOpenCreateForm = (page: TWebNewModalFormPage): void => {
    history.push(
      getModalFormPageRoute(page, 'new'),
      { modalFormPage: location },
    );
  };

  return { handleOpenCreateForm };
};

export { useGoToModalFormPage as useGoToModalFormPageHook };
