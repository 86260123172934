import { useOktaAuth } from '@okta/okta-react';

/**
 * @since 1.0.0
 * @description useLogout - hooks, clean storage data and logout from interface
 * @version 0.0.2
 * @package global-shared/hooks
 * @example useLogoutHook();
 * @returns object
 */
function useLogout() {
  // valiables
  const { oktaAuth } = useOktaAuth();

  // func
  const handleLogout = async() => {
    oktaAuth.signOut();
    localStorage.clear();
    sessionStorage.clear();
  };

  return { handleLogout };
};

export { useLogout as useLogoutHook };
