import { UUID } from 'crypto';

import React, { useState } from 'react';

import { setFetchFunc, setNewIpv4 } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { API_URLS, GLOSSARY, IIpv4Api, INewIPv4 } from 'global-shared/model';
import {
  FormTabsFeature,
  MessageFeature,
  PortalModalFeature,
  SpinnerFeature,
  useTenants,
} from 'global-shared/features';
import { useCleanState } from 'hooks/useCleanState';
import { useHistoryGoBackHook } from 'global-shared/hooks';

export default function CreateNewIpv4ModalForm() {
  // state
  const [clusterError, setClusterError] = useState<string>('');

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc, newIpv4 } = stateDashboard;
  const { loading, status, error } = fetchFunc.ip.post;
  const { data: allIpv4 } = fetchFunc.ips.get;
  const url = `${API_URLS.ipv4}/${newIpv4.cluster_uuid}/${selectedTenantId}`;
  const handleFetchRequest = useHandleFetchRequest();
  const hideBtns = (status > 0 || loading) ? false : true;
  const cleanState = useCleanState();
  const handleClose = useHistoryGoBackHook();

  // func
  const handleCloseModalForm = () => {
    cleanState('ip', 'post');
    dispatchDashboard(
      setNewIpv4({
        tenant_uuid: '',
        cluster_uuid: '',
      }),
    );
    handleClose();
  };

  const handleCreateNewIpv4 = async() => {
    if (!newIpv4.cluster_uuid) {
      return setClusterError(GLOSSARY.notifications.errors.please_select_cluster);
    };
    if (selectedTenantId) {
      const request =  api.useFetchData<INewIPv4, IIpv4Api>(url, 'post', null, newIpv4);
      handleFetchRequest('ip', 'post', request);
      const ipv4: IIpv4Api = await request;
      const correctIpAddressFormat = { ...ipv4, address4: ipv4?.address4.replace('/32', '')};
      dispatchDashboard(setFetchFunc([...allIpv4, correctIpAddressFormat], 'ips', 'get', 'data'));
      handleClose();
    };
  };

  const handleNewIpv4 = (uuid: UUID | string) => {
    dispatchDashboard(
      setNewIpv4({
        tenant_uuid: selectedTenantId,
        cluster_uuid: uuid,
      }),
    );
  };

  const handleClusterError = () => {
    setClusterError('');
  };

  const content = () => {
    if (error) {
      return  <MessageFeature status='error' msg={error} />;
    };

    if (loading) {
      return  <SpinnerFeature />;
    };

    return (
      <div className='pt-8 px-6'>
        <FormTabsFeature
          clusterUuid={newIpv4.cluster_uuid}
          clusterError={clusterError}
          onHandleClusterError={handleClusterError}
          onChange={handleNewIpv4}
        />
      </div>
    );
  };

  return (
    <>
      <PortalModalFeature.HeadingFeature
        heading={`${GLOSSARY.actions.create} ${GLOSSARY.ipv4}`}
        onCancel={handleCloseModalForm}
      />
      {content()}
      {hideBtns ?
        <PortalModalFeature.FooterWithBtnsFeature
          submitBtnName={GLOSSARY.actions.create}
          onSubmit={handleCreateNewIpv4}
          onCancel={handleCloseModalForm}
        />
        :
        null
      }
    </>
  );
};
