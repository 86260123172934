import React, { useEffect } from 'react';

import PageLayout from 'layouts/pages';
import { GLOSSARY } from 'global-shared/model';
import HeaderWithBreadcrumbs from 'components/HeaderWithBreadcrumbs';
import { useGetPageRoutesHook, useMakeGetRequestInstanceHook } from 'global-shared/hooks';
import { dataTestIdsVm } from 'data-test-ids';
import Subheader from 'feature/vm/Subheader';
import { IDPInfoSectionFeature } from 'global-shared/features';

/**
 * @description a component is a detailed page of a single Instance,
 * sets the title in the browser tab, makes a request to the API
 * @returns ReactNode - PageLayout component
*/
var InstanceDetail = () => {
  //variables
  const { getPageRoute } = useGetPageRoutesHook();

  //hooks
  useEffect(() => {
    document.title = `${GLOSSARY.taiga_cloud} | ${GLOSSARY.instance}`;
  }, []);

  useMakeGetRequestInstanceHook();

  return (
    <PageLayout
      page='instance'
      emptyTextPlaceholder=''
      renderSuccess={
        <>
          <HeaderWithBreadcrumbs
            route={getPageRoute('instances')}
            routeName={GLOSSARY.instances_plural}
            dataTestIds={dataTestIdsVm.content.header}
          />
          <div
            id='main-wrapper'
            data-testid={dataTestIdsVm.content.main.name}
          >
            <div
              data-testid={dataTestIdsVm.content.main.name}
              className={'lightGrayWrapperClass mt-4'}
            >
              <Subheader
                dataTestIds={dataTestIdsVm.content.main.header.vmPage}
              />
            </div>
            <IDPInfoSectionFeature
              dataTestIds={dataTestIdsVm.info}
            />
          </div>
        </>
      }
    />
  );
};

export { InstanceDetail as InstanceDetailPage };
