import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  TrashIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  PowerIcon,
} from '@heroicons/react/24/outline';
import { ChevronRightIcon, PencilIcon } from '@heroicons/react/24/solid';
import { useCleanState } from 'hooks/useCleanState';
import { TFetchFuncPage, TFetchFuncRequestMethod } from 'types';
import getVMsStatus, { TActionsMenuComponent } from 'helpers/getStyleVMStatus';
import { ButtonFeature, ElementWithTooltipFeature } from 'global-shared/features';
import { useDashboard } from 'context/dashboard';
import { DATA_TEST_ID_ACTION_MENU } from 'global-shared/model';

import ActionItemPortalModal from './ActionItemPortalModal';

/**
 * @description The ActionItem component.
 * @param props typeAction, route, uuid, url, page, fetchMethod, actionName, itemName, dataTestIds
 * @param uuid required
 * @param page required
 * @param fetchMethod required
 * @param actionName required
 * @param itemName required
 * @param typeAction required
 * @returns Button (action: 'power off/on, ...') and modal form
*/
export default function ActionItem(props: InterfaceProps) {
  // state
  const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(false);

  // props
  const {
    route='',
    uuid='',
    url='',
    page='instance',
    fetchMethod='get',
    actionName='view',
    itemName='',
    typeAction='menu-action',
  } = props;

  // hooks
  const cleanState = useCleanState();

  // func
  const handleOpenModalWindow = () => {
    setOpenPortalModalWindow(true);
    cleanState(page, fetchMethod);
  };

  const handleCloseModalWindow = () => {
    setOpenPortalModalWindow(false);
  };

  // variables
  const iconsAction = {
    reset: ArrowPathIcon,
    reboot: ArrowUpCircleIcon,
    off: PowerIcon,
    delete: TrashIcon,
    on: PowerIcon,
    view: '',
    rename: PencilIcon,
    test: '',
  };
  const CurrentItemIcon = iconsAction[getVMsStatus(actionName).icon];
  const iconStyleActionWithoutTitle = 'w-6 stroke-customColorDarkBlue';
  const iconStyleActionWithTitle = 'w-4 ml-6 mr-[13px] stroke-2 stroke-customColorDarkBlue cursor-pointer pb-[0.1rem]';
  const context = useDashboard();
  const state = context[0];
  const { selectedInstanceType } = state;

  if (actionName === 'view') {
    if (page === 'instances') {
      localStorage.setItem('selectedInstanceType', selectedInstanceType);
    };
    return (
      <Link to={route}>
        <ButtonFeature
          type='button'
          onClick={handleOpenModalWindow}
          uiStyle='menu-with-title-action'
          dataTestId={DATA_TEST_ID_ACTION_MENU.ACTION_ITEM_MENU}
        >
          <ChevronRightIcon className='w-5 ml-6 mr-[13px] stroke-customColorDarkBlue'/> View
        </ButtonFeature>
      </Link>
    );
  };

  return (
    <>
      {typeAction === 'menu-action' ?
        <ButtonFeature
          type='button'
          onClick={handleOpenModalWindow}
          uiStyle={'menu-with-title-action'}
          dataTestId={DATA_TEST_ID_ACTION_MENU.ACTION_ITEM_MENU}
        >
          <CurrentItemIcon
            className={iconStyleActionWithTitle}
            onClick={handleOpenModalWindow}
            aria-hidden='true'
          />
          {getVMsStatus(actionName).action}
        </ButtonFeature>
        :
        <ElementWithTooltipFeature
          text={getVMsStatus(actionName).name}
          element={
            <ButtonFeature
              type='button'
              onClick={handleOpenModalWindow}
              uiStyle={'white-with-icon-tooltip'}
            >
              <CurrentItemIcon
                className={iconStyleActionWithoutTitle}
                onClick={handleOpenModalWindow}
                aria-hidden='true'
              />
            </ButtonFeature>
          }
        />
      }
      {openPortalModalWindow &&
        <ActionItemPortalModal
          actionName={actionName}
          itemName={itemName}
          page={page}
          fetchMethod={fetchMethod}
          uuid={uuid}
          url={url}
          handleCloseModalWindow={handleCloseModalWindow}
          openPortalModalWindow={openPortalModalWindow}
        />
      }
    </>
  );
};

interface InterfaceProps {
  route?: string,
  dataTestIds?: {},
  // required
  uuid: string,
  url: string,
  page: TFetchFuncPage,
  fetchMethod: TFetchFuncRequestMethod,
  actionName: TActionsMenuComponent,
  itemName: string,
  typeAction: 'menu-action' | 'button-action',
};
