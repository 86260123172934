import { useEffect } from 'react';

import { ContentFeature } from './features/content';

function Demo() {
  //hooks
  useEffect(() => {
    document.title = 'Taiga Cloud | Demo';
  }, []);

  return <ContentFeature />;
};

export { Demo as DemoPage };
