import React from 'react';

import { ButtonFeature } from 'global-shared/features';
import { DATA_TEST_ID_MODALS } from 'global-shared/model';

function FooterWithBtns(props: InterfaceProps) {
  //props
  const {
    submitBtnName='Submit',
    isSubmitBtnRed=false,
    onCancel,
    onSubmit,
  } = props;

  // variables
  const styleButton = isSubmitBtnRed ? 'red' : 'primary';

  return (
    <div
      className='mt-10 pb-4 pr-6 items-center flex justify-end'
      data-testid='test_id_modal_btns'
    >
      <ButtonFeature
        type='button'
        customStyle='all-items-center mr-[5px]'
        title='Cancel'
        onClick={onCancel}
        uiStyle='gray'
        dataTestId={DATA_TEST_ID_MODALS.CANCEL_BTN}
      />
      <ButtonFeature
        type='button'
        uiStyle={styleButton}
        onClick={onSubmit}
        title={submitBtnName}
        dataTestId={DATA_TEST_ID_MODALS.SUBMIT_BTN}
      />
    </div>
  );
};

export { FooterWithBtns as FooterWithBtnsFeature };

interface InterfaceProps {
  isSubmitBtnRed?: boolean,
  submitBtnName: string,
  onCancel: () => void,
  onSubmit?: (value?: any) => void,
};
