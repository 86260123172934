import React, { ReactNode, useEffect, useState } from 'react';

import { GLOSSARY } from 'global-shared/model';
import { NotificationFeature, SpinnerFeature, TypographyFeature } from 'global-shared/features';
import ubuntuImage from 'assets/images/ubuntu-logo.png';
import debianImage from 'assets/images/debian.png';
import rockyImage from 'assets/images/rocky.png';
import { InterfaceImageAPI } from 'global-shared/model/dataTypes';
import { useMakeGetRequestImagesApi } from 'global-shared/api';

import classes from './index.module.scss';
import Dropdown from './Dropdown';
import { InterfaceImageFamily } from './shared/data-types';

import { InterfaceImage } from '../../shared/model/data-types';
import { SectionTitleFeature } from '../../shared/features';
import { NewInstanceFormContext } from '../../shared/model';

/**
 * @description a component that returns all images on the create new instance page
 */
const Images = () => {
  //variables
  const {
    request: {
      error,
      isPending,
      response,
    },
  } = useMakeGetRequestImagesApi();

  if (error) return (
    <Wrapper>
      <NotificationFeature
        status='error'
        msg={error}
      />;
    </Wrapper>
  );

  if (isPending) return (
    <Wrapper>
      <SpinnerFeature />
    </Wrapper>
  );

  return (
    <Wrapper>
      <ReturnContent
        response={response}
      />
    </Wrapper>
  );
};

export { Images as ImagesFeature };

const Wrapper = ({children}: {children: ReactNode}) => (
  <>
    <SectionTitleFeature title={GLOSSARY.image} />
    {children}
  </>
);

const ReturnContent = ({response}: {response: InterfaceImageAPI[]}) => {
  //state
  const [imageFamilies, setImageFamilies] = useState<InterfaceImageFamily[]>(() => getImageFamilies(response));

  //variables
  const { useDispatch, setInstance, setInstanceValidation } = NewInstanceFormContext;
  const dispatch = useDispatch();
  const findSelectedImage = imageFamilies?.find(f => f.selected)?.images?.find(i => i.selected) || imageFamilies[0]?.images[0];

  //hooks
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      dispatch(setInstance(findSelectedImage, 'image'));
      dispatch(setInstanceValidation(false, 'isImage'));
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, imageFamilies, setInstance, setInstanceValidation, findSelectedImage]);

  //func
  const handleSelectedImage = (imageFamilyId: number, image: InterfaceImage) => {
    setImageFamilies(
      imageFamilies.map((f) => {
        if (f.id === imageFamilyId) {
          return {
            ...f,
            selected: true,
            images: f.images.map((i) => {
              if(i.uuid === image.uuid) {
                return {
                  ...i,
                  selected: true,
                };
              } else {
                return {
                  ...i,
                  selected: false,
                };
              }
            }),
          };
        } else {
          return {
            ...f,
            selected: false,
            images: f.images.map((i) => {
              return {
                ...i,
                selected: false,
              };
            }),
          };
        }
      }),
    );
  };

  return (
    <ImageFamilies
      imageFamilies={imageFamilies}
      selectedImage={findSelectedImage}
      onSelectImageFamily={handleSelectedImage}
    />
  );
};

const ImageFamilies = (props: InterfacePropsImageFamilies) => {
  //props
  const {imageFamilies, selectedImage, onSelectImageFamily} = props;

  return (
    <div className='row flex-wrap'>
      {imageFamilies?.map(imageFamily => (
        <div
          key={imageFamily.id}
          data-testid={imageFamily.name}
          className={`
            ${classes.itemClass}
            ${imageFamily.selected ? 'border-customColorLightGreen bg-green-50' : 'bg-white border-customColorLightGray'}
          `}
        >
          <div className='col all-items-center mt-[15px] mb-[11px]'>
            <img
              src={imageFamily.logo}
              alt={imageFamily.name}
              className='w-6 mb-[10px]'
            />
            <TypographyFeature
              text={imageFamily.name}
              fontSize='sm'
              fontWeight='medium'
              color='customColorDarkBlue'
            />
          </div>
          <Dropdown
            imageFamily={imageFamily}
            selectedImage={selectedImage || imageFamilies[0]?.images[0]}
            onSelectImageFamily={onSelectImageFamily}
          />
        </div>
      ))}
    </div>
  );
};

interface InterfacePropsImageFamilies {
  imageFamilies: InterfaceImageFamily[],
  selectedImage: InterfaceImage,
  onSelectImageFamily: (imageFamilyId: number, image: InterfaceImage) => void
};

const getImageFamilies = (data: InterfaceImageAPI[]) => {
  //func
  const ubuntu: InterfaceImage[] = data
    .filter((item: InterfaceImageAPI) => item.name.toLowerCase().includes('ubuntu'))
    .map((item: InterfaceImageAPI) =>  ({
      ...item,
      image_group_id: 0,
      image: 'ubuntu',
      selected: false,
    }));

  ubuntu && ubuntu[0] && (ubuntu[0].selected = true);

  const debian: InterfaceImage[] = data
    .filter((item: InterfaceImageAPI) => item.name.toLowerCase().includes('debian'))
    .map((item: InterfaceImageAPI) =>  ({
      ...item,
      image_group_id: 1,
      image: 'debian',
      selected: false,
    }));

  const rocky: InterfaceImage[] = data
    .filter((item: InterfaceImageAPI) => item.name.toLowerCase().includes('rocky'))
    .map((item: InterfaceImageAPI) =>  ({
      ...item,
      image_group_id: 2,
      image: 'rocky',
      selected: false,
    }));

  const imagesFamily: InterfaceImageFamily[] = [
    {
      id: 0,
      name: 'ubuntu',
      logo: ubuntuImage,
      images: ubuntu,
      selected: true,
    },
    {
      id: 1,
      name: 'debian',
      logo: debianImage,
      images: debian,
      selected: false,
    },
    {
      id: 2,
      name: 'rocky',
      logo: rockyImage,
      images: rocky,
      selected: false,
    },
  ];

  return imagesFamily;
};
