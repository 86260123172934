import { UUID } from 'crypto';

import {
  setPreselectedTenant,
  setSelectedTenantId,
  useTenants,
} from 'global-shared/features';
import { setTenantsStorageUtils } from 'global-shared/utils';

/**
 * @description useChangeTenant - a hook which calls a function that changes the tenant
 * and writes a new object to reducer and localStorage
 * @example const { changeTenant, resetTenant } = useChangeTenantHook();
 * @returns changeTenant, resetTenant
 */
function useChangeTenant() {
  // variables
  const context = useTenants();
  const state = context[0];
  const dispatch = context[1];
  const { preselectedTenant, tenants: { data } } = state;

  // func
  const changeTenant = (name: string, uuid: UUID | string) => {
    dispatch(setSelectedTenantId(uuid));
    dispatch(setPreselectedTenant({...preselectedTenant, uuid: uuid, customer_name: name}));
    setTenantsStorageUtils(data, uuid);
  };

  /**
   * @description resetTenant
   * clears the preselected tenant and saves the old tenant to setPreselectedTenant,
   * used on the new vm page when switching to other pages on the cancel click
   */
  const resetTenant = () => {
    dispatch(setPreselectedTenant({...preselectedTenant}));
  };

  return { changeTenant, resetTenant };
};

export { useChangeTenant as useChangeTenantHook };
