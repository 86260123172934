import { ReactNode } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import { DATA_TEST_IDS_ALERT } from 'global-shared/model';

import classes from './index.module.scss';

function AlertWarning({children}: {children: ReactNode}) {
  return (
    <div
      data-testid={DATA_TEST_IDS_ALERT.warning}
      className={`${classes.alertClass} row rounded text-xs font-medium m-1`}
    >
      <div className='w-7 h-7 bg-customBgLightYellow20 rounded-md all-items-center'>
        <ExclamationTriangleIcon className='w-5 fill-customDarkYellow'/>
      </div>
      <div className='ml-18px'>
        {children}
      </div>
    </div>
  );
};

export { AlertWarning as AlertWarningFeature };
