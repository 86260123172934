import React, { useState } from 'react';

import { GLOSSARY } from 'global-shared/model';

import IconCopy from './IconCopy';

import { ButtonFeature } from '../button';
import { ElementWithTooltipFeature } from '../element-with-tooltip';

/**
 * @description CopyComponent is used if you want to display the copy icon and
 * copy the corresponding value passed to it
 * @param props
 * @example
 * <CopyBtn />
 * @returns Button component
 */
function CopyBtn(props: InterfaceProps) {
  // state
  const [tooltipText, setTooltipText] = useState<string>('copy');

  // props
  const {
    value,
    dataTestId='test_id_ui_copy_element',
    iconColor='fill-customColorDarkBlue',
    isIconShow=true,
  } = props;

  // func
  const handleCopyClick = () => {
    navigator.clipboard.writeText(value);
    setTooltipText(GLOSSARY.notifications.success.copied);
    setTimeout(() => setTooltipText('copy'), 1000);
  };

  return (
    <>{
      isIconShow ?
        <ButtonFeature
          type='button'
          onClick={handleCopyClick}
          uiStyle='without-style'
          dataTestId={dataTestId}
        >
          <ElementWithTooltipFeature
            text={tooltipText}
            element={
              <IconCopy
                customStyle={iconColor}
              />
            }
          />
        </ButtonFeature>
        :
        <ButtonFeature
          type='button'
          onClick={handleCopyClick}
          uiStyle='gray'
          dataTestId={dataTestId}
        >
          {GLOSSARY.copy_summary}
        </ButtonFeature>
    }</>
  );
};

export { CopyBtn as CopyBtnFeature };

interface InterfaceProps {
  /**
   * @default 'test_id_ui_copy_element'
   */
  dataTestId?: string,
  value: string,
  /**
   * @description pass the value to be copied
   * @default 'fill-customColorDarkBlue'
   */
  iconColor?: `fill-${string}`,
  isIconShow?: boolean,
};
