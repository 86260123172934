import { Link } from 'react-router-dom';

import { TypographyFeature } from 'global-shared/features';
import { APP_ROUTES_PAGES_PATHS } from 'global-shared/model';

export function NotFound() {
  return (
    <div className='col all-items-center mt-[6.25rem]'>
      <TypographyFeature
        text='404 Not found'
        fontSize='xxxl'
      />
      <Link
        to={APP_ROUTES_PAGES_PATHS.home}
        className='border-customColorLightGray bg-customGray4 px-6 py-4 rounded-md m-8'
      >
        <TypographyFeature
          text='Go to Home'
          fontSize='s'
          fontWeight='thin'
        />
      </Link>
    </div>
  );
};
