
const IconCheckGreen = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='13'
    height='13'
    viewBox='0 0 13 13'
    fill='none'
  >
    <path
      d='M1 7.03125L5.25 11.2812L11.625 1.71875'
      stroke='#4DEC8D'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { IconCheckGreen };
