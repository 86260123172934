import { UUID } from 'crypto';

import React, { ChangeEvent, useState } from 'react';

import { useDashboard } from 'context/dashboard';
import { setFetchFunc, setNewNetwork } from 'actions/dashboard';
import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { API_URLS, GLOSSARY } from 'global-shared/model';
import { INetworkApi, InterfaceNewNetworkApi } from 'global-shared/model/dataTypes';
import { useHistoryGoBackHook } from 'global-shared/hooks';
import {
  AlertValidationFeature,
  FormTabsFeature,
  MessageFeature,
  PortalModalFeature,
  SpinnerFeature,
  useTenants,
} from 'global-shared/features';
import { TDataTestIdsPortalModal } from 'data-test-ids/types/general-pages/portalModal';
import { useCleanState } from 'hooks/useCleanState';

/**
 * @todo refactoring
 * @param props
 * @description It is a duplicate of the index file.
 * This should be temporary. (Overview, networks pages)
 */
export default function CreateNewNetworkPage({dataTestIds}: InterfaceProps) {
  // state
  const [nameError, setNameError] = useState<string>('');
  const [clusterError, setClusterError] = useState<string>('');

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc, newNetwork } = stateDashboard;
  const { loading, status, error } = fetchFunc.network.post;
  const { data: allNetworks } = fetchFunc.networks.get;
  const fetchMethod = 'post';
  const handleFetchRequest = useHandleFetchRequest();
  const clusterUuid = newNetwork.cluster_uuid;
  const url = `${API_URLS.networks}/${clusterUuid}/${selectedTenantId}?network_name=${newNetwork.network_name}`;
  const hideBtns = (status > 0 || loading) ? false : true;
  const handleClose = useHistoryGoBackHook();
  const cleanState = useCleanState();

  // func
  const handleCloseModalForm = () => {
    cleanState('network', 'post');
    dispatchDashboard(
      setNewNetwork({
        tenant_uuid: '',
        cluster_uuid: '',
        network_name: '',
      }),
    );
    handleClose();
  };

  const handleSubmit = async () => {
    if (!newNetwork.network_name) {
      return setNameError(GLOSSARY.notifications.errors.please_enter_name);
    };
    if (!newNetwork.cluster_uuid) {
      return setClusterError(GLOSSARY.notifications.errors.please_select_cluster);
    };

    const newNetworkParams = newNetwork;

    if (selectedTenantId) {
      const request = api.useFetchData<InterfaceNewNetworkApi, INetworkApi>(
        url, fetchMethod, null, newNetworkParams,
      );
      handleFetchRequest('network', fetchMethod, request);
      const getNewNetwork: InterfaceNewNetworkApi = await request;
      dispatchDashboard(setFetchFunc([...allNetworks, getNewNetwork], 'networks', 'get', 'data'));
      handleClose();
    };
  };

  const handleChangeName = (event:  ChangeEvent<HTMLInputElement> ) => {
    const { value } = event.target;
    dispatchDashboard(
      setNewNetwork({
        ...newNetwork,
        network_name: value,
      }),
    );
    setNameError('');
  };

  const handleChangeCluster = (uuid: UUID | string) => {
    dispatchDashboard(
      setNewNetwork({
        ...newNetwork,
        tenant_uuid: selectedTenantId,
        cluster_uuid: uuid,
      }),
    );
    setClusterError('');
  };

  const handleClusterError = () => {
    setClusterError('');
  };

  const content = () => {
    if (error) {
      return <MessageFeature status='error' msg={error} />;
    };

    if (loading) {
      return <SpinnerFeature />;
    };

    return (
      <div className='pt-8 px-6'>
        <label htmlFor='network_name' className='relative'>
          <p>Name</p>
          <input
            type='text'
            name='network_name'
            id='network_name'
            placeholder='Name'
            onChange={(e) => handleChangeName(e)}
            value={newNetwork.network_name}
            data-testid={dataTestIds.portalModal.form.content.inputText}
            required={nameError ? true : false}
            autoComplete='off'
            className='mb-8'
          />
          {nameError && <AlertValidationFeature text={nameError} />}
        </label>
        <FormTabsFeature
          clusterUuid={newNetwork.cluster_uuid}
          clusterError={clusterError}
          onHandleClusterError={handleClusterError}
          onChange={handleChangeCluster}
        />
      </div>
    );
  };

  return (
    <>
      <PortalModalFeature.HeadingFeature
        heading={`${GLOSSARY.actions.create} ${GLOSSARY.network}`}
        onCancel={handleCloseModalForm}
      />
      {content()}
      {hideBtns ?
        <PortalModalFeature.FooterWithBtnsFeature
          submitBtnName={GLOSSARY.actions.create}
          onSubmit={handleSubmit}
          onCancel={handleCloseModalForm}
        />
        :
        null
      }
    </>
  );
};

interface InterfaceProps {
  dataTestIds: TDataTestIdsPortalModal,
};
