import React from 'react';

import { TypographyFeature } from 'global-shared/features';

import classes from './index.module.scss';

/**
 * @description The TooltipContainer UI component
 * @param props text, dataTestId
 * @returns ReactNode - Info compoent | Content component
*/
export default function TooltipContainer(props: InterfaceProps) {
  // props
  const {
    text='tooltip ui component',
    dataTestId='test_id_tooltip',
    x,
    y,
    contentRef,
    position='top',
    description='',
  } = props;

  // style
  const { tipClass } = classes;

  const correctPositionLeft = position === 'top' ? 0 : 125;
  const correctPositionTop = position === 'top' ? 0 : 50;

  return (
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        left: correctPositionLeft,
        top: correctPositionTop,
        transform: `translate3d(${x-35}px, ${y-5}px, 0)`,
        zIndex: 100,
      }}
    >
      <div
        ref={contentRef}
        data-testid={dataTestId}
        className={`${tipClass} capitalize`}
      >
        <TypographyFeature
          text={text}
          fontSize='xs'
          fontWeight='medium'
          color='white'
        />
        <TypographyFeature
          text={description}
          fontSize='xxxs'
          fontWeight='medium'
          color='customColorWhite50'
          // color='white'
        />
      </div>
    </div>
  );
};

interface InterfaceProps {
  /**
   * @default 'tooltip ui component'
  */
  text?: string,
  description?: string,
  /**
   * @default 'test_id_tooltip'
  */
  dataTestId?: string,
  x: number,
  y: number,
  contentRef: any,
  position: 'left' | 'top' |  'right' | 'bottom',
};
