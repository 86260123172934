import { useMemo } from 'react';

import { useDashboard } from 'context/dashboard';
import { DATA_TEST_IDS_OVERVIEW_PAGE, GLOSSARY, IIpv4Api } from 'global-shared/model';
import { useGoToModalFormPageHook } from 'global-shared/hooks';
import { ButtonFeature } from 'global-shared/features';

import { SectionsContentFeature } from '../shared';
import { InterfaceChartPieListItem } from '../shared/dataTypes';

/**
 * @since 1.2.1
 * @description The component returns a pie chart with
 * the number of attached and unattached ipv4s numbers,
 * as well as an error or an empty state
 * @version 0.0.2
 * @package pages/overview/features
 * @example <Ipv4sFeature />
 * @returns React Element | SectionsContentFeature
 */
function Ipv4s(){
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc: { ips: { get: { data: getMetrics, error, loading, status } } } } = stateDashboard;
  const attached = useMemo(() => getMetrics?.filter((item: IIpv4Api) => item.vm_uuid), [getMetrics]);
  const noAttached = useMemo(() => getMetrics?.filter((item: IIpv4Api) => item.vm_uuid === null), [getMetrics]);
  const data = {
    count: getMetrics?.length,
    unattached: noAttached?.length,
    attached: attached?.length,
  };
  const customData: InterfaceChartPieListItem[] = [
    {
      name: 'unattached',
      value: data?.unattached > 0 ? data?.unattached : 0,
      color: '#745BE6',
    },
    {
      name: 'attached',
      value: data?.attached > 0 ? data?.attached : 0,
      color: '#27A9BF',
    },
  ];
  const { handleOpenCreateForm } = useGoToModalFormPageHook();

  return (
    <SectionsContentFeature
      heading={GLOSSARY.ipv4s_plural}
      error={error}
      loading={loading}
      status={status}
      customData={customData}
      totalCount={data?.count}
      dataTestId={DATA_TEST_IDS_OVERVIEW_PAGE.TOTAL_IPV4S_NUMBER}
    >
      <ButtonFeature
        title={`${GLOSSARY.actions.create} ${GLOSSARY.ipv4}`}
        uiStyle='primary'
        customStyle='min-w-[155px] self-end'
        size='small'
        onClick={() => handleOpenCreateForm('ipv4s')}
        dataTestId={DATA_TEST_IDS_OVERVIEW_PAGE.CREATE_NEW_IPV4_BTN}
      />
    </SectionsContentFeature>
  );
};

export { Ipv4s as Ipv4sFeature };
