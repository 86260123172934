import { Redirect } from 'react-router-dom';

import { TypographyFeature } from 'global-shared/features';
import { useGetPageRoutesHook, useShowElementForPDPHook } from 'global-shared/hooks';

/**
 * @description a component that returns an element for an instance page or redirects to an instance page
 * @param props
 * @example <Items item={{}}/>
 * @returns React Element
 */
export default function Item(props: InterfaceProps) {
  // props
  const { item } = props;

  // variables
  var { showRightTypeOfElement } = useShowElementForPDPHook();
  var { getPageRoute } = useGetPageRoutesHook();

  if (item.title === 'Type' && item.value === '-') {
    return <Redirect to={getPageRoute('instances')} />;
  };

  return (
    <div
      key={item.title}
      className='flex col'
    >
      <div className='mb-2'>
        <TypographyFeature
          text={item.title}
          fontWeight='medium'
          color='customColorDarkBlue80'
          fontSize='s'
        />
      </div>
      {showRightTypeOfElement(item.title, item.value)}
    </div>
  );
};

interface InterfaceProps {
  item: {
    title: string,
    value: string,
  }
};
