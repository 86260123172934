import React, { ReactNode } from 'react';

import {
  MessageFeature,
  SpinnerFeature,
} from 'global-shared/features';

/**
 * @param props
 * @description a component that returns the status of one of the request options (waiting, error, or showing data)
 */
const RequestState = (props: InterfaceProps ) => {
  //props
  const { error, loading, children } = props;

  if (error) {
    return <MessageFeature status='error' msg={error} />;
  };

  if (loading) {
    return <SpinnerFeature />;
  };

  return children;
};

export { RequestState as RequestStateFeature };

interface InterfaceProps {
  error: string,
  loading: boolean,
  children: ReactNode,
};
