import { TypographyFeature } from '../typography';

/**
 * @description A component that returns one of the message views
 * (error, success, warning, info) with the text. (a small notification)
 * @param props
 * @see {@link https://www.figma.com/design/ZMxDs0t8fYtsMVTbHy5Njt/Cloud?node-id=3445-2637&node-type=frame&t=X1mPCRIXMA7P6Ppr-0}
 * @example <MessageFeature status='error' msg={error} />;
 */
function Message({status, msg, dataTestId}: InterfaceProps) {
  // func
  const chooseUI = () => {
    switch (status) {
    case 'error':
      return ['text-red-600', 'bg-red-50 border border-red-300'];

    case 'warning':
      return ['text-yellow-600', 'bg-yellow-50 border border-yellow-300'];

    case 'success':
      return ['text-green-600', 'bg-green-50 border border-green-300'];

    default:
      return ['text-cyan-600', 'bg-cyan-50 border border-cyan-300'];
    }
  };

  return (
    <div
      data-testid={dataTestId}
      className={`${chooseUI()[1]} leading-relaxed rounded-md p-2 m-1`}
    >
      <TypographyFeature
        text={msg}
        fontSize='s'
        fontWeight='medium'
        customStyle={chooseUI()[0]}
      />
    </div>
  );
};

export { Message as MessageFeature };

interface InterfaceProps {
  status: 'error' | 'info' | 'warning' | 'success',
  msg: string | number,
  dataTestId?: string,
};
