import React, { ReactNode, useRef, useState } from 'react';

import { PortalTooltip } from './portal';

/**
 * @description
 * @param props
*/
const ElementWithTooltip = (props: InterfaceProps) => {
  // state
  const [targetRect, setTargetRect] = useState<InterfaceTargetRect | null>(null);

  // props
  const {
    dataTestId='test_id_ui_element_with_tooltip',
    text,
    description,
    element,
    position='top',
  } = props;

  // hooks
  const buttonRef = useRef<HTMLInputElement>(null);

  // func
  const handlePointerEnter = () => {
    if (buttonRef.current) {
      const rect = buttonRef?.current?.getBoundingClientRect();
      setTargetRect({
        left: rect.left,
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
      });
    }
  };

  const handlePointerLeave = () => {
    setTargetRect(null);
  };

  return (
    <>
      <div
        className='row items-center'
        data-testid={dataTestId}
        ref={buttonRef}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
      >
        {element}
      </div>
      {targetRect !== null && (
        <PortalTooltip
          targetRect={targetRect}
          text={text}
          description={description}
          position={position}
        />
      )}
    </>
  );
};

export { ElementWithTooltip as ElementWithTooltipFeature };

interface InterfaceProps {
  /**
   * @default 'test_id_ui_element_with_tooltip'
  */
  dataTestId?: string,
  element: ReactNode,
  text: string,
  description?: string,
  position?: 'left' | 'top' |  'right' | 'bottom',
};

interface InterfaceTargetRect {
  left: number | undefined,
  top: number | undefined,
  right: number | undefined,
  bottom: number | undefined,
};
