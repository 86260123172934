import React, { useRef } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

import { useDashboard } from 'context/dashboard';
import { setFetchFunc } from 'actions/dashboard';
import { ISshKey } from 'types';
import useOnClickOutside from 'hooks/useClickOutside';
// eslint-disable-next-line no-restricted-imports
import { NewInstanceFormContext } from 'pages/instances/new/shared/model';
import { GLOSSARY } from 'global-shared/model';
import { useTenants } from 'global-shared/features';

import classes from './key.module.scss';

/**
 * @todo refactoring, eslint-disable-next-line no-restricted-imports
 * @returns
 */
const Items = (() => {
  // variables
  const refDropDown = useRef<HTMLDetailsElement>(null);
  const { useValue, useDispatch, setInstance, setInstanceForm, setInstanceValidation } = NewInstanceFormContext;
  const stateInstance = useValue();
  const dispatchInstance = useDispatch();
  const { instance, validation } = stateInstance;
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = stateDashboard;
  const { data: sshKeys } = fetchFunc.sshKeys.get;

  // style
  const { detailsClass, checkboxClass, listClass, validationClass } = classes;

  // func
  const handleCheckboxes = (key: ISshKey) => {
    const newKeys = [...instance.sshKeys, key];
    const selectedKeys = sshKeys.map(
      (item: ISshKey) => item.uuid === key.uuid ? {...item, checked: true} : item,
    );
    dispatchInstance(setInstance(newKeys, 'sshKeys'));
    dispatchDashboard(setFetchFunc(selectedKeys, 'sshKeys', 'get', 'data'));
    dispatchInstance(setInstanceValidation(false, 'isSshKey'));
    dispatchInstance(setInstanceForm('', 'error'));
    if (key.checked) {
      const newArray = instance.sshKeys.filter(item => item.uuid !== key.uuid);
      const selectedKeys = sshKeys.map(
        item => item.uuid === key.uuid ? {...item, checked: false} : item);
      dispatchDashboard(setFetchFunc(selectedKeys, 'sshKeys', 'get', 'data'));
      dispatchInstance(setInstance(newArray, 'sshKeys'));
    }
  };

  const handleValidation = (key: ISshKey) => {
    const isKeysLess6 = instance.sshKeys.length === 6 && !key.checked;
    if (isKeysLess6) {
      return () => {};
    } else {
      return handleCheckboxes(key);
    }
  };

  const handleClickOutside = () => {
    const el = document.getElementById(`details-${selectedTenantId}`) as HTMLDetailsElement;
    el.open = false;
  };

  useOnClickOutside(refDropDown, handleClickOutside);

  return (
    <details
      ref={refDropDown}
      id={`details-${selectedTenantId}`}
      className='mt-2 relative'
      data-testid='ssh_key_dropdown'
    >
      <summary>
        <div className={`${ validation.isSshKey ? validationClass : detailsClass}`}>
          <div className='row flex'>
            {instance.sshKeys.length !== 0 ?
              instance.sshKeys.map((key, index) => {
                const keyWithComma = index === instance.sshKeys.length - 1 ?
                  <p
                    key={key.uuid}
                    className='truncate min-w-[25px] max-w-[100px] ml-1'
                  >
                    {key.name}
                  </p> :
                  <p
                    key={key.uuid}
                    className='truncate min-w-[25px] max-w-[100px] ml-1'
                  >
                    {key.name},
                  </p>;
                return keyWithComma;
              })
              : GLOSSARY.ssh_key
            }
          </div>
          <ChevronDownIcon className='h-5 w-5 fill-customColorDarkBlue cursor-pointer' aria-hidden='true' />
        </div>
      </summary>
      <div className={`${listClass} z-10 w-full`}>
        {sshKeys?.map((key: ISshKey, index: number) => (
          <div
            onClick={() => handleValidation(key)}
            key={key.uuid}
            className={`
              ${index % 2 === 0 ? 'bg-white' : 'bg-customBgLightGray3'}
              flex items-center h-9
            `}>
            <div className='min-w-0 flex-1 text-sm flex row items-center'>
              <input
                id={`checkbox-${key.name}`}
                name={`checkbox-${key.name}`}
                type='checkbox'
                checked={key.checked ?? false}
                onChange={() => handleValidation(key)}
                className={checkboxClass}
              />
              <p className='select-none text-s font-medium truncate max-w-sm'>
                {key.name}
              </p>
            </div>
          </div>
        ))}
      </div>
    </details>
  );
});

export default Items;
