import { useDashboard } from 'context/dashboard';
import { useLocation } from 'react-router-dom';
import { GLOSSARY } from 'global-shared/model';

import Item from './Item';

/**
 * @description a component that returns an informational list of items about an instance
 * @example <Items />
 * @returns React Element
 */
export default function Items() {
  //variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.instance.get;
  const { status, uuid: vmUUID = '', flavor_uuid = '', image_uuid = '', network_uuid = '' } = data;
  const location = useLocation();
  const instanceType = localStorage.getItem('selectedInstanceType') || '';

  const showCorrectTypeName = (type: string) => {
    if(type === 'baremetal') {
      return GLOSSARY.bare_metal;
    };
    if(type === 'virtualmachine') {
      return GLOSSARY.virtual_machine;
    };
    return '-';
  };

  const infoStatus = {
    title: 'Status',
    value: status,
  };

  const items: InterfaceItem[] = [
    {
      title: GLOSSARY.uuid,
      value: vmUUID,
    },
    {
      title: GLOSSARY.flavor,
      value: flavor_uuid || '',
    },
    {
      title: 'Network UUID',
      value: data?.network ? data?.network?.network_name : network_uuid,
    },
    {
      title: GLOSSARY.cluster,
      value: data.cluster_uuid,
    },
    {
      title: GLOSSARY.image,
      value: image_uuid || '',
    },
    {
      title: 'Type',
      value: data?.mtype ? showCorrectTypeName(data?.mtype) : showCorrectTypeName(instanceType),
    },
  ];

  const ITEMS = location.pathname.includes('/instances') ?
    items : items.toSpliced(1, 0, infoStatus);

  return (
    <div className='grid grid-cols-2 gap-y-10 gap-x-28'>
      {ITEMS.map((item: {title: string, value: string}) =>
        <Item
          key={item.title}
          item={item}
        />,
      )}
    </div>
  );
};

interface InterfaceItem { title: string, value: string };
