import { useDashboard } from 'context/dashboard';
import { useGoToModalFormPageHook } from 'global-shared/hooks';
import {
  ButtonFeature,
  MessageFeature,
  SpinnerFeature,
  TypographyFeature,
} from 'global-shared/features';
import { DATA_TEST_IDS_OVERVIEW_PAGE, GLOSSARY } from 'global-shared/model';

import classes from '../shared/index.module.scss';

/**
 * @since 1.2.1
 * @description SshKeysSection is for overview page,
 * just show a total number of ssh keys for selected tenant
 * @version 0.0.1
 * @package pages/overview/features
 * @example <SshKeyFeature />
 * @returns React Element | div
 */
function SshKeys() {
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const {
    // metrics: { ssh_keys },
    fetchFunc: { sshKeys: { get: { data, error, loading } } },
  } = stateDashboard;
  const { handleOpenCreateForm } = useGoToModalFormPageHook();
  const totalCount = data?.length;

  // style
  const { grayWrapperSectionClass } = classes;

  return (
    <div className={`row ${grayWrapperSectionClass} pl-6`}>
      <TypographyFeature
        text={GLOSSARY.ssh_keys_plural}
        fontSize='xs'
      />
      {loading ?
        <SpinnerFeature />
        : null
      }
      {error ?
        <MessageFeature status='error' msg={error} /> :
        null
      }
      {(!error && !loading) &&
        <>
          {totalCount === 0 ?
            <ButtonFeature
              title={`${GLOSSARY.actions.create} ${GLOSSARY.ssh_key}`}
              uiStyle='primary'
              customStyle='mr-6'
              size='small'
              onClick={() => handleOpenCreateForm('sshKeys')}
              dataTestId={DATA_TEST_IDS_OVERVIEW_PAGE.CREATE_NEW_SSH_KEY_BTN}
            />
            :
            <TypographyFeature
              text={totalCount || 0}
              color='black'
              fontSize='xs'
              customStyle='pr-12'
              dataTestId={DATA_TEST_IDS_OVERVIEW_PAGE.TOTAL_SSH_KEYS_NUMBER}
            />
          }
        </>
      }
    </div>
  );
};

export { SshKeys as SshKeyFeature };
